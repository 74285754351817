@use 'sass/abstracts/variables';

/* Style of button */

.btn-base-primary {
  font-weight: bold;
  background-color: variables.$wds-orange;
  color: variables.$black;
  cursor: pointer;
  border: none;
  font: bold 12px "Lato", sans-serif;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: variables.$black;
  border-radius: 2px;
  text-decoration: none;
    
  &:hover {
      background-color: variables.$wds-hover-orange;
      border: none;
      color: variables.$black;
  }

  &:disabled {
    background: variables.$wds-disabled-orange;
    color: variables.$grey-500;
    cursor:default;
  }
}

.btn-grey {
  @extend .btn-base-primary;
  background: variables.$grey-300;

  &:hover {
    background: variables.$grey-400;
  }

  &:disabled {
    background: variables.$grey-200;
    color: variables.$grey-400;
    cursor:default;
  }
}

.ms-Button--primary {
  @extend .btn-base-primary;
}

.ms-Button--default:not(.nav-item, .map-control, .btn-tab) {
  @extend .btn-grey;
}

.shrink-button {
  min-width: 0px;
  width:25px;

  &.left {
    margin-left: 3px;
    margin-top: -5px;
  }
  &.right {
    margin-left: -10px;
    margin-top: -6px;
    margin-right: -24px;
  }
  &:hover {
    background-color: variables.$wds-orange;
  }
}

.center {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.space-left {
  margin-left: 15px;
}

.space-bottom {
  margin-bottom: 5px;
}

.space-top {
  margin-top: 5px;
}

.btn-size-input {
  height: 36px;
}

.float-right {
  margin-top: 5px;
  margin-right: 8px;
  margin-bottom: 5px;
  float: right;
}

.full-width {
  width: 100%;
}

// Sitmap Cancel Icon/Button
%cancel-icon {
  position: absolute;
  right: 10px;
  font-size: 24px;
  display: flex;
  cursor: pointer;
}
