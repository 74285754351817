@use 'sass/abstracts/variables';

.error-dialog {
  .ms-Overlay {
    background: rgba(80, 80, 80, 0.5);
  }

  .ms-Dialog-main {
    box-shadow: none !important;
    background: variables.$white;
    border: 2px solid variables.$black;
    box-sizing: border-box;
    max-width: none !important;
    min-width: none !important;
  }

  .err-content {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 380px;
    word-break: break-word;
  }

  .err-related {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    font-weight: 500;
    display: none;
  }

  .ms-Dialog-inner {
    padding: 20px 20px 0px 20px !important;
  }

  .ms-Dialog-header {
    padding: 0px;
    margin: 0px;
    display: block;

    div[class*="topButton-"] {
      padding: 0px;

      > button:not(:first-of-type) {
        display: none;
      }
    }

    .ms-Dialog-button {
      color: variables.$black;
    }

    .ms-Dialog-title {
      display: none;
    }
  }

  .err-copy-to-clipboard {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    font-weight: bold;
    margin-top: 20px;

    div {
      cursor: pointer;
    }

    .bi-clipboard {
      font-size: 19px;
      font-weight: bold;
      margin-right: 5px;
    }
  }

  .ms-Dialog-inner .ms-Button {
    width: 187.4px;
    height: 42px;
    background: variables.$grey-300;
    border-radius: 5px;
    border: none;
    color: variables.$black;
 
  }

  .error-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}

.error-link-wrap a {
  color: variables.$blue !important;
  outline: none;
}

.fw-n {
  font-weight: normal !important;
}
