@use 'sass/abstracts/variables';

.cs-bottom {
  display: flex;

  > div {
    flex: 1;
  }
}
.cs-left {
  .ag-row-selected {
    background-color: variables.$black !important;
    color: variables.$white !important;
  }
  .ag-cell {
    line-height: 17px;
  }
}
.cs-right {
  margin-left: 10px;

  .header {
    background: variables.$wds-orange;
    border-radius: 5px 5px 0 0;
    color: variables.$black;
  }
  .client-info {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;

    .detail {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .property-empty {
    width: 100%;
    text-align: center;
    line-height: 50px;
  }
  .tab {
    height: 36px;
    background: variables.$black;

    .tab-button {
      height: 36px;
      color: variables.$white;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: -7px;
      margin-top: 7px;

      &:first-child {
        margin-left: 17px;
      }
    }
    .tab-active {
      background: variables.$white;
      color: variables.$black;
    }
  }
}
.client-info-right {
  text-align: right;

  a {
    min-width: 100px;
  }

  span {
    margin-right: 8px;
  }

  .btn-active-client {
    background: greenyellow;
  }
  .btn-inactive-client {
    background: red;
  }
  .client-active-button {
    height: 20px;
    color: variables.$black;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    cursor: default;
    padding: 0px 20px;
  }
  .kmz-code {
    height: 20px;
    background: variables.$white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    cursor: default;
    padding: 0px 10px;
  }
}
.tab-client-subscription {
  .ts-header {
    background: variables.$wds-orange;
    border-radius: 5px 5px 0 0;
    height: 36px;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: #000;
    padding-left: 14px;
    padding-top: 7.58px;
  }
  .border-black {
    border: 1px solid #000;
    border-top: 0 solid #000;
    box-sizing: border-box;
    padding: 13px 11px;
  }
  .services-list ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 10px;

    li {
      margin-right: 15px;
      font-weight: bold;
    }
  }
  .response-states {
    margin-top: 15px;

    .state-list {
      padding: 20px 20px 40px;
      text-align: center;
    }
    .response-states-map {
      border: 3px solid variables.$black;
      padding: 8px;
    }
    .response-state-config {
      display: flex;
      justify-content: space-between;
      max-height: 440px;
    }
    .left {
      flex: 4;
      max-width: 200px;
    }
    .right {
      flex: 6;
      max-width: 400px;
    }
    .state-checkbox-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 100%;
    }
    .state-checkboxes {
      margin-bottom: 10px;
    }
  }
  .tab-config {
    display: flex;
    justify-content: space-around;
  }
  .user-management-link {
    text-align: center;
    padding: 20px 0 5px 0;
  }
  .save-client {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .save-client-button {
    background: variables.$wds-orange;
    border: none;
    color: variables.$black;

    &:disabled {
      background: variables.$white;
      border: 1px solid #555;
    }
  }
}