﻿@use 'sass/abstracts/variables';

.notifications-main-container {
  padding: 30px;
  font-family: variables.$main-font-family;

  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    line-height: 35px;
  }

  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
}

.firename-prenotice-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.notifications-main-container .hr-1 {
  margin-top: 15px;
  height: 1px;
  background: variables.$grey-300;
}

.notification-cancel-text {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: variables.$black;
  padding-left: 70px;
  padding-right: 70px;
  margin-bottom: 30px;
}

.notifications-main-container {
  .error-message {
    color: variables.$red;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .pl-dropdown .ms-Dropdown .ms-Dropdown-title {
    font-weight: 700 !important;
    color: variables.$black;
    font-family: variables.$main-font-family;
  }
}

.notice-page .ms-Dropdown-callout .ms-Callout-main {
  max-height: 14rem !important;
}

.notifications-main-container {
  .infor-message {
    text-align: right;
    font-size: 14px;
  }

  .ms-TextField-fieldGroup {
    border-color: variables.$grey-300;
  }
}

.fireInformation-container {
  .section-box {
    padding: 20px 10px;
    height: 100%;
  }

  .row-section {
    display: flex;
    margin-top: 10px;
  }

  .row-section-left {
    flex: 1;
  }

  .row-section-right {
    flex: 1;
    margin-left: 15px;
  }
}
