@use 'sass/abstracts/variables';
.user-info {
  display: flex;
  justify-content: space-between;
}
.notification-config-grid {
  .grid-actions > a {
    cursor: pointer;
  }
  .edit-action {
    margin-right: 20px;
  }
}
.edit-notification-body {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;

  & > div {
    flex: 1;
    max-width: 200px;
  }
}
.notification-config-form {
  width: 1000px;
  padding: 15px;
  border-top: 8px solid variables.$wds-orange;
}
.new-notification {
  &.save {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
}
.notification-feeds {
  margin-top: 30px;
}