@use 'sass/abstracts/variables';
.submittal-doc-container {
  font-family: 'Times New Roman', Times, serif;
  width: 8in;
  margin: auto;
}

.submittal-header{
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 6px;
}

.submittal-bold-header{
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 22px;
  margin-bottom: 6px;
}

.submittal-section-header{
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  margin-top: 25px;
}

.submittal-section-subheader{
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-top: 6px;
}

.submittal-body{
  font-size: 14px;
  margin-bottom: 6px;
}

.section-container {
  font-size: 14px;
  page-break-after: always;
  margin-bottom: 30px;
}

.scroll-content {
  max-height: 100%;
}

.enrolled-props-container {
    border-collapse: collapse;
    width: 100%;

    td {
      border-width: 1px;
      padding-left: 5px;
    }
}

.resource-container {
  border: 2px solid variables.$black;
  margin: 25px 0;
  padding: 10px;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
    text-align: center;
    
    th, td {
      border-width: 1px;
    }
  }
}
.resource-instructions, .other-resource-info {
  p {
    font-weight: bold;
  }
}

@media screen {
  #printSection {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSection, #printSection * {
    visibility:visible;
  }
  #printSection {
    position:absolute;
    left:1.5in;
    top:0;
  }
}