@use 'sass/abstracts/variables';

.pu-tab {
  border: 2px solid variables.$wds-orange;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 16px;
  overflow: hidden;
  background: variables.$wds-orange;

  .pu-tab-header {
    height: 50px;
    background: variables.$wds-orange;
    display: flex;
    align-items: center;
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    color: variables.$black;

    .pu-tab-expander {
      font-size: 50px;
      line-height: 40px;
      text-align: center;
      user-select: none;
      position: relative;
      top: -3px;
      cursor: pointer;
      width: 48px;
      margin-right: 10px;
    }

    .pu-tab-title {
      font-size: 20px;
      line-height: 24px;
      margin-right: auto;
    }

    img {
      display: none;
      padding: 6px 7px;
      background-color: white;
      cursor: pointer;
      margin-right: 4px;
      width: 34px;
      box-sizing: border-box;
    }
  }
}

.pu-tab-body {
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.pu-tab.is-expanded {
  background: transparent;

  .pu-tab-body {
    padding: 21px 24px 20px 33px;
    height: auto;
  }

  .pu-tab-header img {
    display: block;
  }
}
