@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.nav-wrap {
  margin-left: 20px;
  flex-grow: 1;

  display: none;
  @include mixins.desktop {
    display: block;
  }
}

.nav-item {
  display: none;
  @include mixins.desktop {
    display: block;
  }

  font-size: 12px;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  border: 0;
  height: 40px;
  border-radius: 0;

  &.is-active {
    background-color: variables.$main-bg-color;
  }

  &.align-right {
    margin-left: auto;
  }
}
