﻿@use 'sass/abstracts/variables';

.res-schedule-event {
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px !important;
  text-align: center;
}

.page-rs .res-schedule-event .ms-Persona-primaryText {
  font-size: 12px !important;
  height: auto !important;
  max-width: 98% !important;
}

.res-schedule-event .ms-Persona {
  margin-left: -6px;

  .ms-Persona-details {
    padding-left: 5px;
    padding-right: 0px;
  }

  .ms-Persona-initials {
    line-height: 18px !important;
    height: 18px !important;
  }

  .ms-Persona-imageArea {
    width: 18px !important;
    height: 18px !important;
  }
}

.page-rs {
  .fc {
    .fc-timeline-bg .fc-bg-event.res-schedule-bar {
      z-index: 5;
    }

    .fc-bg-event.res-schedule-bar {
      opacity: var(--fc-bg-event-opacity, 0.7);
    }

    .fc-h-event.res-schedule-bar {
      background-color: transparent;
      border: none;

      &.no-crew {
        background-color: variables.$grey-200;
        border: 1px solid variables.$grey-300;
      }

      &.inactive {
        background-color: variables.$grey-100;
        border: 1px dashed variables.$grey-300;
      }

      .fc-event-main {
        color: variables.$black;
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }

    .fc-popover-title {
      font-size: .85em;
    }

    .fc-timeline-body {
      overflow-x: hidden;
    }
  }

  .fc-popover-body {
    max-height: 8rem;
    overflow-y: auto;
  }
}

.fc-timeline-lane-frame {
  display: flex;
  align-items: center;
}

.fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
  padding-bottom: 0;
}

.res-schedule-event p {
  font-size: 16px;
  font-weight: bold;
}
