﻿@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';


.fireoverview-main-container {
  padding: 30px;

}

.__react_component_tooltip {
  &::before, &::after {
    content: none !important;
  }
}

/*Property Detail*/

.fireoverview-propertydetail {
  border: 3px solid variables.$wds-orange;
  box-sizing: border-box;
  width: 336px;
}

/*Map*/

.fireoverview-map {
  position: relative;
  height: 550px;

  .map-wrapper {
    height: 100%;
  }

  .map-actions {
    top: 12px;
    right: 12px;

    .fireoverview-search-address {
      width: 196px;
    }

    .fireoverview-legend-selector, .fireoverview-layer-selector {
      height: 35px;
    }
  }

  .property-query-layer {
    position: absolute;
    right: 60px;
    bottom: 12px;
    background-color: variables.$wds-orange;
    border-radius: 15px;
    border: 3px solid variables.$wds-orange;
    width: 253px;

    .query-container {
      display: flex;
      width: 100%;
    }
  }

  .query-text {
    flex: 1;
    padding-left: 15px;
    font-weight: bold;

    /* width: 218px;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .property-query-layer .close {
    width: 20px;
    cursor: pointer;
  }
}

/*end map*/

/* Tabs */

.fireoverview-tabs {
  padding: 20px 45px 20px 45px;
}

.fireoverview-archived {
  color: variables.$red;
  font-weight: bold;
}

.new-property-form .new-property-form-heading.fo-property-modal {
  justify-content: flex-end;
}

.fireoverview-kpibox {
  margin-bottom: 20px;
  @include mixins.kpi-box(25px);

  .fo-kpi-white-box {
    display: flex;
    flex-direction: column;
    @include mixins.kpi-white-box(25px);

    .kpi-red {
      color: red;
      font-weight: bold;
    }
  }

  .fo-kpi-metric {
    @include mixins.kpi-metric();
  }

  .fo-kpi-value {
    display: flex;
    flex-direction: column;
    @include mixins.kpi-value();
  }

  .fo-kpi-change{
    display: flex;
    flex-direction: column;
    @include mixins.kpi-change();
  }
}

.fo-status {
  font-weight:700;
  font-size:18px;
  margin-bottom:2px;
  max-width:180px;
  text-align:center;
  overflow:visible;  
  overflow-wrap:break-word;

  .fo-status-red{
    color: red;
    font-weight: bold;
  }     
}

.fo-fire-name {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: variables.$black;
  height: 38px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
}

.fo-fire-last-update {
    @include mixins.kpi-last-update();
}