﻿@use 'sass/abstracts/variables';

/*fire Information*/

.notifications-main-container .fireInformation-container {
  .row-section-left, .row-section-right {
    min-width: 303px;
  }

  .section-box {
    padding-bottom: 0px;
  }

  .update-date {
    padding-bottom: 10px;
  }

  .section-row {
    display: flex;
    padding: 5px 0;
  }

  .column-name {
    min-width: 120px;
    flex: 1;
  }

  .column-value {
    flex: 2;
  }

  .fire-summary {
    margin-top: 15px;
    padding-left: 4px;
    padding-right: 4px;

    .ms-TextField-field {
      font-family: variables.$main-font-family;
      font-size: 16px;
      color: variables.$black;
      line-height: 19.2px;
    }
  }

  .error-message {
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .impacted-areas {
    .section-header {
      margin-bottom: 20px;
    }

    .section-row {
      margin-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .impacted-column-name {
      width: 100px;
      line-height: 35px;
      font-weight: bold;
    }

    .impacted-column-value {
      flex-grow: 1;
    }
  }
}

/*end fire Information*/

.prenotice-box {
  display: flex;

  .title {
    margin-right: 20px;
  }
}

.preNoticesDate-title {
  width: 100%;
  font-size: 16px;
}

.preNoticesDate-options {
  padding-left: 6px;
  font-weight: normal;
}

.preNoticesDate-published-type {
  font-style: italic;
  margin-left: 5px;
}

.preNoticesDate-published i {
  margin-left: 5px;
  font-weight: bold;
}

.preNoticesDate-unpublished {
  background-color: rgba(196, 196, 196, 0.5);
  width: 100%;
  padding-left: 5px;
}

.prenotice-box .btn {
  margin-top: 4px;
  margin-left: 13px;
  border-radius: 30px;
  font-weight: bold;
  width: 94px;
  height: 30px;
}

.prenotice-box-background .ms-Callout-main {
  background-color: white;
}

/*end select previous notice*/
