@use 'sass/abstracts/variables';

.popup-container {
  pointer-events: none;
}

.popup-close {
  pointer-events: all;
}

.popup-container.mapboxgl-marker {
  will-change: auto;
}

.map-actions {
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 1;
  flex-wrap: wrap;
}

.customInfobox {
  padding: 8px 15px 10px;
  max-width: 280px;
  max-height: 280px;
  overflow-y: auto;
  pointer-events: all;

  div {
    white-space: normal;
    word-break: break-word;

    &.name {
      padding-top: 5px;
      padding-right: 5px;

      a {
        display: inline-block;
        color: variables.$black !important;
        font-weight: bold;
      }
    }

    &.desc {
      line-height: 1.5em;

      a {
        display: inline-block;
        color: variables.$black !important;
      }
    }
  }
}

.popup-container .tooltiptext {
  display: none !important;
}

.map-popup-contents {
  pointer-events: all;
}

.map-popup-pager {
  padding: 0;
  font-size: 12px;
  text-align: center;
  pointer-events: all;
  margin-top: -10px;

  > span {
    padding: 8px;
    line-height: 1;
    display: inline-block;
  }
}

.map-popup-page-content {
  display: none;

  &.active {
    display: block;
  }
}

.azmaps-scaleBar {
  position: absolute;
  right: 60px;
  height: 5px;
  background: none;
  background-color: transparent !important;
  bottom: 2px;

  &.dark {
    border-color: white;
  }

  span {
    display: block;
    width: 60px;
    text-align: right;
    position: absolute;
    left: -68px;
    top: -9px;
    font-weight: 700;
  }

  &.dark span {
    color: white;
  }
}

.bottom-right.subcontrol-container {
  bottom: 10px;
}

.atlas-control-container .non-fixed {
  display: none;
}

.azure-maps-control-button.zoom-out {
  margin-top: 4px;
}

.map-wrapper {
  display: flex;
  height: 750px;
}
