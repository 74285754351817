@use 'sass/abstracts/variables';

/* Border */
.fop-bd-grey-1 {
  border: 1px solid variables.$grey-300;
  box-sizing: border-box;
}
.red-box {
  border-color: variables.$red;
}
.orange-border-wrap {
  border: 2px solid variables.$wds-orange;
  box-sizing: border-box;
  border-radius: 2px;
}