@use 'sass/abstracts/variables';
.new-property-form {
  width: 1200px;
  padding: 15px;
  border-top: 8px solid variables.$wds-orange;
  .new-property-form-heading {
    display: flex;
    justify-content: space-between;
  }
  .new-property-form-body {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px;

    & > div {
      flex: 1;
    }
  }
  .new-property-form-left {
    margin-left: 25px;
  }
  .new-property-client-policy {
    display: flex;
    justify-content: space-between;

    & > div {
      flex: 1;
      max-width: 48%;
    }
  }
  .address-field {
    max-width: 48%;
    margin-bottom: 8px;
  }
  .new-property-city-state-zip {
    display: flex;
    justify-content: space-between;

    & .city {
      flex: 3;
    }
    & .state {
      flex: 1;
      margin-left: 15px;
    }
    & .zip {
      flex: 1;
      margin-left: 15px;
    }
  }
  .new-property-warning {
    color: variables.$red;
    padding: 10px 0;
  }
  .new-property-lat-long {
    display: flex;
    justify-content: space-between;

    & > div {
      flex: 1;
      max-width: 48%;
    }
  }
  .gate-code {
    max-width: 48%;
  }
  .save-new-property {
    display: flex;
    justify-content: center;

  }
  .save-button {
    background: variables.$wds-orange;
    border: none;
    color: variables.$black;

    &:hover {
      background: variables.$wds-orange;
    }

    &:disabled {
      background: variables.$white;
      border: 1px solid variables.$grey-500;
      color: rgb(210, 208, 206);
    }
  }
  .cancel-button {
    margin-left: 15px;
  }
  .new-property-divider {
    height: 5px;
    width: 100%;
    background: variables.$wds-orange;
    margin: 30px 0 20px;
  }
  .autocomplete-dropdown-container {
    z-index: 20;
    
    .suggestion-item {
      border-right: 1px solid variables.$grey-400;
      border-bottom: 1px solid variables.$grey-400;
      border-left: 1px solid variables.$grey-400;
      padding: 5px;
      background: variables.$white;
      cursor: pointer;
      
      &--active {
        border-right: 1px solid variables.$grey-400;
        border-bottom: 1px solid variables.$grey-400;
        border-left: 1px solid variables.$grey-400;
        padding: 5px;
        background: variables.$grey-200;
        cursor: pointer;
      }
    }
  }
  .address-input:focus {
    outline: none;
  }
}