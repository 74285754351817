@use 'sass/abstracts/variables';

.tag-bar {
  color: variables.$black;
  display: flex;
  justify-content: space-between;

  .tag-item {
    font-weight: 600;
    height: fit-content;
    text-transform: capitalize;
  }
}