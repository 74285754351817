@use 'sass/abstracts/variables';

.group-header {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}

.input-group-wrap {
  .group-item {
    margin-top: 14px;
  }

  .ig-label {
    font-style: italic;
  }
}

.group-input {
  height: 36px;

  &.ms-TextField .ms-TextField-fieldGroup {
    border: 1px solid variables.$grey-300;
    border-radius: 0;
    height: 36px;
  }

  &.ms-Dropdown-container {
    .ms-Dropdown-title {
      border: 1px solid variables.$grey-300;
      border-radius: 0;
      height: 36px;
    }

    .ms-Dropdown-caretDownWrapper .ms-Dropdown-caretDown {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid #000;
      display: inline-block;
      margin-left: 5px;
      color: #fff;
      margin-top: 14px;
    }
  }
}

.validate-error {
  color: variables.$red;
}

.text-password {
  color: variables.$blue-light;
  display: flex;

  .ms-Button {
    margin-top: -4px;
  }
}

.full-row .input-group-wrap {
  flex: 1 1 auto;
}
