@use 'sass/abstracts/variables';

.ft-data-table {
  font-family: variables.$main-font-family !important;
}

.ft-table-wrap {
  width: 100%;
  height: 300px;
  border-bottom: 1px solid variables.$grey-300;
}

.ft-header-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
  padding: 8px 6px;

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  .ms-SearchBox {
    height: 36px;
    width: 275px;
    border-radius: 0;
    border: 1px solid variables.$grey-300;
    flex-flow: row-reverse;
  }

  input.ms-SearchBox-field::placeholder {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }

  .ms-SearchBox-icon {
    transform: rotateY(180deg);
    color: variables.$grey-300;
    font-size: 20px;
  }
}

.ft-stauts-bar {
  margin-top: 11px;
  display: flex;
  justify-content: space-between;

  .ft-status-count {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
}

/*overide ag grid styles*/

.ft-data-table {
  .ag-theme-quartz .ag-header {
    border: 0;
    background-color: #fff;
  }

  .ag-header-row .ag-cell-label-container .ag-header-cell-text {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .ag-theme-quartz {
    .ag-header-cell {
      padding-left: 6px;
      padding-right: 6px;
    }

    .ag-row, .ag-header-row.ag-header-row-column {
      border: 0;
    }
  }

  .ag-row .ag-cell {
    font-size: 12px !important;
    line-height: 30px !important;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.ft-menu-item {
  font-family: variables.$main-font-family;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  color: #000;

  &.ag-menu-option-active {
    background-color: #000;
    color: #fff;
  }
}
