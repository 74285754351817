@use 'sass/abstracts/variables';

.new-pha-form {
  width: 1200px;
  padding: 15px;
  border-top: 8px solid variables.$wds-orange;
}

.new-pha-form-heading {
  display: flex;
  justify-content: space-between;
}

.pha-fire-state-id {
  display: flex;
  margin-bottom: 10px;
}

.pha-fire-location {
  display: flex;

  .location-item {
    margin-right: 10px;
    flex: 1;
    border: 1px solid variables.$grey-500;
    border-radius: 2px;
    padding: 8px 5px;
    position: relative;
    min-height: 35px;
  }
}

.location-label {
  position: absolute;
  top: -12px;
  background: variables.$white;
  padding: 0px 3px;
}

.pha-fire {
  display: flex;
  margin-bottom: 20px;
  max-width: 65%;

  > * {
    flex: 1;
    margin-right: 10px;
  }
}

.pha-select-fields {
  margin-bottom: 15px;

  label {
    display: none;
  }
}

.new-pha-property-status, .property-status-right, .property-status-left {
  display: flex;
}

.property-status-right input {
  height: 32px;

  &:nth-child(-n+2) {
    margin-right: 10px;
  }
}

.new-pha-form {
  .comments-col {
    width: 30%;
  }

  .category-list {
    display: flex;
    flex-direction: row;
    max-height: none;

    .action-items-group {
      margin-right: 10px;
    }
  }
}

.close-new-pha-form {
  color: variables.$wds-orange;
}

.new-pha-property-status {
  margin-top: 15px;
  justify-content: space-between;

  label {
    display: none;
  }
}

.property-status-left > {
  :first-child {
    margin-right: 20px;
  }

  :nth-child(2) {
    margin-right: 10px;
  }
}

.new-pha-form-content {
  margin: 20px;
}

.new-pha-form-heading {
  margin: 0px 20px 20px;
}

/* Autocomplete Address styles */

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid variables.$grey-200;
  border-bottom: none;
  border-top: none;
  z-index: 99;

  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;

  div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid variables.$grey-200;

    &:hover {
      /*when hovering an item:*/
      background-color: variables.$grey-200;
    }
  }
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: variables.$white;
}

.save-new-pha {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.new-pha-callout {
  width: 400px;
  padding: 18px;
  border: 3px solid red;
}

.pha-time-input {
  position: relative;

  span {
    position: absolute;
    top: -12px;
    left: 5px;
    background: variables.$white;
    padding: 0px 3px;
  }
}
