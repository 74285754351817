@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.fp-properties-list {
  position: relative;
  height: 100%;

  .fp-pl-filter {
    
    .fp-pl-filter-header {
      display: flex;
      justify-content: space-around;
      padding: 15px 5px;
      background: variables.$black;
  
      .fp-pl-filter-item {
        min-width: 0;
  
        button {
          padding: 0 5px;
          min-width: 0px;
        }
      }
    }
  
    .fp-pl-filter-body {
      display: none;
    }
  
    .fp-pl-filter-body-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 30px;
      background: variables.$black;
  
      p {
        margin: 0;
      }
    }
  
    .fp-pl-filter-body-filter {
      background: variables.$white;
      padding: 5px 60px 5px 30px;
  
      .ms-Checkbox {
        margin-bottom: 5px;
      }
  
      .ms-Checkbox-label {
        width: 100%;
        justify-content: space-between;
      }
    }
  
  }

  .gold-filter {
    .ms-Button--default {
      background: variables.$wds-orange;
    }
  }
}
