@use 'sass/abstracts/variables';

.dp-selector {
  position: relative;

  .ms-Dropdown-container {
    width: 120px;

    .ms-Dropdown-title {
      color: #fff;
    }
  }

  .title {
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    position: absolute;
    font-weight: bold;
    left: 10px;
    top: 8.4px;
  }
}

.selector-wrap .ms-Callout-main {
  max-height: 380px !important;
}

.dp-selector .ms-Dropdown-caretDown {
  width: 0px;
  height: 0px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #000;
  display: inline-block;
  margin-left: 5px;
  margin-top: 13px;
  color: transparent !important;
}

.selector-wrap {
  .ms-Callout-main {
    .ms-Checkbox-checkbox {
      background-color: #fff;
      border-color: variables.$grey-300;
      border-radius: 0;
    }

    .ms-Checkbox.is-checked .ms-Checkbox-checkmark {
      height: 14px;
      width: 14px;
    }
  }

  .ms-Checkbox-label .ms-Dropdown-optionText {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
  }
}
