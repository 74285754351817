@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.page-full-size-map-sitmap .bottom-right.subcontrol-container, .page-full-size-map-fp .bottom-right.subcontrol-container {
  bottom: 90px;
  z-index: 0;
  transition: right .3s ease-in-out;

  @include mixins.desktop {
    bottom: 40px;

    &.panel-show {
      right: 500px;
    }
  }
}