﻿@use 'sass/abstracts/variables';

.workflow-management-main-container {
  font-family: variables.$main-font-family;
  padding: 28px 30px 30px 30px;

  .page-title {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
  }

  .welcome {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 4px;
  }

  .container-fluid {
    padding: 0;
    margin: 0;
  }

  .row {
    margin-top: 10px;
    margin-right: 0px;
    margin-left: 0px;

    .left, .right {
      padding: 0px;
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
  }
}

.daily-title {
  margin-top: 5px;
  margin-bottom: 10px;

  img {
    margin-right: 8px;
    margin-left: 10px;
  }

  span {
    vertical-align: middle;
  }
}

.hidden {
  display: none;
}

/* responsive for region map and announcement */
@media (max-width: 1200px) {
  .regionMap-container {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .regionMap-container {
    margin-right: 7px;
    margin-bottom: 0px;
  }

  .announcementBoard-container {
    margin-right: 7px;
  }
}
