﻿@use 'sass/abstracts/variables';

.loading-snap {
  text-align: center;
  height: 100%;

  img {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}