@use 'sass/abstracts/variables';

.frmap-client {
  position: relative;
  display: flex;

  .ms-Dropdown-container {
    width: 120px;

    .ms-Dropdown-title {
      color: #fff;
      height: 35px;
      line-height: 33px;
    }
  }

  .title {
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    position: absolute;
    font-weight: bold;
    left: 10px;
    top: 8.4px;
  }
}

.frmap-wrap .ms-Callout-main {
  max-height: 380px !important;
  background-color: variables.$dropdown-background;
}

.frmap-client .ms-Dropdown-caretDown {
  width: 0px;
  height: 0px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #000;
  display: inline-block;
  margin-left: 5px;
  margin-top: 13px;
  color: transparent !important;
}

.frmap-wrap {
  .ms-Callout-main {
    .ms-Checkbox-checkbox {
      background-color: #fff;
      border-color: variables.$grey-300;
      border-radius: 0;
    }

    .ms-Checkbox.is-checked .ms-Checkbox-checkmark {
      height: 14px;
      width: 14px;
      background-color: variables.$grey-300;
      color: #000;
    }
  }

  .ms-Checkbox-label .ms-Dropdown-optionText {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: variables.$dropdown-font-size;
    line-height: variables.$dropdown-line-height;
    color: variables.$white;
 
  }
}

.frmap-all-clients {
  background-color: variables.$wds-orange;
  border-color: variables.$wds-orange;
  font-family: variables.$main-font-family;
  font-weight: bold;
  margin-left: 15px;
  padding: 0 20px;
  line-height: 33px;
  height: 35px;

  &:hover {
    background-color: variables.$wds-orange;
    border-color: variables.$wds-orange;
  }
}
