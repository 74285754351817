﻿@use 'sass/abstracts/variables';

.announcementBoard-container {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.announcementBoard-header {
  display: flex;
  justify-content: space-between;
}

.dailyAssignments-container .icon-title {
  margin-bottom: 10px;
}

.announcementBoard-header {
  .icon-title img {
    margin-right: 8px;
  }

  .last-updated {
    font-size: 12px;
    line-height: 20px;
    font-style: italic;
    padding-left: 28px;
  }
}

.announcementBoard-buttons {
  height: 36px;
  display: flex;
}

/*tabs*/

.announcementBoard-tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-bottom: 2px solid variables.$wds-orange;
    z-index: -999;
  }

  .item {
    width: 79px;
    height: 36px;
    line-height: 36px;
    background-color: #000;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
  }

  .selected {
    background-color: variables.$wds-orange;
    color: #000;
  }
}

.announcementBoard-editor {
  flex: 1;
  margin-top: 10px;

  .text-editor, .ms-TextField, .ms-TextField-wrapper, .ms-TextField-fieldGroup {
    height: 100%;
  }

  .ms-TextField-field {
    font-family: variables.$main-font-family !important;
 
  }

  textarea:disabled {
    background-color: white;
    color: variables.$black;
    border: 1px solid variables.$grey-300;
  }
}

.announcementBoard-metrics {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin-top: 5px;
  display: flex;

  .first-col {
    width: 80px;
  }

  .metrics-col {
    margin-right: 20px;
  }
}

.announcementBoard-iai {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.announcementBoard-iai-editor {
  flex: 1;
  margin-bottom: 3px;
}
