@use 'sass/abstracts/variables';

%orange-toggle {
  background-color: variables.$wds-orange;

  &:hover {
    background-color: variables.$wds-hover-orange;
  }
}

.toggle-collapse.is-checked button, .ms-Toggle.is-checked button {
  @extend %orange-toggle;
}

// Styles for PlusMinusIcon component
.plusminus {
	position: relative;
	width: 25px;
	height: 25px;
	cursor: pointer;
	
	&.active {
		&:before {
			transform: translateY(-50%) rotate(-90deg);
			opacity: 0;
		}
		&:after {
			transform: translateY(-50%) rotate(0);
		}
	}
	
	&:before , &:after {
		content: "";
		display: block;
		background-color: variables.$wds-orange;
		position: absolute;		
		top: 50%;
    left: 0;
		transition: .35s;
		width: 100%;
		height: 3px;
	}
	
	&:before {		
		transform: translateY(-50%);
	}
	
	&:after {
		transform: translateY(-50%) rotate(90deg);
	}
	
}