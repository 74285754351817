@use 'sass/abstracts/variables';

.pu-fire-type-chart {
  width: 430px;
  height: 270px;
  padding: 0 0.5rem;
  margin-right: 60px;
  margin-bottom: 30px;
}

.pu-card {
  height: 100%;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 5px;
}

.pu-card-yellow {
  background-color: variables.$wds-orange;
  color: variables.$black;
}

.pu-card-dark {
  background-color: variables.$navy;
  color: variables.$white;
}

.pu-card-light {
  background-color: variables.$grey-300;
  color: variables.$black;
  
}

.pu-fire-type-chart .h-50 + .h-50 {
  margin-top: 0.6rem;
}

.pu-card div + div {
  margin-top: 20px;
}
