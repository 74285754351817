@use 'sass/abstracts/variables';

.action-title {
  height: variables.$grid-header-height;
  color: variables.$black;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-flow: wrap;
}

.map-icon {
  color: variables.$black !important;
  margin: 5px;
}

.page-divider {
  height: 4.41px;
  background: variables.$black;
}

.action-info {
  padding: 7px;
  margin: 5px 0px 10px 0px;
  font-weight: 600;
  background: variables.$grey-100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  justify-content: space-between;

  .action-date,
  .coordinates-link {
    color: variables.$black;
    font-weight: 600;
  }

  .dp-status {
    display: flex;
    align-items: center;
  }
}

.action-list span {
  font-size: 14px;
}

.action-list-tooltip {
  font-size: 14px !important;
  line-height: 17px !important;

  color: variables.$black !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  text-align: left !important;

  &::before,
  &::after {
    display: none;
  }
}

.dp-status {
  .ms-Label.ms-Dropdown-label {
    color: variables.$black;
    padding: 0 10px 0 0;
    border-radius: 5px;
  }

  .ms-Dropdown-title,
  .ms-Dropdown-caretDownWrapper {
    height: 20px;
    line-height: 18px;
  }
}

.comments-wrapper {
  display: flex;
  flex-direction: row;
  background: variables.$grey-100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 20px 20px 20px;
  justify-content: space-between;
}

.comments-col {
  display: flex;
  flex-direction: column;

  /*width: 50%;*/
  flex: 1;
  max-width: 500px;
}

.action-comments .ms-Label,
.engine-comments .ms-Label {
  color: variables.$black;
  text-align: left;
  padding-top: 0;
  flex-grow: 1;
}

.action-comments .ms-TextField-fieldGroup,
.engine-comments .ms-TextField-fieldGroup {
  flex-direction: column;
  height: 100px;
  resize: none;
}

.action-comments {
  margin-bottom: 10px;
  margin-right: 15px;
}

.engine-comments {
  margin-bottom: 10px;
  margin-right: 15px;

  .ms-TextField-field {
    color: rgba(0, 0, 0, 0.65);
    background-color: rgb(252, 252, 252);
  }
}


.pha-metadata {
  border-top: 4px solid black;
  margin: 40px 10px;
  padding: 10px 0 0 0;

  p {
    font-weight: 400;
  }
}