@use 'sass/abstracts/variables';

.resourcestatus-map .frmap-engine {
  width: 118px;

  .ms-Dropdown-container .ms-Dropdown-title {
    font-family: variables.$main-font-family !important
  }
}

.rs-engine-dropdown .ms-Checkbox-label {
  .ms-Checkbox-checkbox {
    border-color: variables.$black !important;
  }

  .ms-Dropdown-optionText {
    color: variables.$black !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
  }
}
