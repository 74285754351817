@use 'sass/abstracts/variables';
@use 'sass/components/buttons';

.dp-header {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: variables.$black;
  border-bottom: 1px solid variables.$white;
  border-radius: 5px 0 0 0;
}

.dp-icon {
  flex: 0 0 auto;
  margin-right: 15px;

  svg {
    height: 40px;
  }
}

.dp-close {
  @extend %cancel-icon;
}

.dp-name-status {
  p {
    margin-bottom: 0px;
  }
}

.dp-address {
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
}