@use 'sass/abstracts/variables';

.resourcestatus-center-layer-wrap {
  height: 32px;
  line-height: 32px;
  width: 118px;
}

.layer-wrap.resourcestatus-center-layer-wrap .dp-layer {
  right: auto;
  background-color: white;
  color: variables.$black;
  padding: 2px 10px 8px 10px;
  border: 1px solid variables.$grey-300;
  min-width: 118px;
  max-height: 450px;
  overflow-y: auto;

  li:not(:last-child) {
    margin-bottom: 0px;
  }
}

.resourcestatus-center-layer-wrap {
  .ms-Dropdown {
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    position: relative;
    outline: 0px;
    border: 1px solid rgb(196, 196, 196);
  }

  .ms-Dropdown-title {
    box-shadow: none;
    margin: 0px;
    padding: 0px 28px 0px 8px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-width: 0px;
    border-style: solid;
    border-color: rgb(96, 94, 92);
    border-radius: 2px;
    cursor: pointer;
    display: block;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 14px;
  }

  .ms-Dropdown-titleIsPlaceHolder .ms-Dropdown-title {
    background-color: variables.$grey-200;
    cursor: default;
  }

  .ms-Dropdown {
    .ms-Dropdown-caretDownWrapper {
      height: 32px;
      line-height: 30px;
      padding-top: 1px;
      position: absolute;
      right: 8px;
      top: 0px;
      cursor: pointer;
    }

    &.ms-Dropdown-titleIsPlaceHolder .ms-Dropdown-caretDownWrapper {
      cursor: default;
    }
  }

  .layer-chbi .ms-Checkbox .ms-Checkbox-text {
    font-size: 14px;
  }
}

/* title and caretDownWrapper*/

.layer-wrap.resourcestatus-center-layer-wrap {
  .chbi-chb .ms-Checkbox-label {
    top: 0px;
  }

  .layer-chbi .ms-Checkbox {
    height: 36px;
    line-height: 36px;
  }

  .dp-layer .sub-item {
    padding-left: 20px;

    .layer-chbi .ms-Checkbox {
      height: 24px;
      line-height: 24px;
    }
  }

  .chbi-chb .ms-Checkbox-label .ms-Checkbox-text {
    color: variables.$black;
  }

  .layer-chbi .chbi-chb {
    .ms-Checkbox-checkbox {
      border-width: 1px;
      background-color: transparent !important;
      border-color: #000 !important;
      height: 14px;
      width: 14px;
      border-radius: 0;
    }

    &.is-disabled .ms-Checkbox-checkbox {
      border-color: variables.$grey-300 !important;
    }

    .ms-Checkbox-checkbox::after {
      background-color: #fff;
      width: 8px;
      height: 8px;
      top: 2px;
      left: 2px;
      display: block;
      content: "";
    }

    &.is-checked {
      .ms-Checkbox-checkbox::after {
        background-color: #000;
      }

      &.is-disabled .ms-Checkbox-checkbox::after {
        background-color: variables.$grey-300;
      }
    }
  }
}