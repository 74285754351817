﻿@use 'sass/abstracts/variables';

.bg-EEE {
  background-color: variables.$grey-100;
}

.bg-CCC {
  background-color: variables.$grey-200;
}

.rs-f-color-000 {
  color: variables.$black;
}

.rs-f-color-31B {
  color: variables.$blue-light;
}

.rs-f-color-999 {
  color: variables.$grey-400;
}

.rs-f-weight-700 {
  font-weight: 700 !important;
}

.rs-f-size-20 {
  font-size: 1.25rem !important;
}

.rs-f-size-12 {
  font-size: .75rem !important;
}

.rs-line-height-45 {
  line-height: 2.8rem !important;
}

.rs-cursor-hand {
  cursor: pointer;
}

.rs-height-zero {
  height: 0px;
  overflow: hidden;
}

.rs-height-row-30 {
  height: 30px !important;
}

.rs-lineheight-row-30 {
  line-height: 30px !important;
}

.rs-section-collapse {
  display: block;
  width: 12px;
  text-align: center;
}

.rs-page.ag-theme-quartz {
  .ag-paging-panel {
    border-top: none;
    border-top-color: none;
    height: auto;
  }

  .ag-header-cell-label .ag-header-cell-text.rs-f-size-12 {
    font-size: .75rem !important;
  }
}

.rs-min-text-width, .rs-min-text-width2 {
  display: inline-block;
}

.rs-width-100 {
  width: 100px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rs-page.ag-theme-quartz .ag-header-cell-label.no-flex {
  flex: none;
}

.rs-body-max-height {
  max-height: 300px;
  overflow-y: auto;
}

.rs-border-lr-c4 {
  border-left: 1px solid variables.$grey-300;
  border-right: 1px solid variables.$grey-300;
}

.rs-bar {
  height: 10px;
  width: 100%;
  margin-top: -20px;
  position: relative;
  top: 0px;
  left: 0px;

  .color-status {
    display: block;
    height: 10px;
  }
}

.rs.custom-react-tooltip {
  background-color: variables.$white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &.show {
    opacity: 1 !important;
  }

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.rs-page.ag-theme-quartz {
  .ag-header-cell-sorted-asc .ag-sort-ascending-icon, .ag-header-cell-sorted-desc .ag-sort-descending-icon {
    display: block !important;
  }
}

.rs-scroll {
  &::-webkit-scrollbar-track {
    background-color: variables.$grey-200;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: variables.$grey-200;
  }

  &::-webkit-scrollbar-thumb {
    background-color: variables.$grey-300;
  }
}

.rs-header-controls {
  height: 48px;
}

.rs-toggle {
  margin-bottom: 0;

  .ms-Toggle-background {
    background-color: variables.$wds-orange;
    border-color: variables.$wds-orange;
    width: 45px;
  }

  .ms-Toggle-thumb, &:hover .ms-Toggle-thumb {
    background-color: white;
  }
}

.rs-toggle-label {
  font-family: variables.$main-font-family;
  font-weight: 700;
  margin: 0 12px;
}

.rs-table-header-subtitle {
  font-size: 12px;
  margin-bottom: 5px;
}

.rs-marker {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 2px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.rs-m-s {
  display: none;
}

@media (max-width: 1199px) {
  .rs-m-l {
    display: none;
  }

  .rs-m-s {
    display: inline;
  }
}

/* forms modal */

.center-form {
  width: 800px;
  padding: 15px;
}

.center-form-heading {
  display: flex;
  justify-content: space-between;
}

.rs-form-dropdown {
  max-height: 300px !important;
}

/* engine resource form */

.gridTable {
  float: left;
  display: flex;
  width: 100%;
  clear: both;
}

.topLeft {
  width: 50%;
  text-align: left;
}

.topRight-boder {
  width: 50%;
  border: 1px solid black;
}

.topRight, .second-grid {
  float: left;
  display: flex;
  width: 100%;
  clear: both;
}

.second-topLeft {
  width: 50%;
  text-align: left;
}

.second-topRight {
  width: 50%;
  margin-bottom: 10px;
  clear: both;
  float: left;
}

.rioline {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
}

.riohead {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rioheadText {
  font-style: normal;
  font-weight: bold;
  color: variables.$black;
}

.fullColumn {
  width: 100%;
}

.p-075 {
  padding: 0.75rem;
}

.p-075tb {
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  clear: both;
}

.rs-status-hidden {
  pointer-events: none;
}

.duplicateVINCallout {
  width: 343px;
  padding: 18px;
  border: 3px solid red;
}

.rs-field-name {
  max-width: 100%;
  padding: 0 5px;
}
