﻿@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';


.resourcestatus-kpibox {
  margin-bottom:5px;
  @include mixins.kpi-box(25px);

  .kpi-white-box {
    @include mixins.kpi-white-box(25px)
  }

  .kpi-metric{
    @include mixins.kpi-metric();
  }

  .kpi-value {
    display: flex;
    flex-direction: column;
    @include mixins.kpi-value();    
  }

  .kpi-change{
    display: flex;
    flex-direction: column;
    @include mixins.kpi-change();
  }
}

  .kpiselect-btn {
    height: auto;

    .row {
      flex-direction: row;
      justify-content: left;
      padding-bottom: 5px;

      .select-btn {
        flex: 1;
        padding: 0 3px;
        margin-top: 8px;
      }
    }
  }

.kpi-datetime{
  font-weight:700;
  font-size:12px;
  margin-bottom:2px;
  max-width:120px;
  text-align:center;
  overflow:visible;  
  overflow-wrap:break-word;
}
  .kpititle .box-edit {
    font-size: 12px;
    line-height: 14px !important;
    bottom: 8px;
  }

  .box-left .row {
    width: 100%;
  }

  .kpititle {
    .box-date {
      width: 250px;
    }

    .percent25 {
      width: 25%;
    }

    .box-edit {
      margin-top: 115px;
    }
  }


@media (max-width: 1470px) {
  .resourcestatus-kpibox .kpiselect-btn .row .select-btn {
    width: 270px;
    flex: none;
  }
}
