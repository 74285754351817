@use 'sass/abstracts/variables';

.wz-resources {
  width: 168px;
  height: fit-content;
  background: variables.$white;
  border: 1px solid variables.$grey-300;
  margin-top: -9px;
  margin-right: -7px;
  margin-left: 13px !important;
}

.wz-resources-title {
  height: 31px;
  background: variables.$wds-orange;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  color: variables.$black;
  padding-left: 9px;
}

.wz-resources-subtitle {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: variables.$black;
  margin: 0 8px;
  padding: 9px 2px 7.5px;
  border-bottom: 1px solid variables.$grey-300;
}

.wz-resources-body {
  overflow-y: auto;
  padding: 7.5px 10px 10px;
  height: 271px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: variables.$grey-200;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: variables.$grey-300;
  }
}

.wz-resource {
  width: 119px;
  height: 22px;
  background: variables.$green-100;
  border: 1px solid variables.$green-300;
  border-radius: 30px;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  vertical-align: middle;
  color: variables.$black;
  padding: 0 13px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  + .wz-resource {
    margin-top: 4px;
  }
}

.wz-resources-toggle {
  cursor: pointer;
  user-select: none;
  display: inline-block;
  padding: 6px;
  line-height: 19px;
}

.wz-resources-expander {
  overflow: hidden;
  width: 100%;
  height: 0;

  &.expaneded {
    height: 323px;
  }
}
