@use 'sass/abstracts/variables';
.pu-layout-fire-overview {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.pu-layout-fire-overview > div {
  flex: none;
}

.pu-layout-fire-dispatch {
  max-width: 1200px;
  margin:0 auto;
  min-height: 338px;
}

.pu-layout-fire-dispatch > canvas {
  height: 338px;
}

.pu-properties-tiles {
  width: 645px;
  height: 270px;
  padding: 0 0.5rem;
  margin-right: 60px;
  margin-bottom: 30px;
}

.pu-properties-charts{
  margin: 5px;
  border: solid;
  border-width: 1px;
  border-color: variables.$grey-300;
  border-radius: 7px;
  margin-bottom: 15px;
}