@use 'sass/abstracts/variables';

p {
  margin: 0 0 5px 0;
}

/* Size */
.fop-fs-25 {
  font-size: 25px;
}
.fop-fs-20 {
  font-size: 20px;
}
.fop-fs-16 {
  font-size: 16px;
}
.fop-fs-14 {
  font-size: 14px;
}
.mt-3-1 {
  margin-top: 1.5rem !important;
}
.mr-4-1 {
  margin-right: 2rem !important;
}
.ml-4-1 {
  margin-left: 2rem !important;
}

/* Style of text */
.text-bold-1 {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  color: variables.$black;
}
.text-normal-1 {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: normal;
  color: variables.$black;
}
.text-1 {
  font-size: 12px;
  line-height: 14px;
}
.text-2 {
  font-size: 14px;
  line-height: 17px;
}
.text-2-checkbox {
  font-size: 14px;
  line-height: 17px;
  margin-left: 0.5em;
  margin-top: 1.5em;
}
.text-2-1 {
  font-size: 16px;
  line-height: 19px;
}
.text-3 {
  font-size: 20px;
  line-height: 24px;
}
.text-4 {
  font-size: 25px;
  line-height: 30px;
}
.text-5 {
  font-size: 28px;
  line-height: 24px;
}
.no-wrap {
  white-space: nowrap;
}
.fop-fs-bold {
  font-weight: bold;
}

/* Style of Lines */
.hr-1 {
  height: 4.41px;
  background: variables.$black;
}

/* Color */
.fop-text-black {
  color: variables.$black;
}
.red-warn {
  color: variables.$red;
  font-weight: normal;
}
.non-dispatch-color {
  color: variables.$non-dispatch;
}
.demob-color {
  color: variables.$demob;
}
@media (max-width: 1280px) {
  .fop-fs-25 {
    font-size: 21px;
  }

  .fop-fs-20 {
    font-size: 16px;
  }

  .fop-fs-16 {
    font-size: 12px;
  }

  .fop-fs-14 {
    font-size: 10px;
  }

  .pl-dropdown .ms-Dropdown {
    font-size: 12px;
  }

  .pfi-tab .ms-Dropdown-callout .ms-Dropdown-optionText {
    font-size: 10px;
  }
}

%label {
  font-size: 12px;
}