@use 'sass/abstracts/variables';

.atc-wrap {
  position: relative;

  .atc-search-icon {
    position: absolute;
    right: 8px;
    top: 1.4px;
    transform: rotateY(180deg);
    color: variables.$grey-300;
    font-size: 20px;
  }

  .ms-BasePicker {
    .ms-BasePicker-text {
      border: 0;
    }

    .ms-BasePicker-input {
      height: 32px;
      border: 1px solid variables.$grey-300;
    }
  }
}

.atc-item {
  vertical-align: middle;
  display: inline-block;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  text-align: start;

  a {
    text-decoration: none !important;
    color: rgb(50, 49, 48) !important;
    margin: 0px 4px;
    padding: 8px;
    display: block;
  }
}

.atc-picker {
  margin-top: -5px;
}
