@use 'sass/abstracts/variables';

.act-srch {
  margin-left: 50px;
}

.map-actions {
  .ms-Button-textContainer {
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
 
  }

  .act-dispatch.ms-Button .ms-Icon {
    width: 0px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #000;
    display: inline-block;
    margin-left: 5px;
    color: #fff;
  }
}

.act-dispatch.ms-Button .ms-Button-label {
  font-family: variables.$main-font-family;
  font-weight: bold;
  font-size: 14px;
  color: variables.$black;
}

.act-srch {
  input.ms-SearchBox-field {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 14px;
    color: variables.$black;

    &::placeholder {
      font-family: variables.$main-font-family;
      font-weight: bold;
      font-size: 14px;
      color: variables.$black;
   
    }
  }

  .ms-BasePicker-text input::placeholder {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 14px;
    color: variables.$black;
 
  }
}

.map-actions .act-srch.layer-wrap {
  width: 193px;
}

.sitmap-sltr-wrap {
  background-color: rgba(0, 0, 0, 0.8);

  a {
    color: white;
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: variables.$dropdown-font-size;
    line-height: variables.$dropdown-line-height;

    &:hover {
      color: white;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
