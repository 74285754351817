@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.ssp-stats {
  display: block;

  &-active {
    overflow-y: auto;
  }
  ul {
    padding-left: 0;
  }

  .stats-icon {
    width: 9%;
  }

  .sideBarTitle {
    text-align: center;
    margin-top: 10px;
  }
  
  .figure {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 5px;
    text-align: right;
  }

  .disp-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid variables.$grey-400;
    padding: 15px 0;
  
    &:last-child {
      border: none;
    }
  }
}

.ssp-properties {
  overflow-y: auto;
  max-height: 100%;
  padding: 0 10px 0 10px;

  h4 {
    text-align: center;
    margin-top: 10px;
  }

  a {
    text-decoration: none;
    color: white; 
    display: flex;
    align-items: center;
    padding: 15px 0 15px 0;
    margin: 0 5px 0 5px;
    position: relative;
  }

  a:active {
    color: variables.$wds-orange-rgba;
  }

  p {
    margin: 0;
    overflow-wrap: break-word;
    padding-right: 20px;
  }

  .properties-li {
    align-items: center;
    border-bottom: 2px solid variables.$grey-400;
    list-style-type: none;

    &:last-child {
      border: none;
    }
    &.selected {
      background: variables.$wds-orange-rgba;
    }
  }

  .properties-info {
    color: white;
  }

  .properties-icon {
    padding-right: 10px;
    display: inline-block
  }
}