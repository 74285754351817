﻿@use 'sass/abstracts/variables';

.historic-fires-title-tooltip-icon {
  margin-left: 10px;
}

.historic-fires-title-tooltip-icon-2 {
  margin-left: 45px;
}

.historic-fires-title-tooltip {
  font-size: 16px !important;
  line-height: 19px !important;
  color: variables.$black !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  text-align: left !important;

  .multi-line {
    text-align: left !important;
  }

  &::before, &::after {
    display: none;
  }
}

.hf-error-message {
  position: absolute;
  height: 63px;
  line-height: 63px;
  left: 50%;
  transform: translate(-50%, -31.5px);
  top: 50%;
  padding: 0 23px;
  white-space: nowrap;
  background: variables.$white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.hf-table-link {
  text-decoration: none;
  color: variables.$black;
}

.hf-fires-table {
  margin-bottom: 27px;

  .ag-overlay-no-rows-wrapper {
    background: variables.$grey-100;
    height: 35px;
    position: absolute;
    top: 49px;
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 15.8333px;
    line-height: 35px;
    color: variables.$black;
    justify-content: flex-start;
    padding-left: 23px;
  }

  &.ag-theme-quartz {

    .ag-header-cell {
      padding-right: 0 !important;
      padding-left: 23px !important;
    }

    .ag-cell {
      padding-right: 0 !important;
      padding-left: 23px !important;
      line-height: 33px;
    }

    .ag-cell-value {
      font-weight: normal;
    }
  }
}

.hf-tf-count {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 25px;
  color: variables.$white;
}

.main-container .hf-page-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: variables.$black;
  height: 68px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
}

.page-main .hf-border {
  position: relative;
}

/* Historic Fires Styles */

.title-demobilized {
  border-bottom: 4px solid variables.$green-400;
}

.fire-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  padding: 13px 0px;

  .imgicon {
    position: absolute;
    height: 36px;
    padding-left: 12px;
  }

  img {
    filter: drop-shadow(0 0 1px variables.$grey-300);
    -webkit-filter: drop-shadow(0 0 1px variables.$grey-300);
  }

  .ms-SearchBox {
    flex-flow: row-reverse nowrap;
    background: variables.$white;
    border: 1px solid variables.$grey-300;
    box-sizing: border-box;
  }

  .ms-SearchBox-field {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;

    &::placeholder {
      font-family: variables.$main-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: variables.$black;
   
    }
  }

  .ms-SearchBox-icon {
    color: variables.$grey-300;
    transform: rotate(270deg);
  }
}