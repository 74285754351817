.fireoverview-layer-wrap {
  height: 35px;

  .dp-header {
    justify-content: center;
    padding: 0 20px;
  }

  .layer-chbi .ms-Checkbox .ms-Checkbox-text {
    font-size: 12px;
  }

  .dp-layer {
    background-color: rgba(0, 0, 0, 0.8);
  }
}