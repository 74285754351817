@use 'sass/abstracts/variables';

.cl-items-wrap {
  position: absolute;
  right: 0;
  background-color: variables.$dropdown-background;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 380px;
  padding: 11px 14px;
  white-space: nowrap;
  scrollbar-gutter: stable;

  .ms-ChoiceField-field {
    &::before {
      border-color: variables.$white !important;
      background-color: transparent;
    }

    &.is-checked::after {
      border-color: variables.$white;
      background-color: variables.$white;
    }
  }

  .ms-ChoiceField-wrapper .ms-ChoiceFieldLabel {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: variables.$dropdown-font-size;
    line-height: variables.$dropdown-line-height;
    color: variables.$white;
 
  }
}
