@use 'sass/abstracts/variables';

.rs-dialog-schedule {
  .rs-supervisors-list {
    position: relative;
    font-family: variables.$main-font-family !important;
    box-sizing: border-box;

    * {
      box-sizing: inherit;

      &::after, &::before {
        box-sizing: inherit;
      }
    }
  }

  .rs-supervisors-list__value {
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 13px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 7px solid #000;
    }
  }

  .rs-supervisors-list__input {
    border: 1px solid rgb(196, 196, 196) !important;
    padding: 0 25px 0px 5px !important;
    display: block;
    height: 36px;
    width: 100%;
    background: #fff;
    outline: none;
    line-height: 36px;
    -webkit-appearance: none;
  }

  .rs-supervisors-list-italic .rs-supervisors-list__input {
    font-style: italic;
  }

  .rs-supervisors-list__input {
    &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &:not([readonly]):focus {
      cursor: initial;
    }
  }

  .rs-supervisors-list__options {
    list-style: none;
    margin: 0px !important;
    padding: 0px !important;
  }

  .rs-supervisors-list__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  .rs-supervisors-list__option, .rs-supervisors-list__not-found {
    display: block;
    height: 36px;
    width: 100%;
    border: none;
    outline: none;
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: variables.$dropdown-font-size !important;
    line-height: variables.$dropdown-line-height !important;
    color: variables.$black !important;
    background-color: transparent !important;
    text-align: left !important;
    cursor: pointer !important;
    padding: 0 8px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .rs-supervisors-list__option {
    &.is-highlighted, &:not(.is-selected):hover {
      background: rgba(47, 204, 139, 0.1);
    }
  }

  .rs-supervisors-list {
    &:not(.is-disabled) .rs-supervisors-list__input {
      cursor: pointer;
    }

    .rs-supervisors-list__select {
      position: absolute;
      z-index: 999;
      top: 36px;
      right: 0;
      left: 0;
      overflow: auto;
      max-height: 360px;
      border: 1px solid variables.$grey-300;
      background: #fff;
    }
  }

  .rs-supervisors-list__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }

  .rs-supervisors-list__row {
    padding: 0px !important;
    margin: 0px !important;
  }
}