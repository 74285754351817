﻿@use 'sass/abstracts/variables';

.vth-btn-pad {
  padding: 0.25rem 0.5rem;
}

.vth-lh-23 {
  line-height: 2.3rem;
}

.wp-120 {
  width: 120px;
  min-width: 120px;
}

.wp-275 {
  width: 290px;
}

.vth-underline {
  text-decoration: underline;
}

.vth-no-dec {
  text-decoration: none;
}

.vth-cursor-hand {
  cursor: pointer;
}

.vth-load[aria-disabled="true"] {
  color: variables.$grey-300;
  cursor: default;
}

.vth-photo {
  list-style: none;
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 85px;
    height: 85px;
    border: 0.5px solid variables.$grey-300;
    box-sizing: border-box;
    margin-right: 0.35rem;
    padding: 0.4rem;
    margin-top: 0.5rem;

    img {
      background: variables.$grey-300;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  max-height: 12.5rem;
  overflow: hidden;
  overflow-y: auto;
}

.vth-date .ms-Dropdown-container {
  width: 8rem;
}

.vth-photo-modal {
  padding: 2.5rem;
  max-height: initial;
  max-width: initial;
  min-width: initial;
  min-height: initial;

  .vth-photo-container {
    max-width: 1200px;
    background-color: variables.$grey-300;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .vth-ph-modal-close {
    position: absolute;
    right: 8px;
    top: 8px;

    .ms-Button {
      color: variables.$black;
      font-weight: bold;
    }

    .ms-Icon {
      font-weight: bold;
    }
  }
}

.vth-data-container {
  max-height: 30rem;
  overflow: hidden;
  overflow-y: auto;
}

.vth-comment, .vth-action {
  max-height: 11rem;
  overflow: hidden;
  overflow-y: auto;
  white-space: normal;
  word-break: break-word;
}

.vth-data-container::-webkit-scrollbar-track, .vth-comment::-webkit-scrollbar-track, .vth-photo::-webkit-scrollbar-track, .vth-action::-webkit-scrollbar-track {
  background-color: variables.$grey-200;
}

.vth-data-container::-webkit-scrollbar, .vth-comment::-webkit-scrollbar, .vth-photo::-webkit-scrollbar, .vth-action::-webkit-scrollbar {
  width: 10px;
  background-color: variables.$grey-200;
}

.vth-data-container::-webkit-scrollbar-thumb, .vth-comment::-webkit-scrollbar-thumb, .vth-photo::-webkit-scrollbar-thumb {
  background-color: variables.$grey-300;
}

.vth-action {
  &::-webkit-scrollbar-thumb {
    background-color: variables.$grey-300;
  }

  ul {
    padding-left: 1.1rem;
  }

  li {
    list-style-type: disc;
  }
}

@media (max-width: 1280px) {
  .vth-data-container {
    .ms-5 {
      margin-left: initial !important;
    }

    .wp-120 {
      width: 100px !important;
      min-width: 100px !important;
    }

    .w-18 {
      width: 24% !important;
    }

    .mm-25 {
      min-width: 23% !important;
      max-width: 23% !important;
      width: 23% !important;
    }
  }

  .vth-photo li {
    width: 75px !important;
    height: 75px !important;
  }

  .vth-data-container .wp-275 {
    width: 260px !important;
  }
}

.pfi-tab .ms-Overlay--dark {
  background-color: rgba(0, 0, 0, 0.2);
}

.home-status-green {
  color: variables.$green-300;
}

.home-status-red {
  color: variables.$red;
}

.home-status-purple {
  color: purple;
}

.home-status-orange {
  color: orange;
}
