@use 'sass/abstracts/variables';

/*users filter dropdown*/

.user-management .client-selector.dp-selector {
  position: absolute;
  right: 300px;
  top: 8px;

  .ms-Dropdown {
    width: 113px;
  }

  .title {
    background-color: variables.$wds-orange;
    width: 113px;
    text-align: center;
    left: 0;
    z-index: -1;
    height: 36px;
    padding-top: 10px;
    top: 0;
  }

  .ms-Dropdown-title {
    background-color: transparent;
    color: transparent;
    height: 36px;
    border: 0;
    border-radius: 0;
  }

  .ms-Dropdown-caretDownWrapper {
    display: none;
  }

  .ms-Dropdown-title {
    color: transparent;
  }
}
