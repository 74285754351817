@use 'sass/abstracts/variables';

.fsb-layer {
  &.ms-Button {
    border: 0;
    border-radius: 0;
    background-color: variables.$wds-orange;

    &:hover {
      background-color: variables.$black;
      color: white;
    }
  }

  &.is-expanded, &:hover .ms-Button-label, &.is-expanded .ms-Button-label {
    background-color: variables.$black;
    color: white;
  }

  &.ms-Button {
    .ms-Button-label {
      font-family: variables.$main-font-family;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
    }

    .ms-Button-menuIcon {
      display: none;
    }
  }
}

.fsb-sub-wrap {
  list-style: none;
  padding: 0;

  .sub-item {
    padding-left: 30px;
  }
}

.fsb-popup .ms-ContextualMenu-submenuIcon {
  display: none;
}

.fsb-sub-wrap .chbi-chb .ms-Checkbox-label .ms-Checkbox-text {
  font-weight: bold;
  font-size: 12px;
  font-style: normal;
  font-family: variables.$main-font-family;
  line-height: 20px;
  color: #fff;
  margin-left: 0;
}

.fsb-popup .ms-ContextualMenu-list {
  padding: 10px 0;
  color: #fff;

  .ms-ContextualMenu-link {
    background-color: #000 !important;
    color: #fff;
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
  }

  .ms-ContextualMenu-itemText {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    padding: 0 24px;
  }
}

.fsb-wrap {
  padding: 10px 20px;
  max-height: 356px;

  .fsb-title {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    margin-bottom: 10px;
    color: #fff;
  }
}

.fsb-title {
  .ms-Checkbox-label {
    font-weight: bold;
  }

  .ms-Stack {
    position: relative;
    top: -2px;
    margin-left: 11px;
  }
}

.fsb-wrap {
  .chbi-chb {
    .ms-Checkbox-checkbox::after {
      background-color: #000;
      width: 8px;
      height: 8px;
      top: 2px;
      left: 2px;
      display: block;
      content: "";
    }

    &.is-checked .ms-Checkbox-checkbox::after {
      background-color: #fff;
    }
  }

  .chbi-icon .ms-Image-image {
    max-width: 32px;
    max-height: 20px;
    height: auto;
    width: auto;
  }
}

.fsb-sub-wrap {
  .ms-Checkbox-label .ms-Checkbox-checkbox {
    width: 16px;
    height: 16px;
    border-width: 2px;
  }

  .fsb-check {
    margin: 10px 0;
  }
}

.fsb-check .chbi-icon-wrap {
  position: relative;
  top: 2px;
}

.sub-gp-item .ms-Checkbox-label .ms-Checkbox-checkbox, .fsb-title .ms-Checkbox-label .ms-Checkbox-checkbox {
  border-width: 2px;
}

.fsb-sub-wrap {
  .sub-gp-item .chbi-chb.is-checked .ms-Checkbox-checkbox::after, .fsb-title .chbi-chb.is-checked .ms-Checkbox-checkbox::after {
    width: 8px;
    height: 8px;
  }
}
