@use 'sass/abstracts/variables';

.ly-item-header {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5;
  padding-bottom: 10px;

  .ly-chbx {
    margin-left: 5px;
  }
}

.historic-fires-layer-wrap {
  .dp-layer.dp-dark {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  &.layer-wrap {
    &:hover .dp-header, &.is-expanded .dp-header {
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
    }
  }

  .dp-layer.dp-dark {
    min-width: 178px;
    border: 0;
    box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  }

  .dp-header {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 16px;

    background-color: variables.$wds-orange;
    border: 0;
  }

  .ly-chbx {
    .ms-Checkbox-checkbox {
      background-color: #000 !important;
      border-color: #fff;
      border-radius: 0;
    }

    .ms-Checkbox .ms-Checkbox-text {
      font-family: variables.$main-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.ly-chbx {
  .ms-Checkbox:hover .ms-Checkbox-text, .ms-Checkbox-text {
    color: #fff;
    margin-left: 0;
  }

  .chbi-chb {
    .ms-Checkbox-checkbox::after {
      background-color: #000;
      width: 8px;
      height: 8px;
      top: 2px;
      left: 2px;
      display: block;
      content: "";
    }

    &.is-checked .ms-Checkbox-checkbox::after {
      background-color: #fff;
    }
  }
}

.hf-ly-item:not(:last-child) {
  margin-bottom: 11px;
}

.hf-ly-timeframe {
  margin: 0 -20px;
  padding: 0 20px;
  min-width: 216px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  width: fit-content;
  position: relative;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: variables.$grey-300;
  }

  .ms-ChoiceField {
    margin-top: 0;
  }

  .ms-ChoiceFieldLabel {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;
    color: #fff !important;
  }

  .ms-ChoiceField-field {
    &::before {
      height: 18px;
      width: 18px;
      margin-top: 6px;
      border-color: #fff;
    }

    &:hover::after, &::after {
      background-color: #000;
      border-color: #000;
      border-width: 7px;
      top: 8px;
      left: 2px;
      height: 14px;
      width: 14px;
    }

    &.is-checked::after {
      border-color: #000;
      top: 8px;
      left: 2px;
      border-width: 3px;
      background-color: #fff;
      width: 14px;
      height: 14px;
      border-style: solid;
    }
  }
}

.hf-tf-img {
  width: 20px;
  align-self: flex-start;
  position: relative;
  top: 1px;
}

.historic-fires-layer-wrap .hf-ly-item .ms-Stack {
  padding-bottom: 7px;
}
