@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.header {
  color: white;

  .wdstitle {
    display: grid;
    align-items: center;
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }
}

.bannertoprow {
  height: 40px;
  background-color: #000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.wdslogo img {
  cursor: pointer;
  width: 32px;
  margin: 2px 15px 2px 7px;
}

.header .user-action {
  color: #fff;
  min-width: 30px;

  .ms-Icon {
    display: none !important;
  }
}

.person-logo {
  position: relative;
  margin-right: 14px;

  .person-icon {
    position: absolute;
    margin-top: 6.5px;
    right: 0;
  }

  .ms-Button .ms-Button-label {
    font-size: 16px;
  }
}

.person-icon .ms-Image {
  height: 27px;
  width: 27px;
  border-radius: 27px;
  background-color: #000;

  .ms-Image-image {
    height: 100%;
    width: 100%;
  }
}

.person-logo .ms-Button-label {
  padding-right: 27px;
}

.header .nav-wrap .nav-item .ms-Button-label {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.mb-menu {
  position: relative;
  display: block;
  @include mixins.desktop {
    display: none;
  }
  
  .ms-Icon-imageContainer {
    position: absolute;
    top: 10px;
  }

  .ms-Button {
    min-width: 20px;
    border: 0;
    background: transparent;

    .ms-Icon {
      display: none !important;
    }
  }
}

.nav-wrap, .person-logo {
  display: none;
  @include mixins.desktop {
    display: block;
  }
}