@use 'sass/abstracts/variables';

body {
  font-family: variables.$main-font-family;
  font-style: normal;
}

.frmap-area {
  position: relative;
  min-height: 100px;
}

.main-container {

  .page-title {
    a {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: variables.$black !important;
      text-decoration: none;
      height: 68px;
      display: flex;
      padding-top: 36px;
      width: fit-content;
    }

    .ms-Icon-imageContainer {
      margin-right: 10px;
    }
  }

  .page-main {
    margin: 0 30px 30px;
    position: relative;
  }
}

.dropdown-menu {
  max-height: 300px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  .map-gallery {
    min-width: 300px;
  }
}

.dropdown-item .btn {
  padding: 0px !important;
}

.collapse ul {
  list-style: none !important;
  padding-inline-start: 0px !important;
  padding-left: 0px !important;
}

.map-gallery {
  .card-body {
    padding: 5px !important;
    text-align: center;
  }

  .mapstyle {
    height: 75px;
  }

  .checked {
    position: absolute;
    left: 0px;
    top: 0px;
    text-align: center;
    width: 130px;
    height: 75px;
    font-size: 3rem !important;
    color: variables.$blue-dark;
  }
}

.fire-table {
  .table-name {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  div[col-id="pid"] {
    width: 0px !important;
  }

  .ag-theme-quartz .ag-row {
    height: 35px;
  }

  .frsl-wrap {
    z-index: 9999;
    min-height: 0px;
  }
}

.with-fire-selector {
  justify-content: space-between;
}

@media (max-width: 576px) {
  .fire-table {
    .table-search-box {
      margin-top: 1px !important;
      margin-bottom: 4px !important;
    }

    .ms-SearchBox {
      max-width: none !important;
    }
  }
}

.fire-table {
  .table-search-box {
    margin-top: -12px;
  }

  .ms-SearchBox {
    margin: 0px 0px 0px auto;
    // max-width: 275px;
    flex-flow: row-reverse nowrap;
    background: variables.$white;
    border: 1px solid variables.$grey-300;
    box-sizing: border-box;
    height: 36px;
  }

  .ms-SearchBox-field {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    padding: 10px 0px 9px 12px;

    &::placeholder {
      font-family: variables.$main-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: variables.$black;
   
    }
  }

  .ms-SearchBox-icon {
    color: variables.$grey-300;
    transform: rotate(270deg);
  }

  .ms-Button-label {
    font-size: 16px !important;
    line-height: 19.2px !important;
    font-weight: 700 !important;
  }

  .pre-plan {
    position: absolute;
    left: 0px;
    bottom: 10px;
    width: 100%;
    display: inline-block;

    > b {
      padding-right: 21px;
    }
  }

  .ag-paging-panel {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 15.83px;
    line-height: 19px;
    color: variables.$black;
 
  }

  .ag-paging-button .ag-icon::before {
    font-size: 17px;
    font-weight: bold;
    line-height: 30px;
    color: variables.$black;
  }
}

.kpititle .local-response {
  max-height: 157px;
  overflow-y: scroll;
}

.kpiselect-btn {
  width: 100%;
  background: variables.$wds-orange;
  border: 3px solid variables.$wds-orange;
  box-sizing: border-box;
  height: 55px;
  padding: 0px 12px;

  .select-btn {
    height: 34px;
    background-color: variables.$white;
    font-weight: bold;
    border-radius: 5px;
    font-size: 16px;
    line-height: 30px;
    color: variables.$black;
    margin-top: 11px;
    margin-right: 5px;
    padding: 0px 6px;

    > i::before {
      font-weight: bold !important;
      font-size: 16px;
      color: variables.$black;
      padding-right: 2px;
    }
  }
}

.sitm-btn-upload {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  height: 23px;
  width: 93px;
  margin-top: 6px;
}

.sitm-btn-smokecheck {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  height: 23px;
  width: 100px;
  margin-top: 6px;
  margin-right: 22px;
}

#firehist_activedatatable .ag-cell {
  line-height: normal;
  word-break: normal;
  padding: 10px;
}

.fire-history-table #firehist_activedatatable .ag-row {
  color: variables.$black !important;
}
