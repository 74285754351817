@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';
@use 'sass/base/typography';

.action-sidebar-wrapper {
  background: variables.$black;
  height: variables.$sidebar-height-mobile;
  width: 100%;
  padding: 0 8px;
  position: absolute;
  bottom: 0;
  z-index: 1;

  .action-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  @include mixins.desktop {
    left: 0;
    height: 100%;
    width: variables.$sidebar-width-desktop;
    padding: 10px 0 0 0;

    .action-sidebar {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.action-sidebar-icon {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  span:first-child {
    height: 30px;
    width: 30px;
  }

  span:nth-child(2) {
    line-height: 1em;
    margin-top: 3px;
  }

  img {
    width: 45px;
    height: 45px;
  }

  .asp-icon-label {
    @extend %label;
  }

  &.asp-active-icon {
    .asp-icon-label {
      color: variables.$wds-orange;
    }

    svg, svg #Path_6 {
      fill: variables.$wds-orange;
    }

  }

  @include mixins.desktop {
    margin: 15px 0;
    text-align: center;
  }
}