﻿@use 'sass/abstracts/variables';

.regionMap-container {
  border: 3px solid variables.$wds-orange;
  border-radius: 5px;
  display: flex;
}

/*Map chart*/

.regionMap-chart {
  flex: 1;
  background-color: variables.$grey-100;
  position: relative;
  border-right: 3px solid variables.$wds-orange;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  min-height: 302px;
}

.wm-region-chart-title {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  color: variables.$black;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
}

.wm-region-chart {
  width: 98%;
  padding-bottom: 20px;
  position: relative;

  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;

    &.base-chart {
      pointer-events: all;
    }
  }

  .chart-tooltip {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    padding: 2px 12px;
    color: variables.$black;
    font-size: 14px;
    transform: translate(-50%, -50%);
    display: none;
    pointer-events: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    white-space: nowrap;

    .chartjs-tooltip-marker {
      display: inline-block;
      width: 1em;
      height: 1em;
      background-color: variables.$grey-100;
      margin-right: 5px;
      vertical-align: middle;
      margin-top: -1px;
    }
  }
}

.wm-my-states {
  border: 2px solid variables.$black;
  position: absolute;
  bottom: 9px;
  right: 13px;
  color: variables.$black;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  padding: 2px 10px;
  font-family: variables.$main-font-family;
  box-sizing: border-box;
}

/*Region Details*/

.region-details-container {
  width: 180px;
  display: flex;
  flex-direction: column;
  height: auto;
}

.region-details-item {
  display: flex;
  flex: 1;
}

.region-details-item-border {
  border-top: 2px solid variables.$black;
}

.region-details-bg {
  width: 18px;
  min-height: 29px;
}

.region-details-regions {
  flex: 1;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.region-details-region-name {
  font-size: 12px;
  font-weight: 700;
  height: 14px;
  line-height: 14px;
}

.region-details-states {
  font-weight: 400;
  font-size: 12px;
  height: 14px;
  line-height: 14px;
}
