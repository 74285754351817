@use 'sass/abstracts/variables';

.share-dialog-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.share-dialog-row {
  display: flex;
  align-items: flex-end;

  .ms-TextField {
    margin-right: 5px;
    flex: auto;
  }
}

.pu-share-dialog {
  padding: 60px 80px;
}

.pu-btn-cancel {
  background: variables.$grey-300;
  border-radius: 5px;
  width: 110px;
  height: 36px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: variables.$black;
  border-style: none;
}
