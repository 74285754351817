@use 'sass/abstracts/variables';

.sltr-main {
  margin-left: 500px;
  background-color: variables.$wds-orange;
  color: #000;
  border: 0;
  border-radius: 0;

  .ms-Button-label {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;
  }

  .ms-Button-flexContainer .ms-Icon {
    display: none;
  }
}

.sltr-menu {
  background-color: transparent;
}

.sltr-wrap {
  padding: 17px;
  color: #fff;
}

.sltr-main {
  &.is-expanded, &:hover, &.is-expanded .ms-Button-textContainer, &:hover .ms-Button-textContainer {
    background-color: #000;
    color: #fff;
  }
}

.sltr-item {
  display: flex;
  font-family: variables.$main-font-family;
  font-weight: bold;
  font-size: variables.$dropdown-font-size;
  line-height: variables.$dropdown-line-height;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  .ms-Image {
    min-width: 23px;
    text-align: center;
  }

  img {
    display: inline;
  }
}

.sltr-rect {
  display: inline-block;
  width: 44px;
  height: 18px;
  border: 1px solid #fff;
}

.sltr-square {
  display: inline-block;
  height: 18px;
  width: 18px;
}

.sltr-text {
  margin-left: 10px;
}

.map-control {
  height: 35px !important;
  padding: 0 20px !important;
  line-height: 35px !important;
}
