@use 'sass/abstracts/variables';

.photo-wrap.container .row:not(:last-child) {
  margin-bottom: 20px;
}

.photo-item {
  width: 23%;
  margin: 0 1%;
  background: variables.$white;
  padding: 5px;
  border: 1px solid variables.$grey-400;
}

.img-wrapper {
  width: 100%;
  position: relative;
  border: 1px solid variables.$grey-100;
  background: variables.$grey-100;

  .ms-Image {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid variables.$grey-400;
  }

  &::after {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }
}

.chb-publish {
  align-items: center;

  &:not(.is-disabled) {
    .ms-Checkbox-checkbox {
      border-color: variables.$black;
    }

    .ms-Checkbox-text {
      color: variables.$black;
    }
  }
}

#pha-file-upload {
  display: none;
}

.pha-custom-file-upload {
  background: variables.$wds-orange;
  padding: 2px 10px;
  cursor: pointer;
  border-radius: 2px;

    
  &:hover {
      background-color: variables.$wds-hover-orange;
      border: none;
      color: variables.$black;
  }
}

.photos-title {
  padding-top: 2px;
}
