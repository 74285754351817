﻿@use 'sass/abstracts/variables';

.dailyAssignments-container {
  margin-top: 10px;
  padding: 10px;

  .icon-title img {
    margin-right: 8px;
    margin-top: -5px;
  }
}

.new-tab-icon {
  margin-top: -9px;
  float: right;
  padding-bottom: 27px;
  border-bottom: 2px solid variables.$wds-orange;
}

.full-board-icon {
  cursor: pointer;
}

/* Tabs */

.dailyAssignments-tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-bottom: 2px solid variables.$wds-orange;
    z-index: -999;
  }

  .item {
    height: 36px;
    line-height: 36px;
    background-color: #000;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
  }

  .selected {
    background-color: variables.$wds-orange;
    color: #000;
  }
}

.dailyAssignments-controls {
  margin-top: 10px;
  position: relative;
}

/* Daily Assignment ag-grid */

.cell-style {
  //margin-left: 18px !important;
}

.wf-row-border {
  border-bottom: 2px solid variables.$grey-300 !important;
}

.dailyAssignments-controls .pl-ag.ag-theme-quartz .ag-cell-value {
  font-size: 14px !important;
  color: #000 !important;

  font-family: variables.$main-font-family !important;
}

.fire-board.ag-theme-quartz .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent !important;
  border-right: 1px solid variables.$grey-300 !important;
}

.daily-operation.ag-theme-quartz .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .noteworthy-fires.ag-theme-quartz .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent !important;
}

.dailyAssignments-controls {
  .ag-theme-quartz {
    .ag-row {
      border-bottom: none;
    }

    .ag-header {
      border-bottom: 2px solid #000 !important;
    }
  }

  .pl-row-alter {
    background-color: white !important;
  }

  .ag-theme-quartz .ag-header-cell-label .ag-header-cell-text {
    font-size: 14px !important;
    font-weight: bold !important;
    color: #000 !important;
  }
}

.cell-butons-container .ag-react-container {
  height: 100%;
}

.cell-butons {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  img {
    cursor: pointer;
    margin-left: 10px;
  }
}

.dailyAssignments-controls .ag-theme-quartz .ag-cell-data-changed {
  background-color: variables.$red !important;
}

.fire-board.ag-theme-quartz {
  .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    line-height: 53px;
  }
}

.dailyAssignments-controls .ag-theme-quartz .ag-cell-inline-editing {
  border: none;
  box-shadow: none;
  background-color: white;
  height: 40px;
}

/* ag-gird  dropdownlist */

.pl-dropdown .daily-operation-selection-dropdown .ms-Dropdown {
  font-size: 14px;
  font-weight: 400;
  margin-top: 1px;
}

.do-dropdown-callout .ms-Callout-main .ms-Dropdown-item {
  font-weight: 400;
  font-size: 14px !important;

  font-family: variables.$main-font-family !important;

  .ms-Dropdown-optionText {
    font-weight: 400;
    font-size: 14px !important;

    font-family: variables.$main-font-family !important;
 
  }
}

select > option {
  font-weight: 400;
  font-size: 14px !important;

  font-family: variables.$main-font-family !important;
}

.daily-operation-selection-dropdown {
  .ms-Dropdown-container .ms-Dropdown-title {
    font-family: variables.$main-font-family !important;
 
  }

  min-width: 95px;
  max-width: 95px;
}

/* Daily Operation */

.daily-operation-buttons {
  right: 0px;
  display: flex;
  justify-content: space-between;
}

.iai-header {
  position: relative;
  font-size: 14px;

  &::after {
    content: "*";
    position: absolute;
    top: 2px;
    left: 21px;
    color: variables.$red;
  }
}

.do-dropdown-callout .ms-Callout-main .is-disabled.ms-Dropdown-item {
  color: variables.$grey-300;

  .ms-Dropdown-optionText {
    color: variables.$grey-300;
  }
}

select > option {
  color: variables.$grey-300;
}

/* Fire Board*/

.fire-board-container {
  min-height: 200px;
  position: relative;

  .select-all {
    position: absolute;
    right: 0px;
    top: -38px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .regions {
    display: flex;
    flex-wrap: wrap;
  }
}

.ckb-region {
  margin-right: 20px;
  padding: 6px 10px 6px 10px;
  height: 30px;
  margin-bottom: 10px;

  .ms-Checkbox-checkmark {
    display: none !important;
  }

  .ms-Checkbox-checkbox {
    border: none;
    height: 18px;
    width: 18px;
    background-color: white !important;
    border-radius: 0;
  }

  &.is-checked .ms-Checkbox-checkbox::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    top: 3px;
    left: 3px;
    box-sizing: border-box;
    border-style: solid;
    background-color: variables.$black !important;
    border: none;
  }

  .ms-Checkbox-label {
    font-family: variables.$main-font-family !important;
    font-weight: bold;

    .ms-Checkbox-text {
      line-height: 18px;
    }
  }
}

/*ag grid*/

.region-sections .grid-wrapper {
  margin-bottom: 10px;
}

.region-bar {
  height: 30px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
}

.fire-board {
  margin-top: 18px;
  width: 100%;
}

.dailyAssignments-controls .fire-board.ag-theme-quartz .ag-header {
  border-bottom: 0px !important;
}

.fire-board {
  &.ag-theme-quartz {
    .ag-header-cell, .ag-header-group-cell {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  &.pl-ag.ag-theme-quartz .ag-header {
    height: 29px !important;
    min-height: 29px !important;
  }
}

.header-cell {
  background-color: variables.$grey-300;
}

.fire-board {
  &.ag-theme-quartz {
    .ag-header-container {
      border-bottom: 2px solid variables.$black;
    }

    .iaI-cell-style, .ag-pinned-left-header {
      border-right: 2px solid variables.$black !important;
    }
  }

  &.pl-ag.ag-theme-quartz .ag-root-wrapper-body.ag-layout-normal {
    min-height: 10px;
  }
}

.dailyAssignments-controls .pl-ag.ag-theme-quartz .fire-board-cell-style.ag-cell-value {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center;
}

.fire-board {
  .cell-style.header-cell {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  &.ag-theme-quartz {
    .pl-row-normal {
      border-bottom: 1px solid variables.$grey-300;
    }

    .ag-header-row {
      height: 28px !important;
      border-top: 1px solid variables.$grey-300;
      border-bottom: 1px solid variables.$grey-300;
    }

    .ag-header-cell-label {
      justify-content: center;
    }

    .ag-header-cell, .ag-cell {
      border-right: 1px solid variables.$grey-300;
    }
  }
}

.dailyAssignments-controls .fire-board.ag-theme-quartz .ag-header-cell-label .ag-header-cell-text {
  font-size: 12px !important;
  font-weight: bold !important;
}

.fire-board-fire-info-container {
  text-align: left;
  line-height: 18px;

  .fire-name {
    display: flex;
    justify-content: flex-start;

    .ellipsis-text a {
      color: variables.$black;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .indicator {
    width: 15px;
    margin-left: 10px;
  }

  .fire-info {
    font-size: 12px;
    margin-top: 6px;
  }
}

.fire-board-selection {
  margin-top: 3px;

  .backup-dropdown {
    margin-top: 3px;
  }

  .pl-dropdown {
    .ms-Dropdown-title {
      height: 20px;
      line-height: 19px;
      font-size: 10px;
    }

    .ms-Dropdown-caretDownWrapper {
      height: 20px;
      line-height: 20px;
    }
  }
}

.dca-text {
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
}

.fb-dropdown-callout .ms-Callout-main .ms-Dropdown-item {
  font-size: 10px;
  font-weight: bold;

  .ms-Dropdown-optionText {
    font-size: 10px;
    font-weight: bold;
  }
}

select > option {
  font-size: 10px;
  font-weight: bold;
}

.fb-dropdown-callout .ms-Callout-main .is-disabled.ms-Dropdown-item {
  color: variables.$grey-300;

  .ms-Dropdown-optionText {
    color: variables.$grey-300;
  }
}

select > option {
  color: variables.$grey-300;
}

.noteworthy-section {
  line-height: 29px;
  font-size: 14px;
  background-color: variables.$grey-300;
  position: relative;
  font-weight: bold;
  height: 29px;
}

.noteworthy-text {
  text-align: center;
}

.noteworthy-expand {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 10px;
}

.fire-board-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .ag-overlay-loading-center {
    font-size: 13px;
    color: variables.$grey-600;
    border: solid 1px;
    border-color: variables.$grey-300;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    padding: 6px;
  }
}

.region-font-color-white {
  color: white;

  .ms-Checkbox-label .ms-Checkbox-text {
    color: white;
  }
}

.region-font-color-black {
  color: variables.$black;

  .ms-Checkbox-label .ms-Checkbox-text {
    color: variables.$black;
  }
}

/* Noteworthy fire */

.noteworthy-fires.ag-theme-quartz .ag-row-selected, .dailyAssignments-controls .noteworthy-fires.ag-theme-quartz .pl-row-alter.ag-row-selected {
  background-color: var(--ag-selected-row-background-color, rgba(33, 150, 243, 0.3)) !important;
}

.noteworthy-comments-cellEditor-container .ms-TextField-fieldGroup {
  height: 36.8px;
  margin-top: 1px;
  border: 1px solid variables.$grey-300;
}

.ellipsis-text {
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noteworthy-fires {
  min-height: 200px;
  height: 200px;

  .fire-name a {
    color: variables.$black;
  }

  .cell-butons-container .cell-butons {
    margin-right: 10px;
  }
}

/* overlay */

// .daily-operation.pl-ag.ag-theme-quartz .ag-overlay, .noteworthy-fires.pl-ag.ag-theme-quartz .ag-overlay {
//   position: absolute;
// }

.daily-operation.ag-theme-quartz .ag-overlay-no-rows-wrapper.ag-layout-auto-height, .noteworthy-fires.ag-theme-quartz .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 0px;
  padding-bottom: 30px;
}

.daily-operation-delete-dlg {
  max-width: 400px;
  min-width: 384px;
  height: 166px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  div[class^='topButton-'] {
    display: none !important;
  }

  .ms-Dialog-title {
    font-family: variables.$main-font-family !important;
    padding: 35px 16px 27px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: variables.$black;
 
  }

  .ms-Dialog-header {
    margin: 0px;
    display: block;
  }

  .ms-Modal-scrollableContent div {
    overflow: hidden;
  }

  .ms-Dialog-actionsRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ms-Dialog-action {
    margin: 0px 9px;

    button {
      width: 110px;
      height: 36px;
      border-radius: 5px;
      border-width: 0px;
    }
  }

  .ms-Button-label {
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }

  .ms-Dialog-actions {
    margin: 0px !important;
  }

  .ms-Dialog-action {
    &:nth-child(1) {
      button {
        background: variables.$grey-300;
      }

      .ms-Button-label {
        color: variables.$black;
     
      }
    }

    &:nth-child(2) button {
      background: variables.$wds-orange;
   
    }
  }
}
