@use 'sass/abstracts/variables';

.import-file {
  display: inline-block;

  .title {
    background-color: variables.$wds-orange;
    height: 36px;
    line-height: 36px;

    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    padding: 0 28px;
    cursor: pointer;

    &:hover {
      background-color: variables.$wds-hover-orange;
      border: none;
      color: variables.$black;
    }
  }

  .input {
    width: 0;
  }
}
