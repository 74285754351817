@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

/* FireSelector Styles */
.fire-selector {
  display: flex;
  align-items: center;
}

.frsl-wrap {
  position: absolute;
  right: 0px;
  top: 36px;
  max-height: 380px;
  overflow-y: auto;
  white-space: nowrap;
  background-color: variables.$dropdown-background;
  min-height: 28px;
  min-width: 270px;
  box-shadow: rgb(0 0 0 / 13%) 0px 6.4px 14.4px 0px, rgb(0 0 0 / 11%) 0px 1.2px 3.6px 0px;

  .frsl-item {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      background-color: variables.$dropdown-hover-background;
   
    }

    span, a {
      text-decoration: none !important;
      color: variables.$white !important;
      font-weight: bold;

      font-size: variables.$dropdown-font-size;
      line-height: variables.$dropdown-line-height;
      margin: 0px 4px;
      padding: 8px;
      display: block;
    }
  }

  .ms-Spinner {
    margin-top: 4px;
  }
}

.frsl-srch {
  &.ms-SearchBox {
    border-radius: 0;
    border: 1px solid variables.$grey-300;
    height: 35px;
    padding-left: 40px;
    flex: 4;
    display: flex;
  }

  input.ms-SearchBox-field::placeholder {
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    color: #000;
  }

  .ms-SearchBox-icon {
    transform: rotateY(180deg);
    color: variables.$grey-300;
    font-size: 20px;
  }
}

.map-actions .ms-SearchBox {
  flex-flow: row-reverse nowrap;
}

.search-logo {
  width: 25px;
  position: absolute;
  left: 3px;
  z-index: 2;
}

/* Sitmap */
.sitm-top-map .act-srch {
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  margin: 0;
  width: 80%;
  transition: right .3s ease-in-out;

  .ms-SearchBox-iconContainer {
    order: 2;
  }

  @include mixins.desktop {
    right: 20px;
    left: auto;
    transform: none;
    width: 400px;

    &.panel-show {
      right: calc(20px + 500px);
    }
  }
}
