﻿@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.resource-status-main-container {
  font-family: variables.$main-font-family;
  padding: 28px 30px 30px 30px;

  .header-text {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }

  .ms-Dropdown-callout .ms-Callout-main {
    max-height: 14rem !important;
  }
}

/* map */

.resourcestatus-map {
  position: relative;

  .map-wrapper {
    height: 672px;
  }
}

.resourcestatus-layer-wrap {
  .chbi-icon .ms-Image-image {
    width: 14px;
    height: 14px;
    margin-top: 3px;
  }

  .rcc-ncc-icon .chbi-icon .ms-Image-image {
    width: auto;
    height: auto;
    margin-top: 0px;
  }
}

/* end map */

/* engine info slide bar */

.rs-engineInfoSlideBar {
  border: 2px solid variables.$grey-200;
  box-sizing: border-box;
  width: 280px;
  background-color: variables.$grey-100;
}



