@use 'sass/abstracts/variables';

/* Layout Styles */
#root {
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
}
.main-container {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    flex-wrap: wrap;
  }

  .page-main {
    margin: 0 30px 0px;
    position: relative;
  }

  .page-title {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: variables.$black;
    height: 68px;
    display: flex;
    align-items: center;
  }

  .page-title-button-container {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    flex-direction: row;
  }
}

/* Active Table Styles */

.active-table {
  .table-title {
    font-weight: bold !important;
    font-size: 25px !important;
    line-height: 30px !important;
    padding-bottom: 12px;
  }

  font-family: variables.$main-font-family !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: variables.$black !important;

  .frsl-wrap {
    /* left: 0px !important; */
    z-index: 9999;
    min-height: 0px;
  }

  .table-title {
    font-weight: bold !important;
    font-size: 25px !important;
    line-height: 30px !important;
    padding-bottom: 12px;
  }
}
