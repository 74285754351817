@use 'sass/abstracts/variables';

.frmap-actions {
  position: absolute;
  top: 0;
  right: 11px;
  z-index: 1;
  display: flex;
  justify-content: space-between;

  .fire-selector {
    min-width: 212px;
  }

  .ms-SearchBox {
    flex-flow: row-reverse nowrap;
  }

  .ms-Button-textContainer {
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
  }

  > * {
    margin-top: 12px;
  }

  .fsb-layer {
    margin-top: 12px;
    height: 32px;
    border-radius: 2px;
  }

  .layer-wrap {
    width: 196px;
  }

  .sltr-item {
    min-height: 25px;
  }
}
