@use 'sass/abstracts/variables';
html,
body {
  width: 100%;
  height: 100%;
  /*overflow: auto;*/
  font-family: variables.$main-font-family;
  font-style: normal;
}
body {
  margin: 0;
  background: variables.$white;
  font-family: variables.$main-font-family;
}