@use 'sass/abstracts/variables';

.chbi-chb {
  .ms-Checkbox-checkbox {
    border-width: 2px;
    background-color: #000 !important;
    border-color: #fff;
    height: 16px;
    width: 16px;
    border-radius: 0;
  }

  &.is-checked .ms-Checkbox-checkbox::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 2px;
    left: 2px;
    box-sizing: border-box;
    border-style: solid;

    background-color: variables.$wds-orange;
    border: none;
  }
}

.chbi-prefix {
  margin-right: 5px;

  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 25px;
}

.chbi-chb {
  .ms-Checkbox-label {
    align-items: center;
    top: 3px;

    .ms-Checkbox-text {
      min-width: fit-content;

      font-family: variables.$main-font-family;
      font-style: normal;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .ms-Checkbox-checkmark {
    display: none !important;
  }
}

.chbi-icon-wrap {
  display: flex;
  align-items: center;
}

.chbi-icon .ms-Image-image {
  max-height: 20px;
  max-width: 20px;
  margin-left: 5px;
}

.chbi-line-wrap {
  display: flex;
  align-items: center;

  .chbi-line {
    display: block;
    width: 24px;
    height: 0.1px;
    border-radius: 24px;
    border-style: solid;
  }
}

.chbi-circle {
  margin-left: 5px !important;
  margin-top: 6px;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
