@use 'sass/abstracts/variables';

.main-font {
  font-family: variables.$main-font-family !important;
}

.user-management {
  margin: 26px 40px 0px 40px;
  font-family: variables.$main-font-family !important;
  height: 100%;

  h1 {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5em;
  }

  h2 {
    margin: 0px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5em;
  }

  .left {
    min-width: 170px;
    padding: 0 20px;
  }

  .right {
    padding: 0 20px;
  }

  hr {
    margin: 6px 0;
    height: 2px;
    color: variables.$black;
    opacity: 1;
  }
}

.ms-Button-textContainer .ms-Button-label {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.editor-input {
  &.ms-TextField .ms-TextField-fieldGroup, &.ms-Dropdown-container .ms-Dropdown-title {
    border: 1px solid variables.$grey-300;
    border-radius: 0;
    height: 36px;
  }

  &.email-input {
    min-width: 295px;
  }
}

.editor-title {
  margin-bottom: 54px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.editor-footer {
  margin-top: 88px;
  display: flex;
  gap: 13px;
}

.editor-item {
  display: flex;
  gap: 30px;
  margin-top: 14px;
}

.editor-wrap .editor-item:not(:first-child) {
  margin-top: 20px;
}

.editor-status {
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  padding: 0;
}

.confirm-dialog .ms-Dialog-main {
  width: 526px;
  min-width: 526px;
  max-width: 526px;
}

.error-row {
  color: variables.$red;
  margin: 5px 0;
}
