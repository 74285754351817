@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.containerbottom {
  display: block;
  width: 100%;
  height: 31px;
  background: variables.$grey-200;
  clear: both;
}

.wdsfooter {
  display: block;
  width: 100%;
  background: black;
  clear: both;
  padding: 2px 0;
  position: fixed;
  bottom: 0;
  font-size: 10px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @include mixins.desktop {
    font-size: 14px;
  }
}

.copyright {
  color: white;
  font-family: variables.$main-font-family;
  display: inline;
}

.terms-conditions {
  color: variables.$wds-orange;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  margin-left: 5px;
}

.disclaimer {
  max-width: 1000px;
}

.disclaimer-content {
  padding: 0 24px 24px 24px;
  border-top: 8px solid variables.$wds-orange;
}

.disclaimer-heading {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 14px 0;
}

.disclaimer-close {
  color: variables.$wds-orange;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
}
