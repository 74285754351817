﻿@use "sass/abstracts/variables";
@use "sass/abstracts/mixins";

body {
  font-style: normal;
  font-weight: normal;
  color: variables.$black !important;
}

.plp-page {
  .ag-header {
    background-color: variables.$grey-200;
  }

  .ag-cell-value {
    font-size: 16px !important;
  }
}

.fire-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  padding: 13px 0px;
}

.ag-header-cell-label {
  .ag-header-cell-text,
  .ag-header-cell-icon {
    font-size: 16px !important;
  }
}

.table-previous {
  border: 2px solid variables.$black !important;

  .ag-row,
  .ag-cell {
    border-width: 0px;
  }

  .ag-row > :first-child {
    border-right: 2px solid variables.$black;
    font-weight: bold;
  }
}

.plp-title-tooltip-icon {
  margin-left: 10px;
}

.plp-title-tooltip-icon-2 {
  margin-left: 45px;
}

.plp-title-tooltip {
  font-size: 16px !important;
  line-height: 19px !important;
  color: variables.$black !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  text-align: left !important;

  .multi-line {
    text-align: left !important;
  }

  &::before,
  &::after {
    display: none;
  }
}

.title-dispatched {
  border-bottom: 4px solid variables.$red;
}

.title-nondispatched {
  border-bottom: 4px solid variables.$wds-orange;
}

.title-demobilized {
  border-bottom: 4px solid variables.$green-400;
}

.title-noteworthy {
  border-bottom: 4px solid variables.$wds-orange;
}

.title-monitored {
  border-bottom: 4px solid variables.$grey-400;
}

.title-engine {
  border-bottom: 4px solid variables.$black;
}

.title-previous {
  border-bottom: 4px solid variables.$black;
  margin-bottom: 29px;
}

.fire-title {
  .imgicon {
    position: absolute;
    height: 36px;
    padding-left: 12px;
  }

  img {
    filter: drop-shadow(0 0 1px variables.$grey-300);
    -webkit-filter: drop-shadow(0 0 1px variables.$grey-300);
  }

  .ms-SearchBox {
    flex-flow: row-reverse nowrap;
    background: variables.$white;
    border: 1px solid variables.$grey-300;
    box-sizing: border-box;
  }

  .ms-SearchBox-field {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;

    &::placeholder {
      font-family: variables.$main-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: variables.$black;
    }
  }

  .ms-SearchBox-icon {
    color: variables.$grey-300;
    transform: rotate(270deg);
  }
}

.main-container {
  position: relative;

  .page-title-button-container {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    flex-direction: row;

    > div:last-child {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: 20px;
    }
  }
}

.wtf-validation-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: variables.$red;
}

.wtf-copywrite {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-top: 33px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    margin-bottom: 0px;
  }
}

.wtf-button {
  background-color: variables.$wds-orange;
  width: 165px;
  height: 30px;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#my-properties-button {
  margin-right: 10px;
}

.buffer-distance-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  color: variables.$black;
  font-family: variables.$main-font-family;
  margin-top: 20px;
  margin-right: 61px;
  width: 100%;
}

.no-result-close div {
  text-align: center !important;
}

.plp {
  .ms-Dialog-header {
    padding: 0px;
    margin-top: 18px;
    margin-left: 14px;
    display: none;
  }

  .ms-Dialog-inner {
    padding: 0px;
  }
}

.wtf-searchresult-icon-monitored {
  max-width: 24px;
  max-height: 38px;
  margin-left: 10px;
}

.wtf-searchresult-icon {
  max-width: 28px;
  max-height: 36px;
  margin-left: 15.15px;
  margin-top: -5px;
}

.wtf-result-form-header {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
  font-family: variables.$main-font-family !important;
  display: flex;
}

.wtf-form-header {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  font-family: variables.$main-font-family !important;
}

.wtf-form .ms-Dropdown {
  display: flex;
  width: 100%;
  min-width: 70px;
  border: 1px solid variables.$grey-300;

  .ms-Dropdown-title {
    height: 36px;
    border: none;
  }

  &:focus::after {
    border: none;
  }
}

.wtf-connected-radios-wrapper {
  margin-top: 27px;
}

.wtf-connected-radios {
  display: flex;
  margin: 0 auto;
  padding-left: 0px;
  width: 100%;
  max-width: 351px;
}

.wtf-connected-radio-label-container {
  display: flex;
  margin: 0 auto;
  padding-left: 0px;
  width: 100%;
  max-width: 351px;
  list-style-type: none;

  li {
    &:first-child {
      margin-left: -5px;
      margin-right: 47px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      margin-right: 47px;
    }

    &:nth-child(4) {
      margin-right: 42px;
    }

    label {
      max-width: 35px;
      width: 100%;
    }
  }
}

.wtf-connected-radios {
  li {
    label {
      width: 100%;
      max-width: 35px;
      display: block;
      display: none;
    }

    input {
      background: variables.$white;
      border: 2px solid variables.$black;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
    }
  }

  .form-check-input:checked {
    &[type="radio"] {
      background-repeat: no-repeat;
      background-position: center;
    }

    background-color: variables.$white;
    border: 2px solid variables.$black;
  }

  li {
    list-style: none;
    position: relative;
    z-index: 300;
    display: flex;
    margin-right: 62px;

    &:first-child {
      justify-content: left;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      height: 5px;
      width: calc(100% + 62px);

      background: variables.$wds-orange;
      top: 50%;
      left: 55%;

      /*create new stacking context to keep pseudo element under parent*/
      z-index: -1;
    }
  }
}

.wtf-radio-center-correction {
  margin-left: 2px;
}

.wtf-caret {
  font-size: 10px !important;
}

.wtf-form-label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  margin-top: 6px;
}

.search-input {
  width: 100%;
  height: 36px;
  border: 1px solid variables.$grey-300;
}

.address-search-input {
  max-width: 351px;
}

.city-search-input {
  max-width: 155px;
}

.dialog-wtf-container {
  max-width: 411px;
  width: 100%;
}

.dialog-wtf-289 .dialog-wtf-container {
  max-height: 289px;
}

.dialog-wtf-623 .dialog-wtf-container {
  max-height: 623px;
}

.dialog-wtf-420 .dialog-wtf-container {
  max-height: 420px;
}

.dialog-wtf-380 .dialog-wtf-container {
  max-height: 380px;
}

.dialog-wtf-785 .dialog-wtf-container {
  max-height: 785px;
}

.dialog-wtf-433 {
  .dialog-wtf-container {
    max-height: 433px;
  }

  .ms-Modal-scrollableContent {
    overflow: hidden;
  }
}

.dialog-wtf {
  .ms-Dialog-header {
    padding: 0px;
    margin-top: 18px;
    margin-left: 14px;
    display: none;
  }

  .ms-Dialog-inner {
    padding: 0px;
  }
}

.dialog-wtf-502 .dialog-wtf-container {
  max-height: 502px;
}

.zip-search-input {
  max-width: 100px;
}

.zip-and-state-wrapper {
  margin-left: 13px;
}

.mapboxgl-map {
  font-family: variables.$main-font-family !important;
}

.plp-kpi-wrapper {
  display: flex;
  margin: 0 30px 20px;
  align-items: center;
  border: 3px solid variables.$wds-orange;
  padding: 15px 30px;
}

.plp-kpi-box {
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  @include mixins.kpi-box(125px);

  .plp-kpi-white-box {
    min-height: 60px;
    display: flex;
    flex-direction: column;
    @include mixins.kpi-white-box(200px);
  }

  .plp-kpi-value {
    display: flex;
    flex-direction: column;
    @include mixins.kpi-value(200px);
    td {
      background: variables.$grey-100;
      padding: 5px;
      min-width: 200px;
    }
  }

  .plp-kpi-change {
    display: flex;
    flex-direction: column;
    @include mixins.kpi-change();
  }

  .plp-kpi-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 2px;
    max-width: 180px;
    text-align: center;
    overflow: visible;
    overflow-wrap: break-word;
  }

  .plp-kpi-metric {
    display: flex;
    justify-content: center;
  }
}

.plp-kpi-wrapper {
  @include mixins.kpi-box(150px);
  margin: 15px 30px 30px;

  .plp-kpi-white-box {
    @include mixins.kpi-white-box(230px);
  }
}

.my-properties-callout-wrapper {
  padding: 20px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  h6 {
    font-weight: 700;
  }
}

.plp-my-properties-list {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.unfollow-x {
  margin-left: 5px;

  i {
    color: variables.$wds-orange;
  }
}

.plp-last-update {
  @include mixins.kpi-last-update();
  padding: 0 0 0 30px;
}
