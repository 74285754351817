@use 'sass/abstracts/mixins';
.scroll-button {
    padding: 5px;
    cursor: pointer;
    width: 100px;
    position: fixed;
    bottom: 120px;
    left: 15px;
    z-index: 2;

    @include mixins.desktop {
        bottom: 45px;
        left: 125px;
    }
}