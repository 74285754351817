@use 'sass/abstracts/variables';

.left-navigation {
  border-radius: 5px;
  padding: 15px 25px;
  margin-top: 32px;
  display: block;
  span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 32px;
    color: variables.$black;
    text-decoration: none;
    cursor: pointer;


  }

  .user-buttons {
    display: block;
    width: 125px;
    margin-top: 10px;

  }

  .back {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
}
