@use 'sass/abstracts/variables';
.program-use-container {
  padding: 60px 37px 0 34px;
}

.pu-description {
  font-family: variables.$main-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 50px;
}

.pu-placeholder {
  height: 600px;
}