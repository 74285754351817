@use "sass/abstracts/variables";


%black-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: variables.$black;
  color: variables.$white;
  font-size: 16px;
  font-weight: bold;
  padding: 0 30px;
  height: 50px;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 0;

  & > * {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
}

%orange-border-no-top {
  border-width: 0 2px 2px;
  border-color: variables.$wds-orange;
  border-radius: 0 0 5px 5px;
  border-style: solid;
  box-sizing: border-box;
}