@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.left-sidebar-list {
  overflow-y: auto;
  max-height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    list-style-type: none;
    padding-left: 5px;
  }

  li {
    display: flex;
    align-items: center;
    border-bottom: 2px solid variables.$grey-400;
    margin: 0 10px 0 5px;
    padding: 10px 20px 10px 0px;
    position: relative;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    &.selected {
      background: variables.$wds-orange-rgba;
    }
  }

  .sbl-icon {
    margin-right: 15px;
  }

  .sidebar-list-chevron {
    position: absolute;
    right: 0px;
    width: 2em;
    height: 2em;
  }

  &.fp-lsl {

    li {
      font-size: 14px;
    }

    .sbl-icon {
      width: 45px;
      height: 45px;
      margin-right: 5px;

      &.color-002EC9 {
        .inside-fill {
          fill: #002EC9;
        }
      }
      &.color-0066ff {
        .inside-fill {
          fill: #0066ff;
        }
      }
      &.color-2ba2fc {
        .inside-fill {
          fill: #2ba2fc;
        }
      }
      &.color-3efe35 {
        .inside-fill {
          fill: #3efe35;
        }
      }
      &.color-660053 {
        .inside-fill {
          fill: #660053;
        }
      }
      &.color-994D8B {
        .inside-fill {
          fill: #994D8B;
        }
      }
      &.color-CF0505 {
        .inside-fill {
          fill: #CF0505;
        }
      }
      &.color-e69800 {
        .inside-fill {
          fill: #e69800;
        }
      }
      &.color-f502ff {
        .inside-fill {
          fill: #f502ff;
        }
      }
      &.color-ffffff {
        .inside-fill {
          fill: #ffffff;
        }
      }
    }

  }
}
