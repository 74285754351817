@use 'sass/abstracts/variables';

.src-action .ms-SearchBox {
  height: 36px;
  width: 275px;
  border-radius: 0;
  border: 1px solid variables.$grey-300;
  flex-flow: row-reverse;

  input.ms-SearchBox-field::placeholder {
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    color: #000;
  }

  .ms-SearchBox-icon {
    transform: rotateY(180deg);
    color: variables.$grey-300;
    font-size: 20px;
  }
}

.src-action-items-wrap::after {
  display: block;
  content: '';
  clear: both;
}

.src-action-block {
  background-color: variables.$wds-orange;
  height: 36px;
  border-radius: 30px;
  padding: 0 9px 0 22px;
  display: flex;
  align-items: center;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;

  button.ms-Button {
    color: variables.$black;
    margin-left: 2px;
  }
}

.src-action-wrap .src-action-item {
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px 5px 16px;
  white-space: nowrap;
  border-bottom: 1px solid variables.$grey-300;
  border-left: 1px solid variables.$grey-300;
  border-right: 1px solid variables.$grey-300;

  &:first-child {
    border-top: 1px solid variables.$grey-300;
  }

  .ms-Button {
    margin-left: 8px;
  }
}
