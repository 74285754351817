@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.main-container {
  // display: flex;
  // flex-direction: column;
  // flex: 1;
  // background-color: variables.$white;

  .page-main {
    margin: 0 30px 30px;
    position: relative;
  }
}

.subnav-top {
  padding: 15px;
  color: variables.$black;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;

  .links {
    border: 1px solid variables.$black;
    border-radius: 3px;
    text-align: left;

    a {
      margin: 0px 5px;
    }

    span {
      padding-left: 15px;
    }

    .ms-Spinner {
      margin-top: 10px;
    }
  }
}

.bg-gray-400 {
  color: #000 !important;
  background-color: variables.$grey-200 !important;
}

.bg-gray-500 {
  background-color: variables.$grey-300 !important;
}

.bg-gray-600 {
  background-color: variables.$grey-400 !important;
}

.dropdown-menu {
  left: auto !important;
  right: 0px !important;
}



.active-table {
  font-family: variables.$main-font-family !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: variables.$black !important;

  .frsl-wrap {
    z-index: 9999;
    min-height: 0px;
  }

  .table-title {
    font-weight: bold !important;
    font-size: 25px !important;
    line-height: 30px !important;
    padding-bottom: 12px;
  }

  .ms-SearchBox-field {
    padding: 10px 0px 9px 11px !important;
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
 
  }
}

.sitmap-main-container {
  height: calc(100% - 40px);
  overflow: hidden;
}

.dialog-wtf-433 {

  .ms-Dialog-header {
    display: none;
  }

  .ms-Dialog-inner {
    padding: 0px;
  }
}

.page-full-size-map-sitmap {
  height: calc(100% - 95px);
}

.sitm-top-map {
  position: relative;
  height: 100%;
  overflow: hidden;

  #sitmap.map-wrapper {
    display: flex;
    height: 100%;
  }
}

