@use 'sass/abstracts/variables';

/* AG grid class extension */

.ag-theme-quartz {
  font-family: variables.$main-font-family !important;
  --ag-header-background-color: #{variables.$wds-orange};
  --ag-wrapper-border-radius: 2px;
  --ag-header-column-resize-handle-color: #{variables.$grey-400};
  --ag-odd-row-background-color: #{variables.$grey-100};

  .ag-header-cell {
    font-weight: bold;
  }

  .ag-root-wrapper {
    border: solid 0px variables.$grey-300;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
    min-height: 6rem;
  }

  .pl-row-normal {
    height: 35px;
    font-size: 16px;
    color: variables.$black;
  }

  .ag-paging-panel {
    font-size: 16px;
  }

  .ag-icon {
    font-weight: bold;
  }
}

.ag-cell {
  display: flex;
  align-items: center;
}

.ag-cell-value {
  font-size: 14px;
  font-weight: 400;
}

.ag-header-cell-sorted-none {
  .ag-sort-none-icon {
    display: block !important;
  }

  .ag-icon-none:before {
    content: '\2193 \2191' !important;
    margin-top: -4px;
    display: block;
  }
}

.ag-header-cell-label {
  .ag-header-cell-icon, .ag-header-cell-text {
    font-size: 12px !important;
  }
}