﻿@use 'sass/abstracts/variables';

/* Dialog */

.dialog-deletequery-container {
  min-width: 407px;
  max-width: 450px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .ms-Dialog-title {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: variables.$black;
    padding: 4rem 2.4rem 1.6rem 2.4rem;
  }

  .ms-Dialog-subText {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: variables.$black;
    padding: 4rem 2.4rem 1.6rem 2.4rem;
    padding: 0rem 0rem 2rem 0rem;
  }

  .ms-Dialog-actions {
    margin: 0px;
    margin-bottom: 2rem;

    .ms-Dialog-actionsRight {
      text-align: center;
      margin: 0px;

      .ms-Button {
        width: 144px;
        height: 42px;
        border: none;
        border-radius: 5px;
        background-color: variables.$wds-orange;
        padding: 0px;

      }

      .ms-Button-label {
        font-family: variables.$main-font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: variables.$black;
     
      }
    }
  }
}

/* Property List */

.pqb-ra-pad {
  padding: .5rem 1.8rem;
  max-height: 2.25rem;
}

.pqb-btn-pad {
  padding: .5rem 1rem;
  max-height: 2.25rem;
}

.pqb-select {
  padding-top: .4rem;
  padding-bottom: .4rem;
  padding-left: .7rem;
  border: 1px solid variables.$grey-300;
  box-sizing: border-box;
  border-radius: 0;
  background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M5 8L0.669872 0.500001L9.33013 0.5L5 8Z'/%3E%3C/svg%3E" );
  min-width: 10rem;
  width: auto;
  max-height: 2.25rem;
  font-size: 16px;
  font-weight: bold;
}

.pqb-textbox {
  padding-top: .4rem;
  padding-bottom: .4rem;
  padding-left: .7rem;
  border: 1px solid variables.$grey-300;
  box-sizing: border-box;
  border-radius: 0;
  max-height: 2.25rem;
}

.pqb-lb-lh {
  line-height: 1.5rem;
  padding-top: .35rem;
}

.text-restriction {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pl-lh-23 {
  line-height: 2.3rem;
}

.add-columns .ms-Dropdown-container {
  width: 15rem;
}

/** Customized dropdown list*/

.pl-custom-dropdown {
  background-color: variables.$white;
  color: variables.$black;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.3rem;
  border: 1px solid variables.$grey-300;
  box-sizing: border-box;
  border-radius: 0px;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  box-shadow: none;
  padding: .375rem 1.3rem .375rem .5rem;

  &:focus, &:hover {
    background-color: variables.$white;
    color: variables.$black;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.3rem;
    border: 1px solid variables.$grey-300;
    box-sizing: border-box;
    border-radius: 0px;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    box-shadow: none;
    padding: .375rem 1.3rem .375rem .5rem;
  }

  &::after {
    position: absolute;
    right: .5rem;
    top: 1rem;
    font-size: 1.3rem;
  }
}

.pl-custom-dropdown-menu {
  overflow-y: hidden !important;
  width: 100%;
  color: variables.$black;
  box-sizing: border-box;
  border-radius: 0px 0px 2px 2px;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  outline: transparent;
  padding-top: 0;
  padding-bottom: 0;
}

.pl-custom-drop-item {
  font-size: 16px;
  color: variables.$black;
  background-color: variables.$white;
  cursor: pointer;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    font-size: 16px;
    color: variables.$black;
    background-color: variables.$white;
    cursor: pointer;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.pl-custom-dropitem-link {
  display: block;
  width: auto;
  padding: 0.25rem .25rem;
  text-decoration: none;
  font-size: 16px;
  color: variables.$black;
  cursor: pointer;

  &:hover {
    display: block;
    width: auto;
    padding: 0.25rem .25rem;
    text-decoration: none;
    font-size: 16px;
    color: variables.$black;
    cursor: pointer;
  }
}

.remove-bold .pl-custom-dropdown {
  font-weight: normal;
  color: rgb(96, 94, 92);
}

.w-rel {
  width: 50%;
}

.w-rel-max {
  max-width: 50%;
}

.no-wrap {
  white-space: nowrap;
}

/** AG */

.pl-ag.ag-theme-quartz {
  .pl-sort {
    margin-left: .8rem;
    margin-top: .25rem;

    .ms-Icon {
      font-size: 1rem;
      color: variables.$black;
    }

    &.pl-sort-show.ag-hidden {
      display: block !important;
    }

    &.pl-sort-hide {
      display: none !important;

      &.ag-hidden {
        display: none !important;
      }
    }
  }

  .ag-header {
    background-color: variables.$white;
    background-color: var(--ag-header-background-color, variables.$white);
    border-bottom: none;
    border-bottom-color: transparent;
    border-bottom-color: var(--ag-border-color, transparent);
  }

  .ag-cell-value {
    font-weight: normal;
  }
}

.pl-ag2.ag-theme-quartz {
  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal;
    overflow: visible;
  }

  &.dm-cell2 {
    .ag-cell, .ag-header-cell {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  &.dm-cell3 {
    .ag-cell, .ag-header-cell {
      padding-left: 1px;
      padding-right: 1px;
    }
  }

  &.dm-cell2 {
    .ag-cell-value, .ag-header-cell-label .ag-header-cell-text {
      font-size: 10px !important;
    }
  }

  &.dm-cell3 {
    .ag-cell-value, .ag-header-cell-label .ag-header-cell-text {
      font-size: 8px !important;
    }
  }

  &.dm-cell2 .ag-header-cell-label .ag-header-cell-text, &.dm-cell3 .ag-header-cell-label .ag-header-cell-text {
    width: min-content !important;
  }

  &.dm-cell2 .ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon, &.dm-cell3 .ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
    margin-left: 0;
  }
}

.pre-s-size {
  min-width: 8rem;
}
