@use 'sass/abstracts/variables';

.page-apim {
  margin-top: 14px !important;
}

.page-apim-tokens {
  margin-top: 0px !important;
}

.apim-del-menu {
  min-width: 100px;
  max-width: 100px;
}

.page-apim {
  .apim-user-list {

    .ag-header-row {
      height: 30px !important;
    }

    .ag-header-cell-text {
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: variables.$black;

    }

    .ag-row-inactive .ag-cell {
      color: variables.$red;
    }

    .ag-cell {
      padding: 8px 0px 0px 17px !important;
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: variables.$black;

    }
  }

  .apim-user-list-editor .ag-row>.ag-cell[aria-colindex="10"] {
    padding: 0px 5px !important;
  }

  .apim-right {
    margin-top: -60px;
    margin-left: 16px;
    width: 451px;
  }

  .info-header {
    margin-top: 24px;
    height: 38px;
    background: variables.$wds-orange;
    border-radius: 5px 5px 0px 0px;
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    padding: 10px 0 0 16px;
  }

  .info-header2 {
    margin-top: 24px;
    height: 38px;
    background: variables.$wds-orange;
    border-radius: 5px 5px 0px 0px;
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 11px 0 0 0px;
    text-align: center;
  }

  .info-form-lastuse {
    height: 74px;
    background: variables.$grey-200;
    padding: 22px 0px 0px 85px;
  }

  .info-form-empty {
    border-radius: 0px 0px 10px 10px;
  }

  .info-content-lastcall {
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: variables.$blue;
  }

  .info-pic {
    height: 31px;
  }

  .info-pic-user {
    background: no-repeat url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAYAAABvVQZ0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBnVTdUQJBDP5yyqgjjtABVqBUoFagViBUILyIPHE8Ib4AFQAd0AF0IFYAVgAMOMgc3prNLf/HiWTmZndymy/fJl+WsM0KwxsQ5UC4gkKE1z6vDSgnj2y06xdCvkDFUYkDU0ueLn+xRRTZyJzm18OsTaCvnACR0kyS+HaieAlfyOoi7TFUNidMBTMr9GKgUEeA3Gnc9zpvoysGfRfQsXMBO9r3Z0aHJhvVttUFz+E2lFuXOp6EEth+TbqUZYo6gkw3QlZz3h/M2NFpD0FG1Pdzr4Ip91PWn2EcwWgxc34QADajbz0hmFnO7Bor7o2Dr6MOtKYIZWTC6ZV/di+C41CJdwkGbLDWHoLBPHk0BRDUZZqsejXg4HM+nZAuahEr53a944cbYBPWzzFakh2KAbWATU41v2Z7vt/KrDh+5OqXvVnUE6CD1Af/0d1jn0jhxoQyOzeJ7FlrE0zGiMdEGKgKJlN7Wd0rZcCBDbIePYSDBDIn9QWY90I0zVU4W7iGv6wwtM2rwqPnyOh50iCranimdwLSlj2zOXPFK0mo6oUXRtxmVNnZZqA4/mszKSl1a3Gh74y7gn2M5nH3FncsJtuJ08A+5po4i64teZZ1Ef06t4vNhKso8guRDbV87JE2CAAAAABJRU5ErkJggg==);
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .info-pic-oauth {
    background: no-repeat url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAUCAYAAABroNZJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGaSURBVHgBpVS7TgJBFD0ziyEkqNBopzRaSaTwA6C1wT9Y6OzAilABjaiN8AViZQuNnQkm9tBbuPEHQCWG5TXe2XUFZGcDepLNzuPcc+fcvTuAF64+Myj3WvSkvGjMdbX8EQdjBRrFZ6gGhCghH6ypRYqdEAJrKUyg02oM6ry2GIZN5MOGLXLZy0IgOZ91aTRJocFw0RP4J7jXZmyLI3XgQ2ST/03kZM+HVjoAPerDy2kA8R1tdRE9quHswUTiro/S0wA6nWhlkZCfodu3x8abIEtMKeIq79Qhua9ZwYfb3JpLi/Xn0TyZcsmTGFiw4rOCZR12KVi+5Txz5JrT4BCTRyggbaTvTTRfx1BCoE0iqKv25SeWJ5A2lGCTBsdgLLuuq+LcHPvhAQO5jbpd8nNqfY5rZ8epwUIE2ZuzRm7lDzlllt9rYFzHshCiivx6Vg6nfWKO5Y/YXk6AeOao6EynIsVwF+YwQfdI3VtgcmvxJP8b7m0oa6ShQBlDP2tMUBArIRes/Kare7nciQBaEZwl6aKq0vErs9ln8QUT2IRXOIrtoAAAAABJRU5ErkJggg==);
    width: 20px;
    height: 20px;
    margin: 0px 20px 0px 10px;
  }

  .info-pic-lastcall {
    background: no-repeat url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAWCAYAAAA4oUfxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJYSURBVHgBtVZbTttAFD138hCoASUraLqChhXUWQFhBYQVwFdL2g+Sj8ogVSqsIGEHsIJmB2QHeAdETRBNjT29907Co0GuXeIjRTP2xHPuua8ZQhr4Ew9E2zxr8a+u7yxGMBQgtpfoVAb4D1Diqn9TB5X6PPOQvE0A+7uJTi1ABhgkEpd/KDHZMazt8a+JX2ENhxXiL7dY/R6vB+wGMfIaJ3e7yABKJuZNxb0IdxJVHU9Oeat93m2MezTxpTJCCrysnEpHSiyqZuG/3Xm4ccD/P2NDqyjgO1JiWbmL87XObfgudRy7N1Wsla4gCanhsJyMNE7yQnH5Vclzo2RxhgRaL+8zqXwnkvqqq8CPx1N5OWAhvb/3W3Y72W030hBpId6ytjsPlStDYDgfBW2XkLdHyeSgqg6xTZU0ClEkrl5UQ6eyxRXR1FFCZ+NzZxQb+MSAx5hLzNYl0dhKSRyKd/Bp8wKrgv+T1Zv+3Ag2bGPolIvb1spXTHqgxIKYPKwSnc2B9goBkap35E9r2jUOXqFMDSMVZven2gukcX2dNgz8aft5TUuf5poFelg1urUx4vhS50V4RbbEKbRMJosCbRp5gc8BQWyrhskb+jALV5dcKWEeEmyhOm8Q3jtmO5KEC/Thm32LvCFVBWrpPI6E3LoEiG5zjPMcemApBtKYxO0u1lLjGc/jTDiZymnXdneDUCvJdTh/0l0UvloV4SztmZwI/64OE7W4Ye0yaUOJI7OHz28uHsmXDcgDAQxfSj7WHkQ9P881IQpshPmAxUXxNVAXk3TN85cumX8AJI3wjfpLIM8AAAAASUVORK5CYII=);
    width: 30px;
    height: 30px;
    margin: 0px 8px 0px 20px;
  }

  .info-arrow {
    display: inline-block;
    width: 0px;
    height: 0px;
    border: 3px solid transparent;
    overflow: hidden;
  }

  .info-arrow-left {
    border-left: none;
    border-right: 6px solid variables.$blue;
  }

  .info-arrow-right {
    border-right: none;
    border-left: 6px solid variables.$blue;
  }

  .info-line-left {
    margin-top: 7px;
  }

  .info-line {
    width: 18px;
    height: 2px;
    margin-top: 2px;
    background-color: variables.$blue;
  }

  .info-status {
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    align-items: center;

    .info-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-bottom: 3px;
    }
  }

  .info-status-live {
    color: variables.$green-200 !important;

    .info-arrow-left {
      border-right-color: variables.$green-200 !important;
    }

    .info-arrow-right {
      border-left-color: variables.$green-200 !important;
    }

    .info-line,
    .info-circle {
      background-color: variables.$green-200 !important;
    }
  }

  .info-status-offline {
    color: variables.$red !important;

    .info-arrow-left {
      border-right-color: variables.$red !important;
    }

    .info-arrow-right {
      border-left-color: variables.$red !important;
    }

    .info-line,
    .info-circle {
      background-color: variables.$red !important;
    }
  }

  .info-form {
    border: 1px solid variables.$grey-300;
    border-radius: 0px 0px 10px 10px;

    .error {
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: variables.$red;
      padding-top: 5px;
    }

    .form-row {
      margin: 17px 20px 0px 0px;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    .form-buttons {
      margin-top: 7px;

    }

    .ms-Dropdown-title {
      font-weight: normal;
    }
  }
}

.apim-info-dropdown {
  max-height: 400px !important;
}

.page-apim {
  .info-form {
    .apim-form-label {
      width: 99px;
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: variables.$black;
      text-align: right;
      padding: 8px 12px 0px 0px;
    }

    .apim-from-input {
      width: 332px;
      height: 30px;
      border: 1px solid variables.$grey-300;
      padding: 0px 11px;
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: variables.$black;
    }
  }

  .ms-Dropdown-container .is-disabled .ms-Dropdown-title {
    background-color: variables.$grey-200 !important;
    cursor: not-allowed;
  }

  .info-form {

    .apim-from-input:disabled,
    .apim-from-input2:disabled {
      background-color: variables.$grey-200 !important;
      cursor: not-allowed;
    }
  }

  .info-form {
    .apim-from-input2 {
      width: 169px !important;
    }

    .form-check-input {
      width: 20px !important;
      height: 20px !important;
      margin-top: 5px;
      border-radius: 0px;

      &:checked {
        background-color: variables.$black;
        border-color: variables.$black;
      }
    }
  }
}

.apim-form-tip {
  border: 1px solid variables.$grey-300;
  padding: 5px;
  font-family: variables.$main-font-family !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: variables.$black;
  border-radius: 5px;

  .ms-Callout-beakCurtain {
    border-radius: 5px;
  }
}

.page-apim-tokens {
  .token-header {
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: variables.$black;
    cursor: pointer;

    > i {
      font-size: 22px;
      line-height: 22px;
      color: variables.$black;
    }
  }

  .apim-token-list {
    margin-top: 29px;
  }
}