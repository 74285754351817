﻿@use 'sass/abstracts/variables';

.fireoverview-propertydetail {
  .propertyDetail-container {
    padding-left: 20px;
    padding-bottom: 15px;
    height: 544px;
    overflow-y: auto;
    overflow-x: hidden;

    .property-info-left {
      padding-top: 8px;
    }

    .property-info-right {
      padding-top: 2px;
      width: 20px;
      padding-right: 8px;

      .arrow-left {
        cursor: pointer;
        width: 14px;
        display: inline-block;

        img {
          width: 7px;
          height: 14px;
        }
      }
    }
  }

  .title {
    font-weight: bold;
    flex: 1;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: variables.$black;
  }

  .text {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: variables.$black;
 
  }

  .bold {
    font-weight: bold;
  }

  .link-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    color: variables.$black;
 
  }

  .threatened {
    flex: 1;
    line-height: 30px;
    color: variables.$red;
    text-align: center;
    font-size: 16px;
  }

  .property-info-address {
    margin-top: 3px;
  }

  .gate-code {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

.fireoverview-propertydetail {
  .hr-1 {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 10px;
    height: 1px;
    background: variables.$grey-300;
  }

  .visit-info {
    padding-right: 12px;
  }

  .home-status {
    margin-top: 12px;

    .link-text {
      line-height: 19px;
    }
  }

  .photos {
    margin-top: 10px;
    padding-right: 5px;
    width: 270px;

    .item {
      padding: 6px;
      height: 85px;
      border: 0.5px solid variables.$grey-300;
      margin-right: 5px;
      width: 80px !important;
    }
  }

  img {
    height: 72px;
    width: 100%;
    cursor: pointer;
  }

  .photos {
    img {
      background-color: variables.$grey-300;
    }

    .slick-slider .slick-track {
      margin-left: 0px !important;
    }
  }
}

/*slide images*/

.slick-prev:before, .slick-next:before {
  color: variables.$black !important;
}

/*end slide images*/

.fireoverview-propertydetail {
  .actions-taken {
    margin-top: 8px;

    ul {
      padding-left: 20px;
    }
  }

  .comments {
    margin-top: 12px;
  }

  .visit-info {
    .threatened {
      line-height: 19px;
      text-align: left;
      font-style: italic;
      margin-top: 12px;
    }

    .link-text {
      cursor: pointer;
    }

    .disabled {
      color: variables.$grey-300;
      cursor: not-allowed;
    }

    .not-threatened {
      color: variables.$black;
    }
  }
}
