@use 'sass/abstracts/variables';

.wz-selected-properties {
  position: absolute;
  width: auto;
  height: auto;
  left: 13px;
  bottom: 10px;
  background: variables.$white;
  border-radius: 10px;
  padding: 7px 8px 7px 7px;
  z-index: 110;

  table {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: variables.$black;

    th {
      padding: 0 6px 0 0;
    }

    td {
      padding: 0;
    }
  }
}
