@use 'sass/abstracts/variables';

%orange-checkbox {
  background-color: variables.$wds-orange;
  border-color: variables.$wds-hover-orange;

  &:hover {
    background-color: variables.$wds-hover-orange;
  }

  i {
    color: variables.$black;
  }
}

.ms-Checkbox.is-checked .ms-Checkbox-checkbox {
  @extend %orange-checkbox;
}