@use 'sass/abstracts/variables';

/** Customized dropdown list*/

.pl-custom-dropdown {
  background-color: variables.$white;
  color: variables.$black;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.3rem;
  border: 1px solid variables.$grey-300;
  box-sizing: border-box;
  border-radius: 0px;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  box-shadow: none;
  padding: 0.375rem 1.3rem 0.375rem 0.5rem;

  &:focus, &:hover {
    background-color: variables.$white;
    color: variables.$black;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.3rem;
    border: 1px solid variables.$grey-300;
    box-sizing: border-box;
    border-radius: 0px;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    box-shadow: none;
    padding: 0.375rem 1.3rem 0.375rem 0.5rem;
  }

  &::after {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    font-size: 1.3rem;
  }
}

.pl-custom-dropdown-menu {
  overflow-y: hidden !important;
  width: 100%;
  color: variables.$black;
  box-sizing: border-box;
  border-radius: 0px 0px 2px 2px;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  outline: transparent;
  padding-top: 0;
  padding-bottom: 0;
}

.pl-custom-drop-item {
  font-size: 16px;
  color: variables.$black;
  background-color: variables.$white;
  cursor: pointer;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    font-size: 16px;
    color: variables.$black;
    background-color: variables.$white;
    cursor: pointer;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.pl-custom-dropitem-link {
  display: block;
  width: auto;
  padding: 0.25rem 0.25rem;
  text-decoration: none;
  font-size: 16px;
  color: variables.$black;
  cursor: pointer;

  &:hover {
    display: block;
    width: auto;
    padding: 0.25rem 0.25rem;
    text-decoration: none;
    font-size: 16px;
    color: variables.$black;
    cursor: pointer;
  }
}

.remove-bold .pl-custom-dropdown {
  font-weight: normal;
  color: rgb(96, 94, 92);
}

@media (max-width: 1280px) {
  .pl-custom-dropdown {
    font-size: 12px !important;

    &:focus, &:hover {
      font-size: 12px !important;
    }
  }

  .pl-custom-drop-item {
    font-size: 12px !important;
    padding: 0.25rem 0.5rem;

    &:hover {
      font-size: 12px !important;
      padding: 0.25rem 0.5rem;
    }
  }

  .pl-custom-dropitem-link {
    font-size: 12px !important;

    &:hover {
      font-size: 12px !important;
    }

    &.me-3 {
      margin-right: 0.5rem !important;
    }
  }

  .pl-custom-dropdown-menu {
    min-width: 6rem !important;
  }

  .pl-ag.ag-theme-quartz {
    .ag-header-cell-label .ag-header-cell-text {
      font-size: 12px !important;
    }

    .ag-cell-value {
      font-size: 12px !important;
      font-weight: normal;
    }

    .ag-paging-panel {
      font-size: 12px !important;
    }
  }
}
