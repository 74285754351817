@use 'sass/abstracts/variables';

.wz-page-title {
  padding-top: 20px;
  padding-left: 34px;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 11px;
}

.wz-page-main {
  margin: 0 29px 0;
}

.wz-config {
  display: flex;
  align-items: stretch;
  height: 600px;
  margin-bottom: 16px;
}

.wz-left {
  flex: none;
  width: 244px;
  margin-right: 10px;
}

.wz-map {
  flex-grow: 1;
  position: relative;
}

.wz-map-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.wz-map-container {
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;

  &.with-message {
    top: 30px;
  }
}

#wz-drawing-controls {
  position: relative;
}

.wz-selected-workzone {
  position: absolute;
  top: 36px;
  right: -5px;
}

.wz-workzones-title {
  height: 36px;
  background: variables.$wds-orange;
  padding-left: 13px;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: variables.$black;
}

.wz-map .marker-container {
  z-index: 200;

  &.wz-map-name {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 5px 2px 7px;
    color: variables.$black;
    background: variables.$white;
    max-width: 200px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
    z-index: 80;

    &.active, &.hover {
      border: 2px solid variables.$black;
      padding: 4px 10px 4px 11px;
      font-size: 16px;
      line-height: 20px;
      max-width: 272px;
      z-index: 100;
    }
  }
}

#wz-drawing-controls::after {
  content: "";
  display: block;
  clear: both;
}

.wz-controls {
  display: flex;
  position: absolute;
  top: 9px;
  right: 7px;
  height: 30px;
  overflow: visible;
  z-index: 110;

  > * + * {
    margin-left: 8px;
  }

  .azure-maps-drawtoolbar > button {
    width: 28px;
    height: 28px;
    background-color: variables.$white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: -1px;

    &:hover, &.active {
      background-color: #000;
    }
  }

  .azure-maps-drawtoolbar-button {
    &.draw-polygon {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.82 12.018H.18V.607L7.6 5.362 11.821.098v11.92zm-10.64-1h9.64V2.944L7.818 6.689 1.18 2.435v8.583z' fill='%23AFAFB4'/%3E%3C/svg%3E");

      &.active, &:hover {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.82 12.018H.18V.607L7.6 5.362 11.821.098v11.92zm-10.64-1h9.64V2.944L7.818 6.689 1.18 2.435v8.583z' fill='%23F2B643'/%3E%3C/svg%3E");
      }
    }

    &.draw-circle {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 13.053a6.2 6.2 0 1 1 6.2-6.2 6.207 6.207 0 0 1-6.2 6.2zm0-11.4a5.2 5.2 0 1 0 0 10.4 5.2 5.2 0 0 0 0-10.4z' fill='%23AFAFB4'/%3E%3C/svg%3E");

      &:hover, &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 13.053a6.2 6.2 0 1 1 6.2-6.2 6.207 6.207 0 0 1-6.2 6.2zm0-11.4a5.2 5.2 0 1 0 0 10.4 5.2 5.2 0 0 0 0-10.4z' fill='%23F2B643'/%3E%3C/svg%3E");
      }
    }

    &.draw-rectangle {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.07 13.07H.93V.93h12.14v12.14zm-11.14-1h10.14V1.93H1.93v10.14z' fill='%23AFAFB4'/%3E%3C/svg%3E");

      &:hover, &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.07 13.07H.93V.93h12.14v12.14zm-11.14-1h10.14V1.93H1.93v10.14z' fill='%23F2B643'/%3E%3C/svg%3E");
      }
    }

    &.edit-geometry {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.058 12.032H1.79L6.6 2.65l2.183 4.483.748-.748L6.623.415.153 13.031h4.674l.231-1z' fill='%23AFAFB4'/%3E%3Cpath d='M11.482 6.838a1.248 1.248 0 0 0-1.766 0L5.82 10.735l-.531 2.297 2.297-.531 3.896-3.897a1.25 1.25 0 0 0 0-1.766zm-4.401 4.716l-.401.086.087-.4 3.624-3.624a.222.222 0 0 1 .314.314L7.08 11.554z' fill='%23AFAFB4'/%3E%3C/svg%3E");

      &:hover, &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.058 12.032H1.79L6.6 2.65l2.183 4.483.748-.748L6.623.415.153 13.031h4.674l.231-1z' fill='%23F2B643'/%3E%3Cpath d='M11.482 6.838a1.248 1.248 0 0 0-1.766 0L5.82 10.735l-.531 2.297 2.297-.531 3.896-3.897a1.25 1.25 0 0 0 0-1.766zm-4.401 4.716l-.401.086.087-.4 3.624-3.624a.222.222 0 0 1 .314.314L7.08 11.554z' fill='%23F2B643'/%3E%3C/svg%3E");
      }
    }

    &.erase-geometry {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.75889 2.24859H9.00936V10.8682C9.00936 11.0256 8.97938 11.168 8.91942 11.3104C8.85946 11.4453 8.7845 11.5653 8.67957 11.6702C8.57464 11.7751 8.46221 11.8501 8.31979 11.91C8.17738 11.97 8.03497 12 7.87757 12H1.88132C1.72392 12 1.58151 11.97 1.4391 11.91C1.29669 11.8501 1.18426 11.7751 1.07932 11.6702C0.97439 11.5653 0.899437 11.4528 0.839475 11.3104C0.779512 11.1755 0.749531 11.0256 0.749531 10.8682V2.24859H0V1.49906H2.99812V0.749531C2.99812 0.644597 3.02061 0.547158 3.05809 0.457214C3.09556 0.367271 3.14803 0.284828 3.21549 0.21737C3.28295 0.149913 3.36539 0.0974404 3.45534 0.0599639C3.54528 0.0224873 3.64272 0 3.74766 0H5.99625C6.10118 0 6.19862 0.0224873 6.28857 0.0599639C6.37851 0.0974404 6.46096 0.149913 6.52842 0.21737C6.59587 0.284828 6.64834 0.367271 6.68582 0.457214C6.72329 0.547158 6.74578 0.644597 6.74578 0.749531V1.49906H9.7439V2.24859H9.75889ZM8.25983 2.24859H1.51405V10.8682C1.51405 10.9731 1.55153 11.0556 1.62648 11.1305C1.70144 11.2055 1.79138 11.243 1.88882 11.243H7.88507C7.99 11.243 8.07245 11.2055 8.1474 11.1305C8.22236 11.0556 8.25983 10.9656 8.25983 10.8682V2.24859ZM3.76265 9.7439H3.01311V3.74766H3.76265V9.7439ZM3.76265 1.49906H6.01124V0.749531H3.76265V1.49906ZM5.26171 9.7439H4.51218V3.74766H5.26171V9.7439ZM6.76077 9.7439H6.01124V3.74766H6.76077V9.7439Z' fill='%23AFAFB4'/%3E%3C/svg%3E%0A");

      &:hover, &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.75889 2.24859H9.00936V10.8682C9.00936 11.0256 8.97938 11.168 8.91942 11.3104C8.85946 11.4453 8.7845 11.5653 8.67957 11.6702C8.57464 11.7751 8.46221 11.8501 8.31979 11.91C8.17738 11.97 8.03497 12 7.87757 12H1.88132C1.72392 12 1.58151 11.97 1.4391 11.91C1.29669 11.8501 1.18426 11.7751 1.07932 11.6702C0.97439 11.5653 0.899437 11.4528 0.839475 11.3104C0.779512 11.1755 0.749531 11.0256 0.749531 10.8682V2.24859H0V1.49906H2.99812V0.749531C2.99812 0.644597 3.02061 0.547158 3.05809 0.457214C3.09556 0.367271 3.14803 0.284828 3.21549 0.21737C3.28295 0.149913 3.36539 0.0974404 3.45534 0.0599639C3.54528 0.0224873 3.64272 0 3.74766 0H5.99625C6.10118 0 6.19862 0.0224873 6.28857 0.0599639C6.37851 0.0974404 6.46096 0.149913 6.52842 0.21737C6.59587 0.284828 6.64834 0.367271 6.68582 0.457214C6.72329 0.547158 6.74578 0.644597 6.74578 0.749531V1.49906H9.7439V2.24859H9.75889ZM8.25983 2.24859H1.51405V10.8682C1.51405 10.9731 1.55153 11.0556 1.62648 11.1305C1.70144 11.2055 1.79138 11.243 1.88882 11.243H7.88507C7.99 11.243 8.07245 11.2055 8.1474 11.1305C8.22236 11.0556 8.25983 10.9656 8.25983 10.8682V2.24859ZM3.76265 9.7439H3.01311V3.74766H3.76265V9.7439ZM3.76265 1.49906H6.01124V0.749531H3.76265V1.49906ZM5.26171 9.7439H4.51218V3.74766H5.26171V9.7439ZM6.76077 9.7439H6.01124V3.74766H6.76077V9.7439Z' fill='%23F2B643'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .azure-maps-drawtoolbar > button + button {
    margin-left: 7px;
  }
}

.wz-workzones-cardlist {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-right: -5px;

  /*align-items: stretch;*/
}

.wz-card {
  /*float: left;*/
  /* position: absolute;*/
  /*flex-grow: 1;*/
  width: 148px;
  height: 135px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  scrollbar-face-color: variables.$grey-300;

  /*  left: 29px;
  top: 5px;*/
  background: variables.$white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.wz-card-blank {
  width: 148px;
  height: 135px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;

  /*  left: 29px;
  top: 5px;*/
}

.wz-card-content {
  width: 100%;
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;

  /*firefox*/

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: variables.$grey-200;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: variables.$grey-300;
  }
}

.wz-card-header {
  width: 100%;
  height: 31px;
  line-height: 31px;
  background: variables.$wds-orange;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wz-card-header-text {
  width: 70%;
  margin-left: 9px;
  vertical-align: middle;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: variables.$black;
}

.wz-card-header-input {
  width: 60%;
  height: 20px;
  margin-left: 4px;
  vertical-align: middle;
  border: thin;
  border-width: 1px;
  background: variables.$white;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: variables.$black;
}

.wz-card-header-buttons {
  line-height: 16px;
  margin-right: 5px;
}

.wz-card-header-button {
  line-height: 16px;
  margin-right: 5px;
  cursor: pointer;
}

.wz-card-engineItem {
  box-sizing: border-box;
  width: 92%;
  height: 22px;
  position: relative;
  margin-left: 5px;
  margin-top: 3px;
  padding-left: 10px;

  /*text-align:left;*/

  background: variables.$green-100;
  border: 1px solid variables.$green-300;
  border-radius: 30px;
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: variables.$black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
}

.wz-card-engineItem-icon {
  position: absolute;
  top: 0;
  padding: 6px;
  right: 5px;
  cursor: pointer;
}

.wz-leftbar {
  padding-top: 9px;
  font-family: variables.$main-font-family;
  font-style: normal;
  color: variables.$black;
}

.wz-leftbar-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-left: 8px;
  margin-bottom: 9px;
}

.wz-leftbar-paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-left: 13px;
  width: 170px;
  margin-bottom: 7px;
}

.wz-leftbar-set-options {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
  padding-left: 23px;
  margin-left: 13px;
}

.wz-leftbar-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
  margin-bottom: 9px;
}

.wz-leftbar-dropdown {
  margin-left: 10px;
  margin-right: 22px;
  margin-bottom: 12px;

  .ms-Dropdown {
    margin: 0;
  }

  .ms-Dropdown-title {
    font-size: 12px;
    padding: 0px 20px 0px 10px;
    height: 34px;
    line-height: 34px;
  }
}

.wz-leftbar-subtitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
  margin-bottom: 13px;
}

.wz-leftbar-paragraph2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  margin-bottom: 17px;
  padding-left: 22px;
}

.wz-leftbar-note {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 9px;
}

.wz-leftbar-paragraph3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  margin-bottom: 13px;
  padding-left: 22px;
}

.wz-leftbar-line {
  border-bottom: 1px solid variables.$grey-300;
  margin-left: 13px;
  margin-right: 28.5px;
}

.wz-leftbar-line1 {
  margin-bottom: 10px;
}

.wz-leftbar-line2 {
  margin-bottom: 8px;
}

.wz-leftbar-line3 {
  margin-bottom: 14px;
}

.wz-leftbar-button {
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  background-color: variables.$wds-orange;
  border-radius: 5px;
  border: none;

  &:hover {
    background-color: variables.$wds-orange;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.wz-leftbar-edit {
  width: 50px;
  margin-left: 20px;
}

.wz-leftbar-new {
  width: 103px;
  background-color: variables.$black;
  color: white;
  margin-right: 13px;
  margin-left: 20px;
}

.wz-leftbar-edit + .wz-leftbar-new {
  margin-left: 52px;
}

.wz-leftbar-new {
  &:hover {
    background-color: variables.$grey-600;
  }

  &:disabled {
    background-color: variables.$grey-500;
  }
}

.wz-leftbar-cancel {
  width: 94px;
  background-color: variables.$grey-300;
  margin-left: 13px;
  margin-right: 13px;

  &:hover {
    background-color: variables.$grey-300;
  }
}

.wz-leftbar-done {
  width: 94px;
}

@media (max-width: 1199px) {
  .wz-left {
    display: none;
  }
}

.wz-map .bottom-right.subcontrol-container .tooltiptext {
  margin-left: -19px;
}

.wz-delete-dlg {
  max-width: 400px;
  min-width: 384px;
  height: 166px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  div[class^='topButton-'] {
    display: none !important;
  }

  .ms-Dialog-title {
    font-family: variables.$main-font-family !important;
    padding: 35px 16px 27px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: variables.$black;
 
  }

  .ms-Dialog-header {
    margin: 0px;
    display: block;
  }

  .ms-Modal-scrollableContent div {
    overflow: hidden;
  }

  .ms-Dialog-actionsRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ms-Dialog-action {
    margin: 0px 9px;

    button {
      width: 110px;
      height: 36px;
      border-radius: 5px;
      border-width: 0px;
    }
  }

  .ms-Button-label {
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }

  .ms-Dialog-actions {
    margin: 0px !important;
  }

  .ms-Dialog-action {
    &:nth-child(1) {
      button {
        background: variables.$grey-300;
      }

      .ms-Button-label {
        color: variables.$black;
      }
    }

    &:nth-child(2) button {
      background: variables.$wds-orange;
    }
  }
}
