@use 'sass/abstracts/variables';

/* KPI Boxes */
@mixin kpi-box($pr){
  background: variables.$light-orange;
  border: 2px solid variables.$wds-orange;
  padding: 5px $pr 5px 15px;
  box-shadow: 0px 6px 6px #707070;
  display: flex;
  justify-content: space-between;
  align-items: center;

  [class*="kpi-tooltip"] {
    margin-left: 5px;
  }
}

@mixin kpi-white-box($flex-basis) {
  padding: 10px 5px;
  flex: 1 0 $flex-basis;
  background-color: rgba(0,0,0,0);
}

@mixin kpi-value($max-width: 170px){
  font-weight:700;
  font-size:18px;
  max-width: $max-width;
  height:25px;
  text-align:center;
  overflow:hidden;  
  white-space:nowrap;
  text-overflow: ellipsis;
}

@mixin kpi-metric(){
  font-weight:700;
  font-size:12px;
  min-width:90px;
  max-width:170px;
  text-align:center;
  overflow-wrap: break-word;  
  display:float;
}

@mixin kpi-change(){
  font-weight:700;
  font-size:12px;
  margin-bottom:2px;
  text-align:center;
  white-space:nowrap;
}

@mixin kpi-last-update(){
  font-style: italic;
  padding-bottom: 5px;
}
/* End KPI Boxes */

/* Breakpoints */
@mixin tablet-sm {
  @media (min-width: #{variables.$tablet-sm}) {
    @content;
  }
}

@mixin tablet-lg {
  @media (min-width: #{variables.$tablet-lg}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{variables.$desktop}) {
    @content;
  }
}
/* End Breakpoints */