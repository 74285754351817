﻿@use 'sass/abstracts/variables';

.mh-main-container {
  font-family: variables.$main-font-family;
  padding: 0px 30px 30px 30px;

  .page-title {
    font-weight: bold;
    font-size: 25px;
    height: 68px;
    line-height: 30px;
    padding-bottom: 16px;
    display: flex;
    align-items: flex-end;
  }
}

.mh-map {
  position: relative;
}

.mh-map-popup-contents {
  padding-bottom: 15px;
}

.mh-map .customInfobox {
  max-width: 180px;
}

.mh-popup-link {
  padding-left: 15px;
  padding-right: 15px;
  pointer-events: all;

  span {
    color: variables.$blue;
  }
}
