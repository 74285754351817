﻿@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';


body {
  background-color: variables.$white;
}

/* select */

.custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: variables.$grey-500;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem (center / 8px) 10px no-repeat;
  border: 1px solid variables.$grey-200;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.fire-page .form-select {
  width: auto;
}

/*tool tip*/

.title-tooltip {
  width: 438px;
}

.tooltip-weather-div-2 {
  margin-top: 20px;
}

.title-tooltip-icon-2, space-left {
  margin-left: 10px;
}

/*editor*/

.tox-notifications-container {
  display: none !important;
}

/* Dialog */

.dialog-cleardata-container {
  min-width: 407px;
  max-width: 450px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .ms-Dialog-title {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: variables.$black;
    padding: 2rem 1.4rem 1.6rem 1.4rem;
  }

  .ms-Dialog-subText {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: variables.$black;
    padding: 2rem 1.4rem 1.6rem 1.4rem;
    padding: 0rem 0rem 0rem 0rem;
  }

  .ms-Dialog-actions {
    margin: 0px;

    .ms-Dialog-actionsRight {
      text-align: center;
      margin: 0px;

      .ms-Button {
        width: 75px;
        height: 23px;
        border: none;
        border-radius: 5px;
        background-color: variables.$wds-orange;
        padding: 0px;
      }

      .ms-Button-label {
        font-family: variables.$main-font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: variables.$black;
     
      }
    }
  }
}

.fire-page {

  .top-right-group {
    margin-left: auto;
  }

  .form-group-width-1 {
    width: 100px;
  }

  .noteworthyCheckboxLabel {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    margin-top: 12px;
  }

  .noteworthyCheckbox {
    margin-top: 17px;
  }

  .upload-perimeter {
    width: 380px;
  }

  .btn-update {
    position: absolute;
    right: 22px;
    margin-top: 15px;
  }
}

/* Coord Conversion */

.coordCallout {
  width: 473px;
  padding: 18px;

  .coordFooter {
    text-align: right;
  }
}

.duplicateFireCallout, .invalidCallout {
  width: 473px;
  padding: 18px;
  border: 3px solid red;
}

/*Map area*/
@media (max-width: 1440px) {
  .fde-name-map > div:first-child {
    width: 700px;
  }
}

@media (min-width: 1440px) {
  .fde-name-map > div:first-child {
    max-width: 50%;
  }
}

.fde-base-map {
  min-height: 342px;
  max-width: 50%;

  .azure-maps-drawtoolbar {
    display: none !important;
  }
}

.fde-map-control {
  padding: 5px 4px 0px 0px;
  position: absolute;
  right: 25px;
  text-align: right;
  z-index: 999;
}

.fde-dialog-map {
  .fde-map-control {
    right: 0px !important;
    margin-top: 40px !important;
  }

  .ms-MessageBar {
    position: absolute;
    bottom: 0;
    z-index: 1;
  }
}

.fde-map-measurementInfo {
  text-align: left;
  margin-top: 6px;
  z-index: 1;
  min-width: 126px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: variables.$black;
  background: variables.$white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 12px 11px 12px;
  white-space: nowrap;
  overflow: hidden;
}

.fde-dialog-map {
  width: 100% !important;
  height: 600px !important;
  margin: auto 29px;
  overflow: hidden;
  font-family: variables.$main-font-family !important;

  .fde-map-button {
    display: none;
  }

  .fde-map-measurementInfo {
    min-width: 176px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-right: 5px;
  }

  .fde-perimeter {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    height: 600px !important;
    display: flex;
  }

  .fde-perimeter-left {
    width: 244px;
    padding: 12px 14px 22px 13px;
    color: variables.$black;
    flex: none;
  }

  .fde-perimeter-right {
    flex: 1;
  }

  .fde-left-line {
    height: 1px;
    margin: 22.5px 31.5px 14.5px 0px;
    border-bottom: 1px solid variables.$grey-300;
  }

  .fde-perimeter-left ul {
    padding-left: 1rem !important;
  }

  .fde-left-des ul > li {
    line-height: 19px !important;
  }

  .fde-left-draw {
    .left-side-header {
      margin-bottom: 7px;
    }

    ul {
      list-style-type: decimal;

      > li {
        line-height: 30px !important;
      }
    }
  }
}

.fde-perimeter-left {
  .left-side-header {
    margin-bottom: 9px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  .left-side-header-small {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  .left-side-content-large {
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .left-side-content-small {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .fde-left-bottom {
    position: absolute;
    bottom: 24px;
  }
}

.scroll-content {
    max-height: 100%;
}

.fire-map-kpi-box {
    margin-bottom: 20px;
    @include mixins.kpi-box(25px);
  
    .fire-map-kpi-white-box {
      display: flex;
      flex-direction: column;
      @include mixins.kpi-white-box(25px);

      .kpi-red {
        color: red;
        font-weight: bold;
      }
    }
  
    .fire-map-kpi-metric {
      @include mixins.kpi-metric();
    }
  
    .fire-map-kpi-value {
      display: flex;
      flex-direction: column;
      display:inline-block;
      @include mixins.kpi-value();
    }
  
    .fire-map-kpi-change{
      display: flex;
      flex-direction: column;
      @include mixins.kpi-change();
    }
  
    .fire-map-kpi-title {
      font-weight:700;
      font-size:18px;
      margin-bottom:2px;
      max-width:180px;
      text-align:center;
      overflow:visible;  
      overflow-wrap:break-word;
    }
  }

  .fire-map-fire-name {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: variables.$black;
    height: 38px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 16px;
    margin-top: 30px;
  }