@use 'sass/abstracts/variables';

.nav-button {
  height: 21px;
  width: 21px;

  &:hover {
    color: variables.$black;
    background-color: transparent;
  }

  .ms-Icon {
    color: variables.$black;
    height: 21px;
    font-size: 12px;
    width: 21px;
    line-height: 1.8;
  }

  &.nav-disable .ms-Icon {
    color: darkgray;
  }
}

.page-button {
  color: variables.$black;
  font-weight: bold;
  cursor: pointer;

  &.page-current {
    color: orange;
  }
}
