@use 'sass/components/buttons';
@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

// Layers Panel
.sitmap-layers-panel {
  padding: 10px;
  overflow: hidden;
  height: 50vh;
  overflow: scroll;
  @include mixins.desktop {
    height: 100%;
    overflow: hidden;
  }
  
  .lp-layers-scroll-box {
    overflow: auto;
    height: calc(100% - 24px);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .lp-layers-outer, .lp-timeframe-outer {
    border-bottom: 2px solid variables.$grey-300;
    margin-top: 10px;
  }

  .lp-timeframe-outer {
    margin-bottom: 30px;
  }

  .lp-timeframe-wrapper {
    .lp-tf-number {
      font-size: 24px;
      color: variables.$wds-orange;
      background: variables.$grey-500;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .lp-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .lp-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 33.33%;
    margin: 5px 0px;
    text-align: center;

    .lp-border-box {
      border: 2px solid transparent;
      padding: 3px;
      margin-bottom: 8px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.selected {
        border: 2px solid variables.$green-200;
      }
    }
  }

  img, svg {
    max-width: 45px;
    max-height: 45px;
  }

  .lp-item span {
    font-size: 40px;
  }

  p {
    font-size: 14px;
  }

  .lp-client-pills {
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0;
    }

    .lp-client-pill {
      background-color: variables.$wds-orange;
      border-radius: 30px;
      padding: 4px 6px;
      margin: 4px 2px;
      display: flex;
      align-items: center;
      color: variables.$black;
    }

    .lp-remove-client {
      display: flex;
      align-items: center;
      margin-left: 4px;
    }
  }
  
}

.lp-close {
  @extend %cancel-icon;
  position: static;
  display: flex;
  justify-content: flex-end;
}

// Fire Details Panel
.fire-details-panel {
  max-height: 40vh;
  margin-bottom: 25px;
  .fdp-body {
    padding: 15px 30px;

    .fdp-body-top {
      text-align: center;
      margin-bottom: 20px;
    }

    .summary {
      margin-top: 30px;
    }

    .last-update {
      margin-top: 30px;
    }
  }

  .mgu-warning {
    color: variables.$red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    background-color: variables.$black;
    width: 300px;
    border-radius: 2px;

    p {
      margin: 0 0 0 5px;
    }
  }

  .fdp-footer {
    position: sticky;
    bottom: 25px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    @include mixins.desktop {
      // position: absolute;
      bottom: 15px;
    }
  }
}

.resource-details-panel {

  .rdp-body {
    padding: 15px 30px;

    .rdp-body-top {
      text-align: center;
      margin-bottom: 40px;
    }
  }
}

// Legend Panel

.legend-header {
  margin-left: 20px;
}

.section-divider {
  opacity: 1;
  border: 1px solid variables.$grey-400;
  margin-left: 10%;
  margin-right: 10%;
}
.grey-bg {
  background-color: variables.$grey-500;
  padding: 8px;
}
.fire-map-icon {
  width: 45%;
}
.icon-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .legend-icon {
    flex: 0 0 33.33%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    .square {
      width: 45px;
      height: 45px;
      border-style: solid;
      border-width: 10px;
      border-color: rgb(40,40,40);
    }
  }
}