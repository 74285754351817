@use 'sass/abstracts/variables';

.data-table .ag-header-cell-sorted-none {
  .ag-sort-none-icon {
    display: block !important;
  }

  .ag-icon-none:before {
    content: '\2193 \2191' !important;
    margin-top: -4px;
    display: block;
  }
}

.export-btn button.ms-Button .ms-Button-flexContainer {
  flex-direction: row-reverse;

  .ms-Button-label {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
}

.ellipsis-menu.ms-ContextualMenu {
  .ms-ContextualMenu-link {
    padding: 0 5px;
  }

  .ms-ContextualMenu-itemText {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    padding-left: 5px;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }
}
