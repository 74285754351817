@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.custom-map-controls {
  position: absolute;
  right: 0px;
  margin-right: 10px;
  bottom: 197px;
  transition: right .3s ease-in-out;

  .custom-control {
    background-color: variables.$white;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 18px;
    margin-bottom: 5px;
    cursor: pointer;

    &.legend-control {
      transform: rotate(45deg);
    }
  }

  @include mixins.desktop {
    bottom: 145px;

    &.panel-show {
      right: 500px;
    }
  }
}