﻿@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';
@use 'sass/components/blackHeaderModule';

.fire-page-main-container {
  height: calc(100% - 40px);
  overflow: hidden;
  
  .fp-header-left {
    display: flex;
    align-items: center;

    .fp-header-info {
      margin-left: 15px;
      
      @include mixins.desktop {
        margin-left: 40px;
      }
    }

    p {
      margin: 0;
    }
  }

  .fp-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 auto;
    padding-bottom: 5px;

    .fp-old-perimeter {
      display: flex;
      .desktop-warning {
        display: none;
      }
    }

    @include mixins.desktop {
      flex: 0 0 32%;
      padding-bottom: 0;
      .fp-old-perimeter {
        .desktop-warning {
        display: block;
        }
      }
    }

    .fp-header-button {
      flex: 0 1 100px;

      @include mixins.desktop {
        flex: 0 1 auto;
      }
    }
  }

  .page-full-size-map-fp {
    height: calc(100% - 131px);
    
    @include mixins.desktop {
      height: calc(100% - 95px);

    }
  }

  .policy-synopsis-wrapper {
    margin: 15px 5px;
  }
  
  .policies-body {
    @extend %orange-border-no-top;
    padding: 10px 5px;
  }

  .policies-header {
    @extend %black-header;
  }

  .mobile-table-wrapper {
    color: variables.$black;
  }

  .fire-page-grid {
    margin-bottom: 15px;
    height: 100px;

    &.active-policies {
      height: auto;
    }

    .ag-header-cell-label .ag-header-cell-text {
      font-size: 12px !important;
    }

    .ag-overlay-no-rows-wrapper {
      padding-top: 30px;
    }
  }

  .details-panel {
    height: calc(100% - 150px);
    @include mixins.desktop{
      height: 100%;
    }
    .property-detail-panel {
      overflow: hidden;
      height: 100%;
    }

    .pdp-scroll-box {
      overflow: auto;
      height: calc(100% - 24px);

      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    .dp-header {
      color: variables.$white;
    }
    &.dp-hide {
      bottom: calc(60vh * -1 + variables.$sidebar-height-mobile);
    }
  }

  .activity-timeline-wrapper{
    margin: 15px 5px;
    padding-bottom: 25px;
    .timeline-header {
      @extend %black-header;
    }

    .expand-toggle {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
    }

    &.timeline-section {
      width: calc(100% - 10px);
    }
  }
}