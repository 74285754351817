@use 'sass/abstracts/variables';

.phahome {
  background: variables.$white;
}

.ms-Fabric--isFocusVisible [class^='input-']:focus + label::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  border: 1px solid black;
}

.titlerow {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: variables.$black;
  height: 68px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
}

.engine-time-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 30px 0px 30px;
}

.panelleft {
  flex-grow: 1;
  overflow: hidden;
  background-color: var(--ag-background-color, #fff);
  position: relative;
  min-width: 50%;
}

.panelright {
  min-width: 50%;
  flex-grow: 1;
  padding-left: 15px;
  font-weight: 600;
}

.engine-time-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 30px;
}

.new-pha-button {
  background: variables.$wds-orange;
  padding: 2px 10px;
  cursor: pointer;

  span {
    font-weight: bold;
  }
}

.scroll-content {
  max-height: 100%;
}

.billable-table .ag-paging-panel {
  height: 0px;
  font-size: 0px;
  visibility: hidden;
}

.billable-grid, .billable-comments {
  width: 50%;
}
