@use 'sass/abstracts/variables';

.resourcestatus-layer-wrap {
  height: 35px;

  .dp-header {
    justify-content: center;
    padding: 0 20px;
  }

  .layer-chbi {
    .ms-Checkbox .ms-Checkbox-text {
      font-size: 14px;
    }

    .chbi-prefix {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .dp-layer {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.layer-wrap.resourcestatus-layer-wrap {
  .dp-layer {
    .sub-item {
      padding-left: 22px;
    }

    li:first-child {
      margin-bottom: 20px !important;
    }
  }

  .layer-section {
    padding-top: 3px;
    margin-bottom: 0px !important;
  }

  .dp-layer .layer-section {
    &:not(.layer-section ~ .layer-section) {
      margin-top: 20px !important;
      height: 23px;
    }

    &:last-child {
      margin-bottom: 10px !important;
    }
  }
}
