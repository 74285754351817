﻿@use 'sass/abstracts/variables';

.sitm-btn-smokecheck {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  height: 23px;
  width: 105px;
  margin-top: 6px;
  margin-right: 22px;
}

.smoke-check-callout {
  padding: 5px 20px 20px;
  width: 450px;
  
  .ms-Callout-beakCurtain {
    background: variables.$grey-200;
  }
}
