@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';
@use 'sass/components/buttons';


// Layers Panel
.layers-panel {
  padding: 10px;
  overflow: hidden;
  height: 50vh;
  overflow: scroll;
  @include mixins.desktop {
    height: 100%;
    overflow: hidden;
  }
  
  .lp-layers-scroll-box {
    overflow: auto;
    height: calc(100% - 24px);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .lp-layers-outer, .lp-timeframe-outer {
    border-bottom: 2px solid variables.$grey-300;
    margin-top: 10px;
  }

  .lp-basemap-outer {
    margin: 10px 0;
  }

  .lp-timeframe-outer {
    margin-bottom: 30px;
  }

  .lp-timeframe-wrapper {
    .lp-tf-number {
      font-size: 24px;
      color: variables.$wds-orange;
      background: variables.$grey-500;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .lp-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .lp-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 0 0 33.33%;
    margin: 5px 0px;

    .lp-border-box {
      border: 2px solid transparent;
      padding: 3px;
      margin-bottom: 8px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.selected {
        border: 2px solid variables.$green-200;
      }
    }

    p {
      text-align: center;
    }

    &.property-icon {
      svg {
        g {
          stroke: variables.$white;
        }
        path {
          fill: variables.$white;
        }
        line {
          stroke: variables.$white;
        }
      }
      
    }
  }

  img, svg {
    max-width: 45px;
    max-height: 45px;
  }

  .lp-item span {
    font-size: 40px;
  }

  p {
    font-size: 14px;
  }

  .lp-client-pills {
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0;
    }

    .lp-client-pill {
      background-color: variables.$wds-orange;
      border-radius: 30px;
      padding: 4px 6px;
      margin: 4px 2px;
      display: flex;
      align-items: center;
      color: variables.$black;
    }

    .lp-remove-client {
      display: flex;
      align-items: center;
      margin-left: 4px;
    }
  }
}

.lp-close {
  @extend %cancel-icon;
  position: static;
  display: flex;
  justify-content: flex-end;
}