@use 'sass/abstracts/variables';
.mh-layer-wrap {
    height: 35px;
}

.mh-dp-layer {
    max-height: 300px;
    overflow-y: auto;
}

.mh-layer-wrap .dp-header {
    padding-left: 20px;
    padding-right: 20px;
}

.layer-wrap.mh-layer-wrap .dp-layer .sub-item {
    padding-left: 0px;
}
