@use 'sass/abstracts/variables';

.program-use-container {
  .form-group {
    margin-right: 10px;
  }

  .pl-dropdown .ms-Dropdown-container {
    min-width: 0;
  }
}

.pu-client, .pu-state, .pu-action {
  width: 131px;
}

.pu-from, .pu-to {
  width: 145px;
  position: relative;
}

.pu-query-to-error {
  position: absolute;
  left: 0;
  top: 100%;
  color: variables.$red;
  font-size: 14px;
  white-space: nowrap;
}

.pu-type {
  width: 183px;
}

.pu-status {
  width: 206px;
}

.ms-Dropdown-items .ms-Dropdown-item:first-child {
  min-width: initial;
  max-width: initial;
}

.export-pdf {
  position: absolute;
  right: 10px;
  width: 20px;
  bottom: 100%;
  margin-bottom: 10px;
}
