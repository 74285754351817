@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.action-sidebar-panel {
  width: 100vw;
  background: variables.$black-alpha;
  transition: bottom .3s ease-in-out;
  position: absolute;
  bottom: calc(variables.$panel-height-mobile * -1 + variables.$sidebar-height-mobile);
  border-radius: 10px 10px 0 0;
  color: variables.$white;
  height: variables.$panel-height-mobile;
  
  &.asp-show {
    bottom: variables.$sidebar-height-mobile;
  }
  
  &.asp-hide {
    bottom: calc(variables.$panel-height-mobile * -1 + variables.$sidebar-height-mobile);
  }

  @include mixins.desktop {
    height: 100%;
    transition: all .3s ease-in-out;
    top: 0px;
    left: calc(-500px + 90px);
    border-radius: 0 10px 10px 0;
    width: 400px;
    max-width: 100%;

    &.asp-show {
      left: 90px;
      height: 100%;
    }

    &.asp-hide {
      left: calc(-500px + 90px);
      height: 100%;
    }

    .asp-stats {

      // .disp-container {
      //   display: block;
      //   align-items: center;
      //   border-bottom: 2px solid variables.$grey-400;
      //   display: flex;
      //   flex-direction: column;
  
      //   .difference {
      //     margin: 0;
      //     text-align: center;
      //   }
      // }
      .figure {
        text-align: center;
        display: block;
        margin-top: 0;
      }
    }
  }
}