@use 'sass/abstracts/variables';
.map-actions {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
}

.wh-sltr .sltr-text {
    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: variables.$dropdown-font-size;
    line-height: variables.$dropdown-line-height;
}