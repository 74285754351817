@use "sass/abstracts/variables";
@use "sass/components/buttons";
@use 'sass/abstracts/mixins';
@use 'sass/components/blackHeaderModule';

/* Property Search Page */
.psp-border-yellow {
  border: 2px solid variables.$wds-orange;
  box-sizing: border-box;
  border-radius: 5px;
}

.psp-header-info {
  margin-top: 25px;
  height: auto;
  display: flex;
  padding-bottom: 15px;

  .search-left {
    flex: 1;
    height: auto;
    flex-grow: 1;
  }

  .search-right {
    display: flex;
    align-items: center;
    margin: 17px 24px 0 0;
  }
}

// .inactive {
//     pointer-events: none;
//     cursor: default;
// }

.psp-header-info {
  .search-form-title {
    line-height: 24px;
    margin-top: 14px;
    margin-left: 26px;
    font-weight: bold;
    font-size: 20px;
  }

  .search-form-items {
    line-height: 19px;
    margin-top: 14px;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .search-form-item {
    padding: 7px 39px 16.5px 39px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    border-right: 1px solid variables.$black;
    min-width: 230px;
    max-width: 20%;

    &:first-child {
      padding: 7px 49px 16.5px 29px;
      flex: 2;
      border-right: 1px solid variables.$black;
    }

    &:last-child {
      flex: 2;
      border-right-width: 0px !important;
      max-width: 25%;
    }

    &.search-form-second-row {
      flex: 1;
      max-width: 304px;
      border-right-width: 0px !important;
    }

    .wds-dropdown-container {
      margin: 10px 0 0;
    }
  }
}

.psp-dropdown .ms-Checkbox-label {
  .ms-Checkbox-checkbox {
    border-color: variables.$black !important;
  }

  .ms-Dropdown-optionText {
    color: variables.$black !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
}

.psp-header-info .search-input {
  margin-top: 10px;
  height: 36px;
  background: variables.$white;
  border: 1px solid variables.$grey-300;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding-left: 13px;
}

.psp-header-info .search-input {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: variables.$black;
    opacity: 1;

    /* Firefox */
    /*font-weight: bold;*/
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: variables.$black;

    /*font-weight: bold;*/
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: variables.$black;

    /*  font-weight: bold;*/
  }
}

.psp-bottom-grid {
  margin-top: 21px;

  .ag-header-container {
    height: 39px !important;
    border-radius: 5px 5px 0px 0px;
  }

  .ag-header-row.ag-header-row-column {
    height: 39px !important;
  }

  .ag-cell {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    flex: 1;
  }

  .ag-row-selected {
    background-color: variables.$black !important;
    color: variables.$white !important;
  }

  .new-property-button-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
  }

  .new-property-button {
    background: variables.$wds-orange;
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 5px;

    span {
      font-weight: bold;
    }
  }
}

.psp-right {
  margin-top: 21px;
  flex: 1;
}

.dialog-map .psp-right {
  margin: 0px !important;
  padding: 0px !important;
  max-width: 100% !important;
}

.psp-right {
  .header {
    background: variables.$wds-orange;
    border-radius: 5px 5px 0px 0px;
  }

  .tab {
    height: 36px;
    background: variables.$black;
    border: 3px solid variables.$wds-orange;
    border-bottom: none;

    .tab-button {
      height: 36px;
      color: variables.$white;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: -7px;
      margin-top: 7px;

      &:first-child {
        margin-left: 17px;
      }
    }

    .tab-active {
      background: variables.$white;
      color: variables.$black;
    }
  }

  .border-black {
    border: 1px solid variables.$black;
    border-top-width: 0px;
    box-sizing: border-box;
  }

  .border-orange {
    border: 3px solid variables.$wds-orange;
    border-top-width: 0px;
    box-sizing: border-box;
  }

  .property-empty {
    width: 100%;
    text-align: center;
    line-height: 50px;
  }

  .property-info {
    height: 61px;
    padding: 6px 6px 0px 16px;
    display: flex;

    .detail {
      flex: 1;
      padding-top: 5px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: variables.$black;
    }

    .btn-wds-lob {
      height: 20px;
      color: variables.$white;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      border-radius: 5px;
      cursor: default;
      padding: 0px 20px;
    }

    .btn-residential {
      background: variables.$grey-500;
    }

    .btn-commercial {
      background: variables.$red;
    }

    .btn-agricultural {
      background: variables.$magenta;
    }
  }

  .property-empty .btn-mgu {
    background: variables.$wds-orange;
  }

  .property-info .btn-status {
    height: 20px;
    background: variables.$white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    cursor: default;
    padding: 0px 10px;
  }

  .content {
    padding: 13px 11px;
  }

  .content-header {
    height: 36px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: variables.$black;
    padding-left: 14px;
    padding-top: 7.58px;
  }

  .content-address {
    height: 184px;
    margin-bottom: 13px;
    padding: 23px 31px 28px 32px;
    display: flex;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;

    div {
      flex: 2;
      margin-right: 8px;

      &:last-child {
        flex: 1;
        margin-right: 0px;
      }

      > input {
        margin-top: 13px;
        margin-bottom: 8px;

        &:last-child {
          margin-top: 6px;
        }
      }
    }
  }

  .content {
    input {
      height: 36px;
    }

    textarea {
      height: 99px;
    }

    input,
    textarea {
      border: 1px solid variables.$grey-300;
      background: variables.$white;
      box-sizing: border-box;
      width: 100%;
      padding: 9px 13px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: variables.$black;
    }

    input:read-only,
    textarea:read-only {
      background: rgba(196, 196, 196, 0.2);
    }

    .content-row {
      display: flex;
      margin-bottom: 8px;

      div {
        margin-right: 8px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .ms-Checkbox.is-checked .ms-Checkbox-checkmark {
      height: 14px;
      width: 14px;
      background-color: #000;
      color: #000;
    }

    .ms-Checkbox-label .ms-Checkbox-checkbox {
      border-color: variables.$black !important;
    }

    .ms-Checkbox-checkbox {
      background-color: #fff;
      border-color: variables.$grey-300;
      border-radius: 0;
      margin-right: 13px !important;
    }

    .ms-Checkbox-text {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: variables.$black;
    }
  }

  .content-policy {
    height: 317px;
    padding: 23px 31px 28px 32px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
  }





  .enrollment-access-flex {
    display: flex;
    justify-content: space-around;

    & > div {
      flex: 1;
    }
  }
}

.content-map-adjustments {
  border: 2px solid variables.$grey-300;
  padding: 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: variables.$black;
  background-color: variables.$white;
  .tip {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: variables.$red;
  }

  .tip-error {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: variables.$red;
    padding-right: 20px;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
  }

  .content-row > div {
    margin-right: 21px;
  }

  .text-end {
    margin-bottom: 20px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 15px;
    gap: 15px;

    div {
      margin-bottom: 10px;
    }

    input {
      height: 40px;
      padding-left: 10px;
      width: 75%;
    }

    .or-unmatched {
      margin-top: 35px;
      margin-bottom: 0px;
      display: flex;
      gap: 30px;
    }
  }
}

.content-access {
  line-height: 17px;

  .tab-access-wrapper {
    border: 2px solid variables.$grey-300;
    padding: 15px;
    background-color: variables.$white;
  }

  .tip {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: variables.$red;
  }

  .content-row {
    margin-bottom: 13px !important;
    display: flex;

    .verified-checkbox {
      width: 50%;
      margin: auto;
      margin-left: 25px;
    }

    .first-tip {
      width: 50%;
    }

    .gate-code-label {
      width: 100%;
    }

    textarea {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
}
.access-header {
  @extend %black-header;
  .section-header-access {
    margin: 0;
  }
    .expand-icon {
      color: variables.$wds-orange;
      font-size:32px;
      font-weight:bolder;
      margin-right: 25px;
      cursor: pointer;

      @include mixins.desktop {
        display:none;
      }
    }
}

.timeline-section {
  margin-top: -25px;
  @include mixins.desktop {
    width: 33%;
    margin-top: 0px;
  }
  .timeline-header {
    @extend %black-header;
    padding-left: 30px;
    .section-header-timeline {
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
    }
  }

  .timeline-content {
    margin-top: 0px;
    padding-top: 5px;
    .expand-toggle {
      display:flex;
      justify-content: flex-end;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}



.chip-section {
  background-color: variables.$grey-200;
  color: variables.$black;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  .chip-header {
    display: flex;
    justify-content: space-between;

    .chip-icon {
      font-size: 24px;
      background-color: variables.$black;
      color: variables.$white;
      border-radius: 50%;
      text-align: center;
      width: 45px;
      height: 45px;
      padding: 8px;
      margin-top: 5px;
      margin-left: 10px;
      margin-right: 15px;
      
      &-triangle {
        @extend .chip-icon;
        size: 50%;
        padding-bottom: 10px;
        padding-top: 6px;
        padding-left: 8px;
      }
    }

    .header-title {
      margin-top: 5px;
    }

    .chip-button {
      color: variables.$wds-orange;
      font-size:32px;
      font-weight:bolder;
      margin-top: 15px;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  .chip-content {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.35s;
  }

  .c-show {
    max-height: 1000px;
  }

  .c-hide {
    max-height: 0px;
  }
}

.enrollment-container, .call-container, .threat-container, .photos-container {
  display: block;

  .horizontal-sect {
    display: flex;
    gap: 10px;

    &-block {
      @extend .horizontal-sect;
      display: block;
    }
    .sect-header {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .image-area {
    display: flex;
    .sub-photos {
      display: block;
      width: 25%;

      .photo-vert {
        position: relative;
        background: variables.$white;
        &:hover {
          background: variables.$grey-200;
        }
        &:active {
          background-color: variables.$grey-400;
        }
        cursor: pointer;
        padding: 5px;
        border: 1px solid;
        border-color: variables.$grey-300;
        .shadow-box {
          display: none;
        }
        &:last-child{
          .shadow-box {
            display: block;
            position: absolute;
            cursor: pointer;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
              margin:auto;
            }
            background-color: variables.$black-alpha;
            &:hover {
              background-color: variables.$grey-400;
            }
            &:active {
              background-color: variables.$black-alpha;
            }
            color: variables.$white;
          }
        }
      }
    }
    .main-photo {
      width: 75%;
      padding: 5px;
      border: 1px solid;
      border-color: variables.$grey-300;
      background: variables.$white;
      &:hover {
        background: variables.$grey-200;
      }
      &:active {
        background-color: variables.$grey-400;
      }
      cursor: pointer;
    }
    .only-photo {
      @extend .main-photo;
      width: 100%;
    }
  }
}
.photo-modal-content {
  background-color: variables.$white;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 750px;
  .photo-change {
    cursor:pointer;
    margin-top: 43%;
    font-size: 24px;
    margin-right: 10px;
    margin-left: 10px;
    color: black;
    vertical-align: middle;
    color: variables.$wds-orange;
  }
  .just-image {
    width: auto;
    margin: 20px;
    img {
      max-height: 700px;
      width: 100%;
      align-self: center;
    }
  }
}

.dialog-map .psp-right .map {
  height: 475px !important;
  width: 766px !important;
  margin: 0px !important;

  .azmaps-map-fullscreen-collapse,
  .azmaps-map-fullscreen-expand {
    padding: 6px !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFvSURBVHgB3VNRTsMwDLXTDfE5bpAbbJyA7X8COu1/HIEjcAPYDdj3pHWDSXxuOwHlBJQTUP4QIzH20pC2H6jfRIri2H6x/WLjarWeWAvXAPtBHMc5NFhJknQA2hul4E4ZAwPW9UThDM3AgpHAKMrFYn2PCBMWU8kE4LhjzL43Hp8vxT6fP/ZbLcXZfWUe7H2VOIxGwysimPlMAOwmitSFj8qp9gFoUwdLyco7VR8hzfJJeEB1+ejUwQdbqO1JI+KZv3NJ3VA5lbnRAEfaX1pB/62ZlBkisRF1EdHBiYSrF5ZSFjMiq10m/2KhF5Lk4ZI55a/Dgm3K43h46mzrdz4y3sJ8aozd+R4pkdhmUuytfKHcmLhdKQ4DqVdcdBSZqbeo4PQpTr+zwF/6GmwVxvNihwfK/e2aCTNr6c07cTYfRQkp1OZG1cHSkawecPtuQzZ26WZEdvURZIKeq+C/Vy3gikvAaVOwLDcDh0y2zNPND57Us8EOik56AAAAAElFTkSuQmCC) !important;
    background-size: 16px !important;
  }
}
  .map {
    height: 262px;
    margin-bottom: 8px;

    .subcontrol-container {
      margin-right: 0px !important;
    }

    .azure-maps-control-button {
      margin-bottom: 6px !important;
    }

    .azmaps-map-fullscreen-expand,
    .azmaps-map-fullscreen-collapse {
      padding: 6px !important;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADXSURBVHgB1ZLLCcJAEIZn8oActwPtwJSgdxE3pB/tQKxkDeg5sQNLsAPjOWzGWXchOQScHP1hDj/sfvNEY64vAHhytBwPa/t7We4uIFQCgPyR8uCXcWzPMEORyzrybQg5gIje4FtwIK4krY0xSgxA7LnfbuNjPgTHxn9KawchgqootvtfgGhstNZtqKRBxCP8hVDyyJgbD5ZO3J72bQ6KQKYDx3pqO8IKhu3Ad9Vu0JmytstFgGkIsqdGDJiAgLsV6QyCMuUzeyHCKoFZ6vhCsQoVKD62xQde8FC77C3bjwAAAABJRU5ErkJggg==),
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADfSURBVHgBzVLLDcIwDH3pB3HsCN2AjkDPHGhGYQQm6AgsUCmiC7BCRigblBtSRYNNilS1hyScsBQljvSen58tEBBKtRUQHQGRUVoAJksQFKkGxpqA+fShIwTFsydw/82MMQ9vBUopkp3e8JEObdswWvwGHkpgS39D4akgVXOwlJLb4NN5eiBOxuA6A/9RrEy0hm0u9DxLedAugmQNZrcNG8bbVjoJmqat4vjFlfrFqCQ8IokisSdgbQlWo3ITCCF2dOdTrkNHxXuQzfJskbsVcHVakrutPvbjGHMbnS/BG0TDTfhgXP1gAAAAAElFTkSuQmCC) !important;
      background-size: 16px !important;
    }

    .bottom-right {
      display: none !important;
    }
  }
.map-coordinates-wrapper{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  @include mixins.desktop {
    width: 45%;
    margin: 0;
  }
  .content-map-coordinates {
    padding: 25px 14px 0px 34px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
    border: 2px solid variables.$grey-300;
    
    .content-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 15px;
      gap: 15px;

      .map-sub-title {
        margin-bottom: 10px;
      }

      input {
        height: 40px;
        padding-left: 10px;
        width: 75%;
      }
    }

    .content-header {
      margin-bottom: 15px;
    }
  }
}
.tab-services-outer-wrapper {
  .tab-services-wrapper {
    border: 2px solid variables.$grey-300;
    padding: 14px;
    background-color: variables.$white;
  }
}

.tab-call-log-wrapper {
  border: 2px solid variables.$grey-300;
  background-color: variables.$white;
  padding: 14px;
}

/* Property Profile Page */

.synopsis-header-desktop {
  display: none;
}

.active-desktop {
  display: block;
  margin-left: 10px;
  padding-top: 10px;
}

.section-header-desktop {
  @extend %black-header;
  .close-button {
    float: right;
    cursor: pointer;
    margin-right: 30px;
  }
}

.tab-call-log-form {
  display: flex;
  justify-content: space-between;
}

.call-log-notes {
  flex: 2;
  margin-left: 20px;
  height: 250px;
  width: 150px;
}

.call-log-fire {
  flex: 1;
}

.call-log-container {
  display: block;

  @include mixins.desktop {
    display: flex;
    justify-content: space-between;
    margin-left: 45px;
    margin-right: 45px;
  }
}

.parent-container-ppp {
  @include mixins.desktop {
    display: flex;
    justify-content: flex-start;
  }
  .mobile-spacing {
    margin-top: 15px;
    color: variables.$white;
    @include mixins.desktop {
      display: none;
      margin-top: 0px;
    }
  }
  // .ppp-sidebar {
  //   background: variables.$black;
  //   width: 100%;
  //   position: fixed;
  //   bottom: calc(variables.$panel-height-mobile * -1 + variables.$sidebar-height-mobile);
  //   z-index: 1;
  //   color: variables.$white;
  //   height: variables.$panel-height-mobile + 2.25vh;
  //   display: flex;
  //   justify-content: space-around;

  //   &:first-child {
  //     padding-top: 25px;
  //   }

  //   .ppp-sidebar-icon {
  //     cursor: pointer;
  //     color: variables.$white;
  //     text-align: center;
  //     text-wrap: none;
  //     padding-top: 10px;
  //     padding-bottom: 10px;
  //     font-size: 14px;
  //     width: 33.3%;

  //     @include mixins.desktop {
  //       width: 100%;
  //     }
  //     .ppp-icon {
  //       width: 45px;
  //     }
  //   }

  //   &-fixed {
  //     @extend .ppp-sidebar;
  //   }
  
  //   @include mixins.desktop {
  //     height: 100%;
  //     position: absolute;
  //     margin-top: 108px;
  //     top: 0;
  //     left: 0;
  //     width: 75px;
  //     display:block;

  //     &-fixed {
  //       background: variables.$black-alpha;
  //       position: fixed;
  //       top: 0;
  //       left: 0;
  //       width: 100px;
  //       margin-top: 0px;
  //       height: 100%;
  //       color: variables.$white;
  //     }
  //   }
  // }
}

// .ppp-sidebar-icon {
//   cursor: pointer;
//   color: variables.$white;
//   display: block;
//   text-align: center;
//   text-wrap: none;
//   padding: 10px;
//   font-size: 14px;

//   .ppp-icon {
//     width: 50%;
//   }
// }

// .ppp-active-icon {
//   background-color: white;
//   color: variables.$black;
//   .ppp-icon-label {
//     color: variables.$black;
//   }
//   .ppp-icon {
//     filter: invert(100%);
//   }
// }
.main-container-ppp {
  @include mixins.desktop {
    width: 66%;
  }
}
.tab-call-log-grid {
  width: 100%;


  @include mixins.desktop {
    width: 50%;
  }
  .call-log-header {
    @include mixins.desktop {
      margin-left: 13px;
      width:97%;
    }
  }

}

.adjustments-section {
  display: none;

  @include mixins.desktop {
    display: block;
    margin-bottom: 100px;
  }
}

.enrollment-access-map-section {
  display: block;
  margin-bottom: 45px;

  @include mixins.desktop {
    margin-left: 15px;
    margin-right: 15px;
  }

  .al-content {
    padding-top: 15px;
    margin-top: 0px;
    .not-table {
      margin-left: 15px;
      margin-right: 15px; 
    }
    .important-header {
      font-weight: bold;
      text-decoration: underline;
    }
    .info-section {
      display: block;
      
      &:first-child {
        margin-top: 25px;
      }
      margin-bottom: 25px;

      p:not(.down-below) {
        display: inline;
        margin-right: 10px;
      }
    }
  }
}

.tab-call-log-export {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 10px;
  flex: 1;
}

.export-icon {
  color: variables.$green-500;
  font-size: 30px;
  cursor: pointer;
}

.notes-custom-header {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.notes-header-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.call-log-gate-code-warning {
  color: variables.$red;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }
}

#anchor-visits {
  scroll-margin-top: 160px;
}

#call-log {
  scroll-margin-top: 160px;
}

#coordinates {
  scroll-margin-top: 160px;
}

.call-log-visits-wrapper {
  border: 2px solid;
  border-color: variables.$grey-300;
  .call-log-visit-wrapper {
    border-bottom: 3px solid variables.$wds-orange;
    padding-bottom: 20px;

    .call-log-comments-photos {
      margin-top: 30px;

      & > div {
        flex-basis: 50%;
      }
    }

    .call-log-visit-comments {
      width: 50%;
    }
  }
}

.scroll-content {
  max-height: 100%;
}

.wtf-psp-wrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .psp-bottom-grid {
    width: 100%;
  }

  .psp-right {
    width: 100%;
    margin-left: 0;
  }
}

.active-incidents-block {
  display: none;
}

@include mixins.desktop {
  .active-incidents-block {
    display: block;
  }
}

.header-left {
  display: flex;
  .pph-title-box {
    line-height: 1em;
    margin-top: 16px;
    margin-left: 15px;
  }
}

.property-profile-page {
  padding: 0 30px 0;

  .page-title {
    display: none;
    @include mixins.desktop {
      display: flex;
    }
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: variables.$black;
    height: 68px;
    align-items: center;
  }

  .ppp-header {
    display: flex;
    min-width: 0;
    justify-content: space-between;
    margin-bottom: 10px;
    @include mixins.desktop {
      margin-bottom: 0px;
    }
  }



  .ppp-right-header {
    display: flex;
    gap: 15px;
    .multi-action-button {
      display: none;
      @include mixins.desktop {
        display: block;
      }
      background-color: variables.$wds-orange;
      padding-left: 25px;
      padding-right: 25px;
      height: 32px;
      margin-top: 20px;
      border-radius: 2px;
    }
  }

  .ppp-follow {
    margin-right: 15px;
    margin-top: 20px;
    min-width: 0;
    button {
      border: none;
      color: variables.$white;
      width: 120px;
    }
    .following {
      background: variables.$grey-400;
    }

    .not-following {
      background: variables.$blue;
    }
  }

  .property-profile-header {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
  }

  .pph-right {
    display: flex;
  }

  .ppp-info-mobile-header {
    position: fixed;
    top: 0;
    background-color: white; 
    margin-right: 10px;
    margin-left: -10px;
    padding-top: 10px;
    width: 100%;
    z-index: 1;

    .ppp-follow {
      position: absolute;
      bottom: 20px;
      right: 40px;

      @include mixins.desktop {
        position: relative;
        bottom: 0;
        right: 0;
        margin: 0;
        margin-top: 20px;
      }
    }
  }

  .info-and-button-container {
    display: flex;
    justify-content: space-between;
  }

  .pph-left {
    display: flex;

    #pre-plan-file-upload {
      display: none;
    }

    .pre-plan-link {
      margin-left: 30px;
    }
  }

  .grid-wrapper {
    padding: 8px;
    
    .ag-root-wrapper .ag-header {
      background-color: variables.$wds-orange;
      border-radius: 5px 5px 0 0;
    }

    & > :nth-child(2) {
      margin-bottom: 10px;
    }
  }
}

.popover-content-container {
  display: flex;
  justify-content: space-evenly;
  background-color: variables.$grey-200;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 2px solid;
  border-color: variables.$wds-orange;
  min-width: 300px;

  .action-item {
    display: flex;
    gap: 5px;
  }

  .center-header {
    text-align: center;
  }
  .popover-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .popover-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .modal-button {
    display: block;
    width: 100px;
    height: 25px;
    white-space: nowrap;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  button, a {
    @extend .modal-button;
  }
  .fp-actions-right {
    button, a {
      width: 140px;
      display: flex;
    }
  }
  .vert-bar{
    margin: 0px 15px 0px 15px;
    width: 2px;
    border: 1px solid;
    height: 170px;
    border-color: variables.$wds-orange;
  }

  .pre-plan-custom-file-upload {
    @extend .btn-base-primary;
    @extend .modal-button;
    height: 25px;
    line-height: 2.1em;
  }

  #pre-plan-file-upload {
    display: none;
  }
  .link-container {
    @extend .modal-button;
    background-color: variables.$wds-orange;
    text-align: center;
    cursor:pointer;
    &:hover {
      background-color: variables.$wds-hover-orange;
    }
    .link-as-button {
    text-decoration: none;
    color: variables.$black;
    font-size: 12px;
    font-weight: bold;
  }
  }

}

.unfollow-modal {
  width: 400px;
}

.action-modal {
  background-color: transparent;
}

.unfollow-modal-content {
  padding: 0 24px 24px 24px;
  border-top: 8px solid variables.$wds-orange;
}

.unfollow-modal-heading {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 14px 0;
}

.unfollow-modal-close {
  color: variables.$wds-orange;
}

.pp-top-section {
  display: block;

  .policy-synopsis-wrapper {
    @include mixins.desktop {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .active-incidents-wrapper {
    display: block;
    gap: 100px;
    margin-left: 15px;
    margin-right: 15px;
    
    .active-incidents-flex {
      display: block;
      border-top: none;

      .ai-subheader {
        margin-left: 10px;
        margin-bottom: 15px;
        padding-top: 15px;
      }
    }

    .active-incidents-map {
      height: 360px;
      flex: 1 0 225px;
      padding: 8px;
      margin-left: 10px;
      margin-right: 10px;
      background-color: variables.$black;
    }

    .active-incidents-grid {
      flex: 1 0 250px;
      border: none;

      .ag-overlay {
        position: relative;
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .ag-header {
        border-radius: 8px 8px 0px 0px;
      }
    }
  }

  .active-incidents-legend {
    display: flex;
    background-color: variables.$black;
    justify-content: center;
    padding: 4px 0;
    margin-left: 10px;
    margin-right: 10px;
    & > div {
      display: flex;
      align-items: center;
      color: variables.$white;
      margin-right: 8px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.border-box-no-top {
  @extend %orange-border-no-top;
}


.mobile-disappear {
  display: none; 

  @include mixins.desktop {
    display: block;
  }
}

.pp-general-section {
  margin-top: 20px;
}