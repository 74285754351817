@use 'sass/abstracts/variables';

.ms-Callout.ms-Dropdown-callout .ms-Callout-main {
  border: 1px solid variables.$grey-300;
}

.ms-Callout-main .ms-Dropdown-item {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;

  font-size: variables.$dropdown-font-size;
  line-height: variables.$dropdown-line-height;
  color: variables.$black;
  background-color: transparent;

  .ms-Dropdown-optionText {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;

    font-size: variables.$dropdown-font-size;
    line-height: variables.$dropdown-line-height;
    color: variables.$black;
    background-color: transparent;
  }
}

select > option {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;

  font-size: variables.$dropdown-font-size;
  line-height: variables.$dropdown-line-height;
  color: variables.$black;
  background-color: transparent;
}

.ms-Callout-main {
  .ms-Checkbox-checkbox {
    border-width: 1px;
    background-color: transparent !important;
    border-color: #000 !important;
    height: 14px;
    width: 14px;
    border-radius: 0;
  }

  .ms-Checkbox.is-disabled .ms-Checkbox-checkbox {
    border-color: variables.$grey-300 !important;
  }

  .ms-Dropdown-item {
    .ms-Checkbox-checkbox {
      .ms-Checkbox-checkmark {
        display: none;
      }

      &::after {
        background-color: #fff;
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;
        display: block;
        content: "";
      }
    }

    &.ms-Checkbox.is-checked {
      .ms-Checkbox-checkbox::after {
        background-color: #000;
      }

      &.is-disabled .ms-Checkbox-checkbox::after {
        background-color: variables.$grey-300;
      }
    }
  }
}

.add-columns .ms-Dropdown-container {
  width: 15rem;
}

.map-control-wrap .ms-Callout-main {
  border: none;

  background-color: variables.$dropdown-background !important;

  .ms-Dropdown-item {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;

    font-size: variables.$dropdown-font-size;
    line-height: variables.$dropdown-line-height;
    color: variables.$white;
    background-color: transparent;

    .ms-Dropdown-optionText {
      font-family: variables.$main-font-family;
      font-style: normal;
      font-weight: bold;

      font-size: variables.$dropdown-font-size;
      line-height: variables.$dropdown-line-height;
      color: variables.$white;
      background-color: transparent;
    }

    &:hover, &:active, &[aria-selected="true"] {
      background-color: variables.$dropdown-hover-background;
      color: white;
    }
  }

  .ms-Checkbox-checkbox {
    border-width: 2px;
    background-color: #000 !important;
    border-color: #fff !important;
    height: 16px;
    width: 16px;
    border-radius: 0;
  }

  .ms-Dropdown-item {
    .ms-Checkbox-checkbox {
      .ms-Checkbox-checkmark {
        display: none;
      }

      &::after {
        background-color: #000;
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;
        display: block;
        content: "";
      }
    }

    &.ms-Checkbox.is-checked .ms-Checkbox-checkbox::after {
      background-color: #fff;
    }
  }
}
