@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';

.fsp-stats {
  height: 100%;
  overflow-y: auto;

  .chrono {
    margin-top: 20px;
  }

  .fsp-instructions {
    text-align: center;
    margin-bottom: 10px;

    &_no-pages{
      @extend .fsp-instructions;
      margin-top:50px;
    }
  }
  .chart-no-data{
    text-align: center;
    background-color: variables.$white;
    color: variables.$black;
    margin-left: 10px;
    margin-right: 10px;
  }
  .stats-chart {
    margin: 10px;
    background-color: variables.$white;
    color: variables.$black;
    text-align: center;

    .stats-row {
      display: flex;
      margin: 10px;
    }

    .fire-size {
      padding-bottom: 5px;
    }

    .number {
      font-size: 20px;
    }
  }
}

.fsp-smokecheck {
  padding: 15px;

  .smoke-check-textfield {
    color: variables.$white;

    label {
      color: variables.$white;
    }
  }
}

.fsp-log {
  height: 100%;
  overflow-y: scroll;

  .cat-active {
    background-color: variables.$wds-orange;
  }

  .not-active {
    background-color: variables.$grey-300;
  }

  .fire-page-picker {
    background-color: variables.$white;
    width: 100%;

    div {
      width: 100%;

      div {
        button {
          width: 94%;
        }
      }
    }

    table {
      width: 90%;
    }
  }
}

.fsp-popover {
  .category-boxes {
    padding: 15px;
    padding-bottom: 10px;

    .category-box {
      padding-bottom: 10px;
    }
  }
}

.message-icon {
  color: variables.$wds-orange;
  font-size: 24px;
  margin-left: 2px;
}

.category-boxes {
  width: 200px;
}

.fsp-log-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px;

  .space-left {
    margin-left: 10px;
  }
}

.fsp-log-entries {
  text-align: center;

  .fsp-date-header {
    font-size: 12px;
    margin-bottom: -2px;
  }

  .log-chip {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 2px solid variables.$grey-400;

    .log-icon {
      margin-top: 20px;
    }

    .plusminus {
      margin-top: 25px;
      width: 25px;
    }

    .no-threat-icon,
    .threat-icon,
    .demob-icon {
      height: 30px;
      margin-left: 2px;
      margin-right: 1px;
    }
  }
}

.mon-log-update-icon {
  svg {
    stroke: variables.$grey-100;
  }
}

.new-threat-icon {
  svg {
    stroke: variables.$wds-orange;
  }
}

.log-message-editor {
  height: 50%;

  .attach-container {
    .btn-attach-item {
      border-radius: 5px;
      margin-right: 4px;
      margin-top: 3px;
      line-height: 30px;
      text-decoration: none;
      padding: 3px 2px 2px 6px;
      font-family: Roboto, variables.$main-font-family;
      color: variables.$white;
      display: flex;
      justify-content: space-between;
    }
  }
}

.fillable-field {
  background: variables.$white;
  height: 100%;
  padding-bottom: 44px;
  color: variables.$black;
  caret-color: variables.$black;
}

.ql-mention-list-container {
  width: 270px;
  border: 1px solid variables.$grey-100;
  border-radius: 4px;
  background-color: variables.$white;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9002;
  overflow: auto;
  top: 30px !important;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 14px;
  font-size: 12px;
  padding: 5px 20px;
  vertical-align: middle;

  &:hover {
    background-color: variables.$grey-100;
  }
}

.log-chip-content {
  width: 85%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
  max-height: 75px;
  overflow: hidden;
  transition: max-height 0.75s;
}

.t-show {
  max-height: 800px;
}

.t-hide {
  max-height: 75px;
}

.message-content,
.notice-content,
.mon-log-content {
  text-align: left;

  p {
    margin: 0;
  }

  .notice-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
}

.monitored {
  font-size: 32px;
  font-weight: bold;
  color: variables.$grey-500;
}

.dispatched {
  font-size: 32px;
  font-weight: bold;
  color: variables.$red;
}

.no-threat {
  font-size: 32px;
  font-weight: bold;
  color: variables.$wds-orange;
}

.demobilized {
  font-size: 32px;
  font-weight: bold;
  color: variables.$green-400;
}

.numberLine {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  padding-bottom: 0px;
  font-size: 12px;
  align-content: center;
  text-align: center;
  row-gap: 60px;

  .timeline-arrow {
    font-size: 20px;
    margin-left: 5px;
    margin-right:5px;
    color: variables.$grey-400;
    margin-top: -8px;
    cursor: pointer;
  }

  .numberPoint {
    border: 1px solid variables.$grey-400;
    border-right: none;
    border-left: none;
    box-sizing: border-box;
    flex: 1 0 1%;
    height: 0px;
    cursor: pointer;

    &__number {
      margin-top: -22px;
    }
    .not-active {
      padding-top: 2px;
    }    
    .active {
      background-color: variables.$grey-400;
      color: variables.$wds-orange;
      width: 50%;
      margin: auto;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 4px;
    }
  }
}

.page-num {
  text-align: center;
  margin-top: 30px;
  font-size: 12px;
}