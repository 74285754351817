@use 'sass/abstracts/variables';
.pu-layout-fire-overview {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.pu-layout-fire-overview > div {
  flex: none;
}

.pu-properties-header {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .title{
    font-weight: bold;
    font-size: 16px;
    vertical-align: top;
    line-height: 40px;
    text-align: center;
    margin-right: 20px;
  }

  .button{
    width: 150px;
  }
}

.pu-properties-table {
  height: 400px;
  margin: auto;
}