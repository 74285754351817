@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';
@use 'sass/components/buttons';

.details-panel {
  width: 100vw;
  background: variables.$grey-600;
  transition: bottom .3s ease-in-out;
  position: absolute;
  border-radius: 0 5px 5px 0;
  color: variables.$white;
  // height: variables.$panel-height-mobile;
  overflow-y: auto;
  
  &.dp-show {
    bottom: variables.$sidebar-height-mobile;
  }
  
  &.dp-hide {
    bottom: calc(variables.$panel-height-mobile * -1 + variables.$sidebar-height-mobile);
  }

  @include mixins.desktop {
    background: variables.$black-alpha;
    height: 100%;
    transition: right .3s ease-in-out;
    top: 0px;
    border-radius: 0 5px 5px 0;
    width: 500px;
    z-index: 1;
    border-radius: 5px 0 0 5px;

    &.dp-show {
      right: 0px;
      height: 100%;
    }

    &.dp-hide {
      right: -500px;
      height: 100%;
    }
  }
}