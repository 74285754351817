@use 'sass/abstracts/variables';

.ag-header-cell-sorted-none {
  .ag-sort-none-icon {
    display: block !important;
  }

  .ag-icon-none:before {
    content: '\2193 \2191' !important;
    margin-top: -4px;
    display: block;
  }
}

.ag-header-cell .ag-floating-filter-body .ag-disabled {
  .ag-input-field-input:disabled {
    border-color: var(--ag-input-border-color, var(--ag-border-color, variables.$grey-300));
    background-color: var(--ag-background-color, #fff);
  }

  position: relative;
  cursor: pointer;

  &:after {
    position: absolute;
    right: 8px;
    top: 5px;
    content: '';
    display: inline-block;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    width: 10px;
    height: 10px;
    cursor: pointer;
    transform: rotate(-315deg);
  }
}

.ag-cell-label-container.ag-header-cell-sorted-none [class^='ag-'] {
  font-size: 14px;
  font-weight: 700;
}

.ag-theme-quartz .ag-ltr .ag-floating-filter-button {
  width: 0;
  margin-left: 0;
  visibility: hidden;
  position: absolute;
  left: 16px;
  top: 45px;
}

.ag-header-row.ag-header-row-column {
  border-bottom: 0px solid variables.$grey-300;
  height: 49px !important;
}

.table-tool-bar {
  text-align: right;
  height: 31px;
  margin-bottom: 5px;
}

.data-table {
  width: 100%;

  /* zoom: 0.65; */
  border-top: 1px solid variables.$grey-300;

  .ag-cell-value, .ag-header-cell-label .ag-header-cell-text {
    font-size: 12px !important;
  }
}

.ag-set-filter-list {
  height: 160px !important;
}
