@use 'sass/abstracts/variables';

.risk-address {
  text-align: center;
  margin: 20px 0;
}

.risk-info {
  display: flex;
  justify-content: space-between;
}

.risk-score-box {
  padding: 15px 60px;
  color: variables.$white;
  text-align: center;
  margin-bottom: 5px;

  p {
    font-size: 20px;
  }
}

.risk-graphic {
  text-align: center;
  background: variables.$grey-200;
  height: 260px;
  margin-bottom: 20px;
}

.risk-std-dev {
  background: variables.$blue-faint;
  padding: 20px;

  p {
    margin: 0;
  }
}

.risk-liability p {
  margin: 20px 0;
}

.other-scores {
  display: flex;
  margin-bottom: 5px;

  > div {
    flex: 1;
    text-align: center;
    background: variables.$blue-dark;
    padding: 20px 0;
  }

  h4 {
    color: variables.$white;
  }
}

.score-whp {
  margin-left: 5px;
}
