@use 'sass/abstracts/variables';
.pu-layout-monitored-fire {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.pu-layout-fire-overview > div {
  flex: none;
}
