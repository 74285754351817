﻿@use 'sass/abstracts/variables';

.radius-30 {
  border-radius: 30px;
}

.pfi-ag.ag-theme-quartz {
  .ag-header-cell-label {
    /*flex: 0 1 auto;*/
    justify-content: center;

    .ag-header-cell-text {
      white-space: normal;
      align-self: end;

      &:after {
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-weight: normal;
        font-family: FabricMDL2Icons;
        content: url('data:image/svg+xml; utf8, <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10 18.3036C5.41518 18.3036 1.69643 14.5848 1.69643 10C1.69643 5.41518 5.41518 1.69643 10 1.69643C14.5848 1.69643 18.3036 5.41518 18.3036 10C18.3036 14.5848 14.5848 18.3036 10 18.3036Z" fill="black"/> <path d="M12.491 5.64066C11.8213 5.05361 10.9374 4.73218 9.9999 4.73218C9.0624 4.73218 8.17847 5.05584 7.50882 5.64066C6.8124 6.25004 6.42847 7.06923 6.42847 7.94646V8.11611C6.42847 8.21432 6.50882 8.29468 6.60704 8.29468H7.67847C7.77668 8.29468 7.85704 8.21432 7.85704 8.11611V7.94646C7.85704 6.96209 8.81909 6.16075 9.9999 6.16075C11.1807 6.16075 12.1428 6.96209 12.1428 7.94646C12.1428 8.64066 11.6517 9.27682 10.8905 9.56923C10.4173 9.75003 10.0155 10.067 9.72757 10.4822C9.43516 10.9063 9.28338 11.4152 9.28338 11.9308V12.4107C9.28338 12.509 9.36373 12.5893 9.46195 12.5893H10.5334C10.6316 12.5893 10.7119 12.509 10.7119 12.4107V11.9041C10.7131 11.6874 10.7795 11.476 10.9026 11.2977C11.0256 11.1193 11.1995 10.9821 11.4017 10.9041C12.7186 10.3974 13.5691 9.23664 13.5691 7.94646C13.5713 7.06923 13.1874 6.25004 12.491 5.64066ZM9.10704 14.9107C9.10704 15.1475 9.20111 15.3747 9.36855 15.5421C9.53599 15.7095 9.7631 15.8036 9.9999 15.8036C10.2367 15.8036 10.4638 15.7095 10.6312 15.5421C10.7987 15.3747 10.8928 15.1475 10.8928 14.9107C10.8928 14.6739 10.7987 14.4468 10.6312 14.2794C10.4638 14.112 10.2367 14.0179 9.9999 14.0179C9.7631 14.0179 9.53599 14.112 9.36855 14.2794C9.20111 14.4468 9.10704 14.6739 9.10704 14.9107Z" fill="black"/></svg>');
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
    }
  }

  .ag-header-cell {
    text-align: center;
  }

  .ag-cell-value {
    white-space: normal;
    overflow: visible;
    text-align: center;
  }

  .ag-cell {
    line-height: normal;
  }

  .pl-row-normal {
    height: auto;
  }

  .pl-cell-normal {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-break: break-word;
    height: auto;
  }

  .pl-cell-left {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-break: break-word;
    height: auto;
    text-align: left;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .ag-header-row.ag-header-row-column {
    height: 60px !important;
  }
}

.time-dropdown .ms-Dropdown-container {
  min-width: 8rem;
  max-width: 10rem;
}

.time-slider {
  .ms-Slider-value {
    display: none;
  }

  [class^="ms-Label titleLabel-"] {
    width: 80px;
    height: 42px;
    margin-top: -1.5rem;
    position: relative;
    left: -25px;
  }

  .ms-Slider-thumb {
    border-color: variables.$wds-orange;
    background-color: variables.$wds-orange;
 
  }

  &.disabled .ms-Slider-thumb {
    border-color: variables.$grey-300;
    background-color: variables.$grey-300;
  }

  .ms-Slider-inactive {
    background-color: variables.$grey-300;
  }

  .ms-Slider-active {
    background-color: variables.$black;
 
  }
}

.pfi-tab .ms-Dropdown-callout .ms-Callout-main {
  max-height: 20rem !important;
}

.pfi-no-wrap {
  white-space: nowrap;
  min-width: 5rem;
}
