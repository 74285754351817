@use 'sass/abstracts/variables';

.risk-globe, .risk-bar-chart {
  position: relative;
}

.risk-globe .bi-circle-fill, .risk-bar-chart .bi-circle-fill {
  font-size: 8em;
  color: variables.$red;
}

.risk-globe .bi-globe {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 5em;
  color: variables.$white;
}

.get-risk-text {
  max-width: 320px;
}

.wds-risk-logo {
  margin: 0 auto;
  width: 500px;

  img {
    max-width: 100%;
  }
}

.get-risk-address {
  width: 450px;
}

.search-by-address {
  position: relative;
}

.autocomplete-dropdown-container {
  position: absolute;
}

.get-risk-coords span label {
  min-width: 75px;
}

.risk-bar-chart .bi-bar-chart-line-fill {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 5em;
  color: variables.$white;
}

.risk-type {
  width: 140px;
}

.risk-type-label {
  margin-bottom: 31px;
}

.risk-date-pickers {
  width: 400px;

  > * {
    flex: 1;
  }
}

#risk-past-scores-grid .ag-cell {
  display: flex;
  align-items: center;
  line-height: 20px;
  word-break: normal;
}
