@use 'sass/abstracts/variables';
.w-20 {
  width: 20%;
}
.mm-20 {
  min-width: 20% !important;
  max-width: 20% !important;
  width: 20% !important;
}
.mm-25 {
  min-width: 25%;
  max-width: 25%;
  width: 25%;
}
.mm-30 {
  min-width: 30%;
  max-width: 30%;
  width: 30%;
}
.mm-40 {
  min-width: 40%;
  max-width: 40%;
  width: 40%;
}
.w-70 {
  width: 70%;
}
.w-30 {
  width: 30%;
}
.w-18 {
  width: 18%;
}
.mm-15 {
  min-width: 15%;
  max-width: 15%;
  width: 15%;
}
.mm-18 {
  min-width: 18%;
  max-width: 18%;
  width: 18%;
}
.mm-10 {
  min-width: 10%;
  max-width: 10%;
  width: 10%;
}
.fixed-20 {
  min-width: 18%;
  max-width: 18%;
}