@use 'sass/abstracts/variables';

/* Layer Selector Styles */

.layer-wrap {
  position: relative;
  background-color: #fff;
  white-space: nowrap;

  &:hover .dp-header, &.is-expanded .dp-header {
    background-color: variables.$black;
    color: #fff;
  }

  .dp-header {
    border: 0;

    background-color: variables.$wds-orange;
    cursor: pointer;
    display: flex;
    padding: 5px 10px;

    font-family: variables.$main-font-family;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;

    color: variables.$black;
    height: 100%;
    user-select: none;
    align-items: center;
  }

  .dp-layer {
    background-color: variables.$dropdown-background;
    color: #fff;
    position: absolute;
    right: 0;
    list-style-type: none;
    z-index: 1000;
    padding: 10px 20px;
    border: 0;

    .sub-item {
      padding-left: 30px;
    }

    li:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  .layer-chbi {
    .ms-Checkbox-checkbox {
      background-color: #000 !important;
      border-color: #fff;
      border-radius: 0;
    }

    .ms-Checkbox .ms-Checkbox-text {
      font-family: variables.$main-font-family;
      font-style: normal;
      font-weight: bold;

      font-size: variables.$dropdown-font-size;
      line-height: 20px;
    }
  }
}

.layer-chbi {
  .ms-Checkbox:hover .ms-Checkbox-text, .ms-Checkbox-text {
    color: #fff;
    margin-left: 0;
  }

  .chbi-chb {
    .ms-Checkbox-checkbox::after {
      background-color: #000;
      width: 8px;
      height: 8px;
      top: 2px;
      left: 2px;
      display: block;
      content: "";
    }

    &.is-checked .ms-Checkbox-checkbox::after {
      background-color: #fff;
    }
  }
}

li + .layer-section {
  padding-top: 10px;
  margin-bottom: 10px !important;
}

/* Layers Dropdown on Maps */

.ly-timeframe {
  .ms-ChoiceFieldLabel {
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #fff !important;
    padding-bottom: 7px;
  }

  .ms-ChoiceField-field {
    &::before {
      height: 18px;
      width: 18px;
      margin-top: 1px;
      border-color: #fff;
    }

    &:hover::after, &::after {
      background-color: #000;
      border-color: #000;
      border-width: 7px;
      top: 3px;
      left: 2px;
      height: 14px;
      width: 14px;
    }

    &.is-checked::after {
      border-color: #000;
      top: 3px;
      left: 2px;
      border-width: 3px;
      background-color: #fff;
      width: 14px;
      height: 14px;
      border-style: solid;
    }
  }
}
