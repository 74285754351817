/* Colors */
$wds-orange: #f2b643;
$wds-orange-rgba: rgba(242, 182, 67, 0.8);
$wds-disabled-orange: #f5c875;
$wds-hover-orange: #b78425;
$non-dispatch: #E9AA52;
$demob: #63933C;
$light-orange: #FFF8EB;
$main-bg-color: #707372;
$grey-100: #eeeeee;
$grey-200: #e5e5e5;
$grey-300: #c4c4c4;
$grey-400: #777777;
$grey-500: #555555;
$grey-600: #222222;
$black: #000000;
$black-alpha: rgba(40,40,40,0.8);
$white: #ffffff;
$blue-faint: #d3e1eb;
$blue-light: #31BDEF;
$blue: #0088F4;
$blue-dark: #0d5fd8;
$navy: #334051;
$green-100: #CFE8CD;
$green-200: #5AC350;
$green-300: #40A33A;
$green-400: #63933C;
$green-500: #1d6f42;
$magenta: #974e85;
$red: #ff0000;
$dropdown-background: rgba(0, 0, 0, 0.8);
$dropdown-hover-background: rgba(255, 255, 255, 0.1);

/* Canviz Baloney */
$grid-header-height: 31px;
$main-font-family: 'Lato', sans-serif;
$dropdown-font-size: 12px;
$dropdown-line-height: 20px;

/* Breakpoints */
$tablet-sm: 768px;
$tablet-lg: 992px;
$desktop: 1200px;

/* Sitmap Sidebar Panel */
$panel-width-desktop: 400px;
$panel-height-mobile: 50vh;
$sidebar-width-desktop: 90px;
$sidebar-height-mobile: 80px;