@use 'sass/abstracts/variables';
.pu-query-title {
  width: 217px;
}

.pu-query .form-group {
  margin-right: 60px;
}

.pu-select-query {
  width: 217px;
}

.pu-edit-query {
  background: transparent;
  border: none;
}

.pu-custom-dropdown-menu {
  overflow-y: auto !important;
  color: variables.$black;
  box-sizing: border-box;
  border-radius: 0px 0px 2px 2px;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  outline: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  width: auto;
  transform: translate(0, 28px) !important;
}

.pu-reports-dropdown {
  width: auto;
}

.pu-custom-dropdown-menu li {
  padding-left: 20px;
  padding-right: 20px;
}
