/* Spacing */
.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-blank {
  width: 200px;
}

.mr-large-1 {
  margin-right: 2.75rem !important;
}

.ml-large-1 {
  margin-left: 2.75rem !important;
}

.column-w-1 {
  width: 48px;
}

.line-height-1 {
  line-height: 32px;
}

.w-100 {
  width: 100%;
}

.w-180 {
  width: 180px;
}