@use "sass/abstracts/variables";
@use "sass/components/_buttons";
@use "sass/abstracts/mixins";
@mixin num-columns($size) {
    .columns-#{$size} {
        grid-template-columns: repeat($size, 1fr);
    }
}
@include num-columns(1);

.break-mobile {
  opacity: 1;
  margin: 0;
  border: 2px solid variables.$wds-orange;
}


@include mixins.desktop {
    .table-title {
        display:none;
    }

    .break-mobile {
      display: none;
    }

    @include num-columns(10);
    @include num-columns(6);
    @include num-columns(4);
    @include num-columns(3);
    @include num-columns(5);
}

.table-title {
    background-color: variables.$wds-orange;
    padding-bottom:5px;
    margin-bottom: 0px;
    padding-left: 5px;
    margin: 5px;
    padding-top: 5px;
    border-radius: 4px;
}


.mobile-table-wrapper {
  display: grid;


  .grid-item {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    .cell-mobile {
    display: block;
    }

    .cell-data {
      text-transform: capitalize;
      margin-left: 15px;
    }
  }

  :nth-child(2n-1):not(.cell-data, .cell-header, .cell-desktop, .grid-desktop, .table-title){
    background-color: variables.$grey-200; 
  }

  .cell-desktop {
    display: none;
  }

  @include mixins.desktop {
  width: 97%;
  margin-left: 13px;
  &:last-child{
    margin-bottom: 10px;
  }

  .grey{
    background-color: variables.$grey-200;
    .cell-data {
      background-color: variables.$grey-200;
    }
  }
    .grid-item {
        display: block;
        padding: 0;
        .cell-mobile {
            display: none;
          }
    }
    .cell-desktop {
        display: block;
    }

    .cell-header {
      &:first-of-type {
        border-radius: 10px 0 0 0;
      }
      background-color: variables.$wds-orange;
      font-weight: bold;
      font-size: 16px;
      padding-left: 17px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .last-header {
      border-radius: 0 10px 0 0;
    }

    .cell-data {
      margin: 15px;
      margin-left: 17px;
      background-color: white;
    }
  }
}

