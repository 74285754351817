@use 'sass/abstracts/variables';

.loading-layer {
  display: grid;
  align-items: center;
  visibility: visible;
  background-color: variables.$black;
  opacity: 0.3;

  .ms-Layer-content {
    text-align: center;
  }
}

.bg-light-dark {
  background-color: rgba(0, 0, 0, 0.2);
}