﻿@use 'sass/abstracts/variables';

.notice-type-section {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid variables.$grey-300;

  .remove {
    cursor: pointer;
  }
}

.notice-type-wdsaction {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid variables.$grey-300;
}

.notifications-main-container .notice-type-wdsaction {
  .ms-TextField-field {
    font-family: variables.$main-font-family;
    font-size: 16px;
    color: variables.$black;
    line-height: 19.2px;
  }

  .ms-TextField-errorMessage {
    font-family: variables.$main-font-family;
    font-size: 14px;
    color: variables.$red;
  }
}

.notice-type-wdsaction .title {
  padding-left: 8px;
  padding-bottom: 10px;
}

.notice-type-select-clients {
  flex: 1.2;
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid variables.$grey-300;
}

.notice-type-clients-selection {
  display: flex;
}

.notifications-main-container .notice-type-clients-selection .title {
  font-size: 16px;
  line-height: 35px;
  margin-right: 10px;
}

.notice-type-clients-selection-dropdown {
  flex-grow: 1;
  width: 0px;
}

.notice-type-select-clients .notice-type-clients {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  padding-top: 10px;
  display: flex;
}

.notice-type-clients .pill-button {
  background: variables.$wds-orange;
  border-radius: 30px;
  color: variables.$black;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 15px;
  font-weight: bold;
  text-decoration: none;
  margin-right: 15px;
  margin-top: 8px;

  .remove {
    margin-left: 30px;
  }
}

.notice-type-perimeter-snapshot {
  flex: 0.8;
  padding-left: 20px;
  padding-right: 20px;
}

.notice-type-remove .remove {
  margin-top: -25px;
}

.add-notice-type-buttons {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  .ms-Dropdown-container {
    width: 290px;
  }
}
