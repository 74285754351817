@use 'sass/abstracts/variables';

.category-list {
  background-color: variables.$white;
  max-height: 263px;
  overflow-y: auto;
  padding: 5px;
  border-style: solid;
  border-color: variables.$black;
  border-width: 1px;
  flex-flow: column;
  flex: 2;

  .header {
    color: variables.$black;
    font-weight: 600;
    font-size: 14px;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.category-item {
  margin-top: 10px;
}
