@use 'sass/abstracts/variables';

/* Modal Styles */

.pcp-banner {
  max-width: 1000px;
}

.pcp-banner-content {
  padding: 0 24px 24px 24px;
  border-top: 8px solid variables.$wds-orange;
}

.pcp-banner-heading {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 14px 0;
}

.pcp-banner-close {
  color: variables.$wds-orange;
}

.new-property-form {
  width: 1200px;
  padding: 15px;
  border-top: 8px solid variables.$wds-orange;

  .new-property-form-heading {
    display: flex;
    justify-content: space-between;
  }
}

.close-new-pha-form {
  color: variables.$wds-orange;
}
