@use 'sass/abstracts/variables';

/* Custom Paging Panel Styles */

.sitm-custom-paging-panel {
  font-family: variables.$main-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: variables.$black;

  .ag-paging-row-summary-panel-number {
    padding: 0px 5px;
  }

  .ag-paging-button {
    margin: 0px;
    width: 30px;
    height: 30px;

    .ag-icon {
      text-align: center;

      &::before {
        font-size: 17px;
        font-weight: bold;
        line-height: 30px;
        color: variables.$white;
      }
    }
  }

}
