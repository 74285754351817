@use 'sass/abstracts/variables';

/* Where's the Fire Styles */

.wtf-result-info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-family: variables.$main-font-family;
}

.wtf-firename-color {
  color: variables.$blue;

  a {
    color: variables.$blue;
    text-decoration: none;
  }
}

.wtf-form-row .second-color, .second-color {
  color: variables.$red;
}

.wtf-results-address {
  color: variables.$red;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 14px;
}

.wtf-result-row:last-child {
  margin-bottom: 20px;
}

.wtf-form {
  margin-bottom: auto;
  width: 100%;
  max-width: 411px;
}

.wtf-form-row {
  display: flex;
  flex-direction: row;
  max-width: 351px;
  width: 100%;
}

.wtf-dropdown-open {
  .ms-Callout-main {
    background-color: white !important;
    max-height: 380px !important;
    box-shadow: none !important;
    font-family: variables.$main-font-family;
  }

  .ms-Callout {
    box-shadow: none !important;
  }
}

.wtf-form .wtf-dropdown-open div[class^='callout-'], div[class*=' callout-'] {
  box-shadow: none !important;
}
