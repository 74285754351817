﻿@use 'sass/abstracts/variables';

.fireInformation-container {
  color: variables.$black;

  .section-box {
    padding: 20px 10px;
    height: 100%;
  }

  .section-header {
    padding-left: 10px;

    img {
      width: 30px;
      margin-top: -8px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    padding-left: 10px;
  }

  .row-odd {
    display: flex;
    background-color: variables.$grey-100;
    padding: 5px 0;
  }

  .row-even {
    display: flex;
    padding: 5px 0;
  }

  .column-name {
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
  }

  .column-value {
    flex: 1;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    padding-right: 10px;
  }

  .rich-text {
    display: flex;
    padding: 5px 10px;
    line-height: 19px;
  }

  .update-date {
    font-size: 14px;
    line-height: 17px;
    font-style: italic;
    padding: 5px 0px 20px 10px;
    white-space: nowrap;
  }

  .bi-exclamation-circle-fill {
    font-size: 25px;
  }

  .text-bold {
    font-weight: bold;
  }

  .redflag {
    float: right;
    margin-right: 10px;
    margin-top: -5px;

    .bi-flag-fill {
      margin: 0 15px 0 0;
      color: variables.$red;
      font-size: 25px;
    }
  }

  .zipCodes .title-tooltip-icon-2 {
    margin-left: 5px;
    cursor: pointer;
  }

  .title-tooltip-zipcodes {
    word-break: break-all;
  }

  .red {
    color: variables.$red;
    font-weight: bold;
  }
}

/*weather ag-grid*/

.fireoverview-weather {
  .properties-table {
    margin: 9px 12px 17px 13px;
  }

  .table-title {
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 24px !important;
    padding-left: 0px;
    padding-bottom: 3px;
  }

  .ag-theme-quartz .ag-cell {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.ag-theme-quartz .ag-full-width-row .ag-cell-wrapper.ag-row-group, .fireoverview-weather .ag-theme-quartz .ag-header-cell, .ag-theme-quartz .ag-header-group-cell {
  padding-left: 0px;
  padding-right: 0px;
}

.fireoverview-weather {
  .table-header {
    border-bottom: 2px solid variables.$black !important;
  }

  .ag-header {
    background-color: variables.$white !important;
    border-bottom: 0px !important;
    height: 30px !important;
    min-height: 30px !important;
  }

  .ag-header-icon {
    display: none;
  }

  .ag-header-row {
    background-color: variables.$white !important;
    border-bottom: 0px !important;
    height: 30px !important;
  }

  .ag-header-row-column {
    height: 30px !important;
  }

  .ag-header-cell-label .ag-header-cell-text {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: variables.$black !important;
    white-space: normal;
  }

  .ag-body-viewport {
    height: 62px !important;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .ag-cell {
    line-height: 14px !important;
    padding-top: 6px !important;
    font-size: 12px !important;
    color: variables.$black !important;
 
  }
}

/*end weather ag-grid*/
