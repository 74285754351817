﻿@use 'sass/abstracts/variables';

.engineInfoSlideBar-container {
  padding-left: 20px;
  padding-bottom: 15px;
  padding-right: 5px;
  height: 544px;
  overflow-y: auto;
  overflow-x: hidden;

  .engine-info-left {
    flex: 1;
  }

  .engine-title {
    font-size: 16px;
    font-weight: bold;
    color: variables.$blue-light;
  }

  .engine-title1 {
    font-size: 14px;
    font-weight: bold;
    color: variables.$black;
  }

  .engine-text {
    font-size: 12px;
    color: variables.$black;
  }

  .engine-info-right .arrow-left {
    cursor: pointer;
    width: 14px;
    display: inline-block;

    img {
      width: 7px;
      height: 14px;
    }
  }
}
