@use 'sass/abstracts/variables';
.client-users .ft-table-wrap {
    height: 200px;
}

.client-footer.editor-footer {
    margin-top: 32px;
}

.client-input{
    width: 275px;
}